import { PixelRatio } from 'react-native'

export function createViewElement() {
    const emitter = createEventEmitter()

    function getHandlers() {
        const handlers = {}

        handlers.onTouchStart = ({ nativeEvent }) => {
            emitter.emit('pointerdown', convertTouch(nativeEvent))
        }
        handlers.onTouchMove = ({ nativeEvent }) => {
            convertTouches(nativeEvent.touches).forEach((touch) => {
                emitter.emit('pointermove', convertTouch(touch))
            })
        }
        handlers.onTouchEnd = ({ nativeEvent }) => {
            emitter.emit('pointerup', convertTouch(nativeEvent))
        }
        handlers.onTouchCancel = ({ nativeEvent }) => {
            emitter.emit('pointercancel', convertTouch(nativeEvent))
        }

        return handlers
    }

    function getElement(gl = {}) {
        const width = gl.drawingBufferWidth
        const height = gl.drawingBufferHeight
        return {
            ...emitter,
            style: {},
            width,
            height,
            clientWidth: Math.round(width / PixelRatio.get()),
            clientHeight: Math.round(height / PixelRatio.get()),
            setPointerCapture: (e) => {
                // console.log({ e })
            },
            releasePointerCapture: (e) => {
                // console.log({ e })
            },
        }
    }

    return {
        getHandlers,
        getElement,
    }
}

function convertTouches(touches) {
    const list = []
    for (let i = 0; i < touches.length; ++i) {
        list.push(touches[i])
    }
    return list
}

function convertTouch(nativeEvent) {
    const event =
        nativeEvent.identifier === undefined
            ? nativeEvent.touches[0] || nativeEvent.changedTouches[0]
            : nativeEvent
    return {
        nativeEvent,
        ...event,
        pixelRatio: PixelRatio.get(),
        pointerType: 'touch',
        eventType: event.type,
        pointerId: event.identifier,
        offsetX: event.locationX,
        offsetY: event.locationY,
        // locationX: event.locationX,
        // locationY: event.locationY,
        // pageX: event.pageX,
        // pageY: event.pageY,
        // touches: event.touches,
        // changedTouches: event.changedTouches,
    }
}

function createEventEmitter() {
    const events = {}
    return {
        addEventListener: (name, listener) => {
            const listeners = events.hasOwnProperty(name)
                ? events[name]
                : (events[name] = [])
            listeners.push(listener)
        },
        emit: (name, ...params) => {
            const listeners = events[name] || []
            return listeners.map((listener) => {
                return listener(...params)
            })
        },
        removeEventListener: (name, listener) => {
            const index = events[name].indexOf(listener)
            events[name].splice(index, 1)
        },
    }
}

// handlers.onStartShouldSetResponder = (e) => {
//     console.log(
//         'onStartShouldSetResponder',
//         e.nativeEvent.touches.length
//     )
//     return true
// }
// handlers.onMoveShouldSetResponder = (e) => {
//     return true
// }
// handlers.onResponderGrant = (e) => {
//     console.log('pointerdown', e.nativeEvent.touches.length)
//     e.nativeEvent.touches.forEach((nativeEvent) => {
//         emitter.emit('pointerdown', convertTouch({ nativeEvent }))
//     })
// }
// handlers.onResponderMove = (e) => {
//     // console.log('move', e.nativeEvent.touches.length)
//     e.nativeEvent.touches.forEach((nativeEvent) => {
//         emitter.emit('pointermove', convertTouch({ nativeEvent }))
//     })
// }
// handlers.onResponderRelease = (e) => {
//     console.log('pointerup', e.nativeEvent.touches.length)
//     emitter.emit('pointerup', convertTouch(e))
// }
// handlers.onResponderReject = (e) => {
//     console.log('pointercancel', e.nativeEvent.touches.length)
//     emitter.emit('pointercancel', convertTouch(e))
// }
