import React from 'react'
import styled from '@emotion/native'
import { isWeb, px } from '~/utils/device'
import { UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { translate } from '~/locale'
import { useGlobalState } from '~/store/hooks'
import Button from '~/components/stateless/Button'
import { AUDIO } from '~/audio'

export default function Outdated() {
    const [{ outdated }] = useGlobalState()

    return (
        <Background>
            <Title>{translate('An update of the app is required.')}</Title>
            <SubTitle>error: {translate(outdated.error)}</SubTitle>
            <Image source={UX_ASSETS['outdated.png']} />
            <Reconnect>
                <ReconnectButton>
                    {isWeb() && (
                        <Button
                            label={translate('Reload App')}
                            width={300}
                            height={80}
                            fontSize={30}
                            onClick={() => {
                                AUDIO.CLICK()
                                location.reload()
                            }}
                        />
                    )}
                </ReconnectButton>
            </Reconnect>
        </Background>
    )
}

const Background = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background: ${COLOR.REDDARK + 'fd'};
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    cursor: default;
    z-index: 3;
`

const Title = styled.Text`
    font-family: Poppins-Bold;
    color: white;
    font-size: ${px(25)};
`

const SubTitle = styled.Text`
    font-family: Poppins-Medium;
    color: white;
    font-size: ${px(18)};
`

const Image = styled.Image`
    width: ${px(270)};
    height: ${px(270)};
    margin: ${px(100)} 0;
`

const Reconnect = styled.View`
    align-items: center;
`

const ReconnectButton = styled.View`
    padding-top: ${px(40)};
`
