import React from 'react'
import styled from '@emotion/native'
import { UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { px } from '~/utils/device'
import Gradient from '~/components/stateless/Gradient'
import DialogBackground from '~/components/stateless/DialogBackground'
import { Error, ErrorText } from '~/components/stateless/Error'

export default function Dialog({
    children,
    width = 900,
    height = 800,
    title,
    titleScale = 1,
    error,
    gradient = true,
    background,
    backgroundColor = COLOR.BLUEBLACK + 'F5',
    backgroundResize = 'repeat',
    backgroundPattern = UX_ASSETS['bg-pattern.png'],
    backgroundPatternResize = 'repeat',
    contentColor = COLOR.BEIGE,
    contentColor2 = COLOR.BEIGE3,
    borderColor = COLOR.BROWNBLACK,
    borderWidth = 15,
    borderRadius = 20,
    borderRadiusInner = 10,
    onContent,
    onClose,
    onBackground,
}) {
    return (
        <DialogBackground
            onPress={onBackground}
            backgroundColor={backgroundColor}
        >
            <Container
                width={width}
                height={height}
                onPress={onContent}
                top={-borderWidth}
                left={-borderWidth}
            >
                <ContentBorder
                    width={width + borderWidth * 2}
                    height={height + borderWidth * 2}
                    contentColor={contentColor}
                    borderColor={borderColor}
                    borderWidth={borderWidth}
                    borderRadius={borderRadius}
                >
                    {background === undefined && (
                        <>
                            {gradient && (
                                <BackgroundGradient
                                    borderRadius={borderRadiusInner}
                                >
                                    <Gradient
                                        colors={[
                                            [contentColor, 0],
                                            [contentColor2, 1],
                                        ]}
                                    />
                                </BackgroundGradient>
                            )}
                            <BackgroundPattern
                                borderRadius={borderRadiusInner}
                                source={backgroundPattern}
                                imageStyle={{
                                    resizeMode: backgroundPatternResize,
                                }}
                            />
                        </>
                    )}

                    <ContentBackground
                        source={background}
                        width={width}
                        height={height}
                        imageStyle={{ resizeMode: backgroundResize }}
                    >
                        {onClose && (
                            <CloseContainer onPress={onClose}>
                                <Close source={UX_ASSETS['icon-close.png']} />
                            </CloseContainer>
                        )}

                        {title && (
                            <TitleContainer scale={titleScale}>
                                <TitleBackground
                                    scale={titleScale}
                                    // source={UX_ASSETS['dialog-title.png']}
                                    imageStyle={{
                                        resizeMode: 'stretch',
                                    }}
                                >
                                    <Title scale={titleScale}>{title}</Title>
                                </TitleBackground>
                            </TitleContainer>
                        )}

                        <Content>{children}</Content>
                    </ContentBackground>
                    {error && (
                        <ErrorContainer>
                            <Error>
                                <ErrorText>{error}</ErrorText>
                            </Error>
                        </ErrorContainer>
                    )}
                </ContentBorder>
            </Container>
        </DialogBackground>
    )
}

const Container = styled.Pressable`
    cursor: default;
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
    top: ${(p) => px(p.top)};
    left: ${(p) => px(p.left)};
`

const ContentBorder = styled.View`
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
    border-radius: ${(p) => px(p.borderRadius)};
    border: ${(p) => px(p.borderWidth)} solid ${(p) => p.borderColor};
    background: ${(p) => p.contentColor};
`

const ContentBackground = styled.ImageBackground`
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
`

const CloseContainer = styled.TouchableOpacity`
    position: absolute;
    right: ${px(-30)};
    top: ${px(-30)};
    z-index: 1;
`

const Close = styled.Image`
    width: ${px(90)};
    height: ${px(90)};
    transform: rotate(15deg);
`

const TitleContainer = styled.View`
    top: ${(p) => px(p.scale * (-100 / 2))};
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
`
const TitleBackground = styled.View`
    min-width: ${(p) => px(p.scale * 600)};
    height: ${(p) => px(p.scale * 100)};
    padding: 0 ${(p) => px(p.scale * 40)};
    background: ${COLOR.BROWNBLACK};
    border-radius: ${px(10)};
    align-items: center;
    justify-content: center;
`
const Title = styled.Text`
    // top: ${(p) => px(p.scale * -5)};
    font-size: ${(p) => px(p.scale * 40)};
    position: relative;
    text-align: center;
    color: white;
    font-family: Poppins-Black;
    align-items: center;
    justify-content: center;
`

const Content = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
`

const BackgroundGradient = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: ${(p) => px(p.borderRadius)};
    overflow: hidden;
`
const BackgroundPattern = styled.ImageBackground`
    position: absolute;
    border-radius: ${(p) => px(p.borderRadius)};
    width: 100%;
    height: 100%;
    overflow: hidden;
`
const ErrorContainer = styled.View`
    position: relative;
    top: ${px(20)};
    width: 100%;
`
