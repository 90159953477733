import * as THREE from 'three'
import { loadMeshMaterial } from './utils/Loader'

export default function createPath({ path, url, ...options }, { scene }) {
    const lines = new THREE.Group()
    const material = loadMeshMaterial(url, { transparent: true })
    let tween

    path.forEach((origin, index) => {
        if (path[index + 1]) {
            const destiny = path[index + 1]
            const line = createLine({
                ...options,
                material,
                scene: lines,
                fromX: origin.col,
                fromZ: origin.row,
                toX: destiny.col,
                toZ: destiny.row,
            })

            lines.add(line.element)
        }
    })

    // lines.position.y = position_y
    scene.paths.add(lines)

    return {
        element: lines,
        animate: ({ offset = 0.075, interval = 25 }) => {
            tween = setInterval(() => {
                material.map.offset.x -= offset
            }, interval)
        },
        setColor: ({ color }) => {
            material.color = new THREE.Color(color)
        },
        setOpacity: ({ opacity = 1 }) => {
            material.opacity = opacity
        },
        remove: () => {
            scene.paths.remove(lines)
            lines.children.forEach((line) => line.remove())
            clearInterval(tween)
        },
    }
}

function createLine({
    scene,
    material,
    fromX,
    fromZ,
    toX,
    toZ,
    repeat_factor = 2,
    line_width = 1,
    line_height = 0.3,
}) {
    const geometry = new THREE.PlaneGeometry(line_width, line_height)
    const mesh = new THREE.Mesh(geometry, material)
    const texture = material.map
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    material.map.minFilter = THREE.LinearFilter
    mesh.rotation.x = -Math.PI / 2
    mesh.position.x = line_width / 2
    const line = new THREE.Group()
    line.add(mesh)
    scene.add(line)

    // Position
    const fromVector = new THREE.Vector2(fromX, fromZ)
    const toVector = new THREE.Vector2(toX, toZ)
    const distance = fromVector.distanceTo(toVector)
    const scaleFactorX = distance / mesh.geometry.parameters.width
    line.position.x = fromX
    line.position.z = fromZ
    line.scale.set(scaleFactorX, 1, 1)
    texture.repeat.set(distance * repeat_factor, 1)
    line.rotation.y = -Math.atan2(toZ - fromZ, toX - fromX)

    return {
        element: line,

        remove: () => {
            scene.remove(line)
        },
    }
}
