import { registerRootComponent } from 'expo'
import * as ScreenOrientation from 'expo-screen-orientation'
import { useFonts } from 'expo-font'
import { StatusBar } from 'expo-status-bar'
import { FONTS } from '~/const/assets'
import Views from '~/components/Views'

export default function App() {
    const fonts = {}
    Object.keys(FONTS).forEach((font_name) => {
        fonts[font_name] = FONTS[font_name]
    })
    const [fonts_loaded] = useFonts(fonts)

    return (
        fonts_loaded && (
            <>
                <StatusBar hidden={true} />
                <Views />
            </>
        )
    )
}

registerRootComponent(App)

// try {
//     if (isIos() || isAndroid()) {
//         ScreenOrientation.lockAsync(
//             ScreenOrientation.OrientationLock.LANDSCAPE_LEFT
//         )
//     }
// } catch (e) {}

// document.addEventListener('visibilitychange', () => {
//     const state = document.visibilityState
//     if (state === 'hidden') {
//         // your PWA is now in the background
//         getConnection().ws.close()
//     }

//     if (state === 'visible') {
//         // your PWA is now in the foreground
//     }
// })
