import * as THREE from 'three'
// import { WebGLRenderer } from 'three'
import { Renderer } from 'expo-three'
import TWEEN from '@tweenjs/tween.js'
import {
    changeRotation,
    changePosition,
    degToRad,
    convertNegativeRadianIntoDouble,
    cartesianToSphericalByControls,
} from './utils/'
import createBoard from './createBoard'
import createInteractivity from './createInteractivity'
import createTerrainPattern from './createTerrainPattern'
import createTerrainTile from './createTerrainTile'
import createTerrainSprite from './createTerrainSprite'
import createTile from './createTile'
import createArea from './createArea'
import createPath from './createPath'
import createRangeTiles from './createRangeTiles'
import createHandHelper from './createHandHelper'
import createFlag from './createFlag'
import createUnit from './createUnit'
import createVolumeSprite from './createVolumeSprite'
import createUxSprite from './createUxSprite'

export default function createThree({
    gl,
    canvas,
    fov = 30,
    near = 1,
    far = 999999,
    debug = false,
    isWeb = () => true,
    isDesktop = () => true,
    isLandscape = () => true,
    getPixelRatio = () => 1,
    onWhell = () => {},
    onMouseMove = () => {},
    onTouchStart = () => true,
    onTouchEnd = () => {},
    onTouchMove = () => {},
    onTouchClick = () => {},
    onTouchDragged = () => {},
}) {
    const clock = new THREE.Clock()
    const scene = {
        terrain: new THREE.Scene(),
        terrainsprites: new THREE.Scene(),
        areas: new THREE.Scene(),
        tiles: new THREE.Scene(),
        paths: new THREE.Scene(),
        sprites: new THREE.Scene(),
        ux: new THREE.Scene(),
    }
    const scenes = [
        scene.terrain,
        scene.terrainsprites,
        scene.areas,
        scene.tiles,
        scene.paths,
        scene.sprites,
        scene.ux,
    ]
    const sprite_animateds = []
    const updates = []

    // RENDERER
    // const renderer = new WebGLRenderer({ canvas, antialias: true })
    const renderer = new Renderer({ gl })
    renderer.autoClear = false
    renderer.setSize(canvas.width, canvas.height)

    // CAMERA
    const camera = new THREE.PerspectiveCamera(
        fov,
        canvas.width / canvas.height,
        near,
        far
    )

    const { controls } = createInteractivity({
        debug,
        canvas,
        camera,
        renderer,
        isWeb,
        isDesktop,
        isLandscape,
        getPixelRatio,
        onMouseMove,
        onTouchStart,
        onTouchMove,
        onTouchEnd,
        onTouchClick,
        onTouchDragged,
        onWhell,
    })

    function changeCameraPosition({ col, row, time = 1000 }) {
        return new TWEEN.Tween({
            x: controls.target.x,
            z: controls.target.z,
        })
            .to({ x: col, z: row }, time)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(({ x, z }) => changePosition({ x, z, camera, controls }))
            .start()
    }

    function changeCameraRotation({
        angle_vertical,
        angle_horizontal,
        distance,
    }) {
        return changeRotation({
            vertical: degToRad(angle_vertical),
            horizontal: degToRad(angle_horizontal),
            distance,
            controls,
            camera,
        })
    }

    function changeCameraDistance({ distance, time = 1000 }) {
        return new TWEEN.Tween({
            distance: getCameraDistance(),
        })
            .to({ distance }, time)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(({ distance }) => {
                changeRotation({ distance, camera, controls })
            })
            .start()
    }

    function getCameraRotation() {
        const { horizontal } = cartesianToSphericalByControls(controls)
        return convertNegativeRadianIntoDouble(horizontal)
    }

    function getCameraDistance() {
        return camera.position.distanceTo(controls.target)
    }

    function addSpriteAnimated(sprite) {
        sprite_animateds.push(sprite)
    }

    function addUpdate(cb) {
        updates.push(cb)
    }

    function render(time) {
        const delta = clock.getDelta()
        requestAnimationFrame(render)
        renderer.clear()
        scenes.forEach((scene) => {
            renderer.render(scene, camera)
            renderer.clearDepth()
        })
        sprite_animateds.forEach((sprite) => {
            sprite.update(delta)
        })
        updates.forEach((cb) => cb())
        controls.update()
        TWEEN.update(time)
        gl.endFrameEXP()
    }

    render() // Start rendering

    const three = {
        gl,
        renderer,
        canvas,
        render,
        scene,
        addSpriteAnimated,
        addUpdate,
        camera,
        controls,
        sprite_animateds,
        getCameraDistance,
        getCameraRotation,
        changeCameraPosition,
        changeCameraDistance,
        changeCameraRotation,
        createBoard: (props) => createBoard(props, three),
        createTerrainPattern: (props) => createTerrainPattern(props, three),
        createTerrainTile: (props) => createTerrainTile(props, three),
        createTerrainSprite: (props) => createTerrainSprite(props, three),
        createTile: (props) => createTile(props, three),
        createArea: (props) => createArea(props, three),
        createPath: (props) => createPath(props, three),
        createRangeTiles: (props) => createRangeTiles(props, three),
        createHandHelper: (props) => createHandHelper(props, three),
        createUnit: (props) => createUnit(props, three),
        createFlag: (props) => createFlag(props, three),
        createVolumeSprite: (props) => createVolumeSprite(props, three),
        createUxSprite: (props) => createUxSprite(props, three),
    }

    return three
}
