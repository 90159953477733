import React, { useState } from 'react'
import styled from '@emotion/native'
import * as Clipboard from 'expo-clipboard'
import { COLOR } from '~/const'
import { px } from '~/utils/device'
import { translate } from '~/locale'
import { AUDIO } from '~/audio'
import { AnimationFadeOut } from '~/components/Animations'

export default function CopyValue({ children, value }) {
    const [copied, setCopied] = useState(false)

    async function onCopy() {
        AUDIO.CLICK()
        await Clipboard.setStringAsync(value)
        setCopied(true)
    }

    return (
        <BlockCopiedContainer onPress={onCopy}>
            {children}
            {copied && (
                <AnimationFadeOut
                    onFinish={() => setCopied(false)}
                    delay={1000}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <BlockCopiedContent>
                        <BlockCopiedText>{translate('Copied')}</BlockCopiedText>
                    </BlockCopiedContent>
                </AnimationFadeOut>
            )}
        </BlockCopiedContainer>
    )
}

const BlockCopiedContainer = styled.TouchableOpacity`
    flex: 1;
`
const BlockCopiedContent = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${COLOR.GREENDARK};
    border-radius: ${px(50)};
    align-items: center;
    justify-content: center;
`
const BlockCopiedText = styled.Text`
    color: white;
    font-family: Poppins-Medium;
    font-size: ${px(20)};
`
