import { getGlobalState } from 'store/'
import TRANSLATIONS from '~/locale/translations'
import { supplant } from 'utils/'
import { ERROR_DESCRIPTION } from 'const/errors'

export function translate(text, args = {}) {
    const { language } = getGlobalState()
    const translated = getTranslate(text, language)
    return supplant(translated, args)
}

export function getTranslate(text, language = 'en') {
    // if (language === 'en' || text.length === 0) return text
    const text_trimed = text.trim()
    if (
        TRANSLATIONS.hasOwnProperty(text_trimed) &&
        TRANSLATIONS[text_trimed].hasOwnProperty(language)
    ) {
        return TRANSLATIONS[text_trimed][language]
    }
    if (language !== 'en') {
        console.log(`TRANSLATION NOT FOUND
"${text_trimed}": {${language}: ''},`)
    }
    return text
}

export function getError(error) {
    const message = error.message || error.error || error
    return ERROR_DESCRIPTION.hasOwnProperty(message)
        ? ERROR_DESCRIPTION[message]
        : message
}
