import * as THREE from 'three'
import { loadMeshMaterial } from './utils/Loader'
import TWEEN from '@tweenjs/tween.js'

const tile_geometry = new THREE.PlaneGeometry(1, 1)
export default function createTile(options, { scene }) {
    const { scale = 1 } = options
    const mesh = createTileRaw({ ...options })
    let highlight_animation
    let opacity = options.opacity

    scene.tiles.add(mesh)

    return {
        element: mesh,
        remove: () => {
            scene.tiles.remove(mesh)
        },
        hide: () => {
            mesh.visible = false
        },
        show: () => {
            mesh.visible = true
        },
        opacity: (o) => {
            mesh.material.opacity = opacity = o
        },
        setColor: (color) => {
            mesh.material.color = new THREE.Color(color)
        },
        setPosition: ({ col, row }) => {
            mesh.position.x = col
            mesh.position.z = row
        },
        highlight: ({
            opacity_to = opacity,
            scale_to = scale,
            time = 500,
        } = {}) => {
            highlight_animation = new TWEEN.Tween({
                opacity,
                scale,
            })
                .to({ opacity: opacity_to, scale: scale_to }, time)
                .easing(TWEEN.Easing.Quadratic.Out)
                .repeat(Infinity)
                .yoyo(true)
                .onUpdate(({ opacity, scale }) => {
                    mesh.scale.set(scale, scale, scale)
                    mesh.material.opacity = opacity
                })
                .start()
        },
        unhighlight: () => {
            if (highlight_animation !== undefined) {
                highlight_animation.stop()
                highlight_animation = undefined
                mesh.material.opacity = opacity
            }
        },
    }
}

export function createTileRaw({
    url,
    position_x = 0,
    position_y = 0,
    position_z = 0,
    opacity = 1,
    scale = 1,
    color = 0xffffff,
    filter,
    cache,
}) {
    const material = loadMeshMaterial(url, {
        transparent: true,
        opacity,
        cache,
    })

    if (filter !== undefined) {
        material.map.minFilter = filter
        // material.map.minFilter = THREE.NearestFilter
        // material.map.minFilter = THREE.NearestFilter
    }

    const mesh = new THREE.Mesh(tile_geometry, material)
    mesh.position.x = position_x
    mesh.position.y = position_y
    mesh.position.z = position_z
    mesh.rotation.x = -Math.PI / 2
    mesh.material.color = new THREE.Color(color)
    mesh.scale.set(scale, scale, scale)
    return mesh
}
