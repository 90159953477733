import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { screenToWorld } from './utils/'

export default function createInteractivity({
    debug,
    canvas,
    camera,
    renderer,
    isWeb,
    isDesktop,
    getPixelRatio,
    onMouseMove,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onTouchClick,
    onTouchDragged,
    onWhell,
}) {
    const touches = {}
    const state = {
        last: {},
        toucheslength: 1,
        // landscape: isLandscape(),
    }

    if (isWeb()) {
        window.addEventListener('resize', (e) => {
            const ms = isDesktop() ? 250 : 1500
            setTimeout(() => {
                const pixelratio = getPixelRatio()
                const canvas_width = window.innerWidth * pixelratio
                const canvas_height = window.innerHeight * pixelratio
                renderer.setSize(canvas_width, canvas_height)
                camera.aspect = canvas_width / canvas_height
                camera.updateProjectionMatrix()
            }, ms)
        })
    }

    canvas.addEventListener('wheel', onWhell)
    canvas.addEventListener('pointerdown', onPointerDown)
    canvas.addEventListener('pointermove', onPointerMove)
    canvas.addEventListener('pointerup', onPointerUp)

    function onPointerDown(e) {
        // Only if left click
        if (e.button === undefined || e.button === 0) {
            touches[e.pointerId] = e

            if (Object.keys(touches).length === 1) {
                const isTouch = e.pointerType === 'touch'
                const position = getCellFromEvent(e)
                const enablePan = onTouchStart({ e, position, isTouch })

                controls.enablePan = enablePan === undefined ? true : enablePan
                state.position_origin = position
                state.distance = 0
            }
        }
    }

    function onPointerMove(e) {
        const isTouch = e.pointerType === 'touch'
        const position = getCellFromEvent(e)

        if (
            state.last.col !== position.col ||
            state.last.row !== position.row
        ) {
            state.last = position

            if (!isTouch) {
                onMouseMove({ e, position })
            }

            if (Object.keys(touches).length === 1) {
                onTouchMove({
                    e,
                    position,
                    position_origin: state.position_origin,
                    isDragged: !controls.enablePan,
                    isTouch,
                })
            }
        }

        if (state.position_origin !== undefined) {
            const x = position.offsetX - state.position_origin.offsetX
            const y = position.offsetY - state.position_origin.offsetY
            const distance = Math.sqrt(x * x + y * y)
            if (distance > state.distance) {
                state.distance = distance
            }
        }
    }

    function onPointerUp(e) {
        const toucheslength = Object.keys(touches).length

        if (toucheslength === 1 && state.toucheslength === 1) {
            const position = getCellFromEvent(e)
            const isTouch = e.pointerType === 'touch'
            const isDragged = !controls.enablePan
            const isClicked = state.distance < 5

            const params = {
                e,
                position,
                position_origin: state.position_origin,
                isTouch,
                isDragged,
                isClicked,
            }

            onTouchEnd(params)

            if (isClicked) {
                onTouchClick(params)
            }

            if (
                isDragged &&
                positionToTile(params.position) !==
                    positionToTile(params.position_origin)
            ) {
                onTouchDragged(params)
            }

            delete state.position_origin
            delete state.distance

            controls.enablePan = true
        }

        delete touches[e.pointerId]
        state.toucheslength = toucheslength
    }

    function getCellFromEvent(e) {
        const { offsetX, offsetY } = e
        const pos = screenToWorld({
            x: offsetX,
            y: offsetY,
            camera,
            canvas_width: canvas.clientWidth,
            canvas_height: canvas.clientHeight,
        })
        const { x, z } = pos
        const col = Math.floor(x + 0.5)
        const row = Math.floor(z + 0.5)
        return { col, row, x, z, offsetX, offsetY }
    }

    function positionToTile({ col, row }) {
        return `${col}.${row}`
    }

    // CONTROLS
    const controls = new OrbitControls(camera, canvas)
    controls.mouseButtons = {
        LEFT: THREE.MOUSE.PAN,
        RIGHT: debug ? THREE.MOUSE.ROTATE : undefined,
    }
    controls.touches = { ONE: THREE.TOUCH.PAN, TWO: THREE.TOUCH.DOLLY_PAN }
    controls.enableDamping = false
    controls.screenSpacePanning = false
    controls.dampingFactor = 0.1

    return { controls }
}
