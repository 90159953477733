import { createStore, TYPE } from 'dop'
import { EVENT } from 'conkis-core'
import { VIEW, CONNECTION, LOCALSTORAGE } from '~/const'
import {
    getLocale,
    localStorageGet,
    localStorageSet,
    localStorageRemove,
} from '~/utils/device'

export const store = createStore(getDefaultState())

export function getDefaultState(patch = {}, replace = false) {
    const web3 = {
        error: null,
        open: false,
        loaded: false,
        wallets: [],
        wallet: null,
        addresses: [],
        address: null,
        coin: null,
        payment: null,
    }

    return {
        view: VIEW.HOME,
        connection: CONNECTION.CLOSE,
        connection_trys: 0,
        input: null,
        logged: false,
        language: getLocale(),
        sounds: true,
        music: true,
        game_id: null,
        outdated: null,
        playerror: null,
        user: replace ? TYPE.Replace({}) : {},
        web3: replace ? TYPE.Replace(web3) : web3,

        ...patch,
    }
}

export function getGlobalState() {
    return store.state
}

export function setGlobalState(patch) {
    store
        .applyPatch(patch)
        .filter(({ mutations }) => mutations.length > 0)
        .forEach(({ listener, patch }) => listener(patch))
}

export function changeView(view) {
    setGlobalState({ view })
}

export function openWeb3(params = {}) {
    setGlobalState({
        web3: {
            open: true,
            error: null,
            payment: null,
            ...params,
        },
    })
}

// Logic
// Logic
// Logic

export function onServerPatch(patch) {
    // console.log(patch)
    setGlobalState({ user: patch })
}

export async function onLogout(Server) {
    Server.userLogout({})
    localStorageRemove(LOCALSTORAGE.EMAIL)
    localStorageRemove(LOCALSTORAGE.TOKEN)
    setGlobalState(
        getDefaultState({ connection: store.state.connection }, true)
    )
}

export function setSounds(value) {
    setGlobalState({ sounds: value })
    localStorageSet(LOCALSTORAGE.SOUNDS, value)
}

export function setMusic(value) {
    setGlobalState({ music: value })
    localStorageSet(LOCALSTORAGE.MUSIC, value)
}

// Load localStorage
;(async function loadLocalStorage() {
    const language = await localStorageGet(LOCALSTORAGE.LANGUAGE)
    const sounds = await localStorageGet(LOCALSTORAGE.SOUNDS)
    const music = await localStorageGet(LOCALSTORAGE.MUSIC)
    const game_id = await localStorageGet(LOCALSTORAGE.GAME_ID)
    const patch = {}

    if (language !== null) {
        patch.language = language
    }

    if (sounds !== null) {
        patch.sounds = sounds
    }

    if (music !== null) {
        patch.music = music
    }

    if (game_id !== null) {
        patch.game_id = game_id
    }

    setGlobalState(patch)
})()
