import React, { useEffect, useRef } from 'react'
import { useColorScheme, Keyboard } from 'react-native'
import styled from '@emotion/native'
import { PADDING } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import {
    px,
    isNative,
    hideKeyboard,
    isLandscape,
    screenHeightWithKeyboard,
} from '~/utils/device'
import { useGlobalState, useLocalState } from '~/store/hooks'

export default React.memo(function InputApp() {
    const inputref = useRef()
    const [{ input }, setGlobalState] = useGlobalState('input')
    const [state, setState] = useLocalState({
        visible: false,
        text: '',
        inputMode: 'text',
        maxLength: undefined,
        height: screenHeightWithKeyboard(),
        keyboard: { remove: () => {} },
    })

    const color = useColorScheme()
    const isdark = color === 'dark'
    const button_asset = isdark
        ? 'icon-check-input-light.png'
        : 'icon-check-input-dark.png'

    useEffect(() => {
        if (input === null) {
            onClose()
        } else {
            state.keyboard = onKeyboardShowHide((showhide) => {
                setState({ height: screenHeightWithKeyboard() })
                if (showhide === KEYBOARD.HIDE) {
                    onClose()
                }
            })
            onOpen()
        }
    }, [input])

    function onOpen() {
        inputref.current.focus()
        setState({
            visible: true,
            text: input.text,
            textdefault: input.text,
            maxLength: input.maxLength || undefined,
            inputMode: input.inputMode || 'text',
            height: screenHeightWithKeyboard(),
        })
    }

    function onConfirm() {
        if (typeof input.onConfirm === 'function') {
            input.onConfirm()
        }
        onClose()
    }

    function onChange(text) {
        if (typeof input.onChange === 'function') {
            input.onChange(text)
        }
        setState({ text })
    }

    function onCancel() {
        if (typeof input.onCancel === 'function') {
            input.onCancel(state.textdefault)
        }
        onClose()
    }

    function onClose() {
        state.keyboard.remove()
        inputref.current.blur()
        hideKeyboard()
        setState({ visible: false })
        setGlobalState({ input: null })
    }

    return (
        <Background height={state.height}>
            <Blur visible={state.visible} onPress={onCancel} />
            <Content visible={state.visible} isdark={isdark}>
                <InputContainer>
                    <Input
                        value={state.text}
                        inputMode={state.inputMode}
                        maxLength={state.maxLength}
                        returnKeyType="done"
                        isdark={isdark}
                        ref={inputref}
                        onChangeText={onChange}
                        onSubmitEditing={onConfirm}
                    />
                </InputContainer>
                <Button onPress={onConfirm}>
                    <ButtonImage source={UX_ASSETS[button_asset]} />
                </Button>
            </Content>
        </Background>
    )
})

const KEYBOARD = {
    SHOW: 'SHOW',
    HIDE: 'HIDE',
}

function onKeyboardShowHide(callback) {
    if (isNative()) {
        const show = Keyboard.addListener('keyboardDidShow', () =>
            callback(KEYBOARD.SHOW)
        )
        const hide = Keyboard.addListener('keyboardDidHide', () =>
            callback(KEYBOARD.HIDE)
        )
        return {
            remove: () => {
                show.remove()
                hide.remove()
            },
        }
    } else {
        const height = screenHeightWithKeyboard()
        const listener = (event) => {
            if (!isLandscape()) {
                const newheight = screenHeightWithKeyboard()
                callback(newheight === height ? KEYBOARD.HIDE : KEYBOARD.SHOW)
            } else {
                callback(KEYBOARD.HIDE)
            }
        }
        window.visualViewport.addEventListener('resize', listener)

        return {
            remove: () => {
                window.visualViewport.removeEventListener('resize', listener)
            },
        }
    }
}

// const interval = setInterval(() => {
//     const newheight = screenHeightWithKeyboard()
//     if (newheight !== height) {
//         callback(newheight < height ? KEYBOARD.SHOW : KEYBOARD.HIDE)
//         height = newheight
//     }
// }, 250)

// clearInterval(interval)

const Background = styled.View`
    pointer-events: box-none;
    position: absolute;
    width: 100%;
    height: ${(p) => p.height + 'px'};
    left: 0;
    top: 0;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
`

const Blur = styled.Pressable`
    display: ${(p) => (p.visible ? 'flex' : 'none')};
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: default;
    backdrop-filter: blur(10px);
`

const Content = styled.View`
    width: 100%;
    height: ${px(150)};
    opacity: ${(p) => (p.visible ? '1' : '0')};
    pointer-events: ${(p) => (p.visible ? 'all' : 'none')};
    padding: 0 ${px(PADDING.SIDES)};
    flex-direction:row;
    align-items"center;
    backdrop-filter: blur(10px);
    background: ${(p) =>
        p.isdark ? 'rgba(48, 48, 48, 0.8)' : 'rgba(229, 229, 229, 0.8)'};
`

const InputContainer = styled.View`
    flex: 1;
    padding: ${px(25)} 0;
`
const Input = styled.TextInput`
    width: 100%;
    height: 100%;
    border: 0;
    background: ${(p) => (p.isdark ? 'rgba(20, 20, 20, 1)' : 'white')};
    color: ${(p) => (p.isdark ? 'white' : 'black')};
    padding: 0 ${px(30)};
    border-radius: ${px(15)};
    font-size: ${px(45)};
`
const Button = styled.TouchableOpacity`
    width: ${px(140)};
    height: 100%;
`
const ButtonImage = styled.Image`
    width: 100%;
    height: 100%;
`
