import React from 'react'
import styled from '@emotion/native'
import { UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { px } from '~/utils/device'

export default function Checkbox({ label, checked = false, onClick }) {
    return (
        <CheckboxContainer onPress={onClick}>
            <CheckboxIcon
                source={
                    UX_ASSETS[
                        checked ? 'icon-check-on.png' : 'icon-check-off.png'
                    ]
                }
            />
            <CheckboxText checked={checked}>{label}</CheckboxText>
        </CheckboxContainer>
    )
}

const CheckboxContainer = styled.TouchableOpacity`
    width: 100%;
    height: ${px(110)};
    flex-direction: row;
    align-items: center;
    background: ${COLOR.BROWNDARK};
    border: ${px(6)} solid #ffffff11;
    padding-left: ${px(40)};
    border-radius: ${px(60)};
`
const CheckboxIcon = styled.Image`
    width: ${px(50)};
    height: ${px(50)};
`
const CheckboxText = styled.Text`
    margin-left: ${px(25)};
    font-family: ChangaOne;
    color: #ffffff;
    font-size: ${px(45)};
    line-height: ${px(45)};
    opacity: ${(p) => (p.checked ? '1' : '0.5')};
`
