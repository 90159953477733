import React, { useEffect } from 'react'
import styled from '@emotion/native'
import { CARD_RARITY, CARD_RARITY_ID } from 'conkis-core'
import { CHAINS } from '../../npm/web3'
import { getDeck, sortCards } from 'conkis-core/src/utils/cards'
import { AUDIO } from '~/audio'
import { COLOR, PADDING } from '~/const'
import { Server } from '~/server'
import { useGlobalState, useLocalState, useServer } from '~/store/hooks'
import { translate } from '~/locale'
import { px } from '~/utils/device'
import Lobby from '~/components/partials/Lobby'
import { H1 } from '~/components/stateless/Texts'
import Deck from '~/components/stateless/Deck'
import Stat from '~/components/stateless/Stat'
import Button from '~/components/stateless/Button'
import CardsDeck from '~/components/stateless/CardsDeck'
import CardsList from '~/components/stateless/CardsList'
import CardInfo from '~/components/dialogs/CardInfo'
import CardsMint from '~/components/dialogs/CardsMint'

const CARD_SCALE = 1.2

export default function Cards() {
    const [{ user }] = useGlobalState()
    const [state, setState] = useLocalState({
        cards: [],
        mints: [],
        show_card_mint: false,
        show_card_info: null,
    })
    const { cards } = state

    useEffect(() => {
        fetchCards()
    }, [])

    const deck = getDeck(cards)
    const mycards = sortCards(cards.filter((c) => !c.free && c.minted === null))
    const freecards = sortCards(cards.filter((c) => c.free))

    //stats
    const power = cards.reduce((value, card) => value + card.power, 0)
    const common = cards.filter((c) => c.rarity === CARD_RARITY.COMMON).length
    const uncommon = cards.filter(
        (c) => c.rarity === CARD_RARITY.UNCOMMON
    ).length
    const rare = cards.filter((c) => c.rarity === CARD_RARITY.RARE).length
    const epic = cards.filter((c) => c.rarity === CARD_RARITY.EPIC).length
    const legendary = cards.filter(
        (c) => c.rarity === CARD_RARITY.LEGENDARY
    ).length

    async function fetchCards() {
        const cards = await Server.userCards({ user_id: user._id })
        setState({ mints: [], cards })
    }

    function onSelectMint(card) {
        AUDIO.CLICK()
        const mints = state.mints.slice(0)
        if (mints.map((c) => c._id).includes(card._id)) {
            const index = state.mints.indexOf(card)
            mints.splice(index, 1)
        } else {
            mints.push(card)
        }

        setState({ mints })
    }

    function onCardInfo(card) {
        AUDIO.OPEN()
        setState({ show_card_info: card })
    }

    function onCardMint() {
        AUDIO.OPEN()
        setState({ show_cards_mint: true })
    }

    function onCompleteMint() {
        fetchCards()
    }

    return (
        <>
            <Lobby
                title={translate('Cards')}
                bgpattern="bg-weapons-white.png"
                bgcolors={[
                    [COLOR.BEIGE2, 0],
                    [COLOR.BEIGE4, 1],
                ]}
            >
                <ScrollView>
                    <Content>
                        <ContentLeft>
                            <ContentLeftBlock>
                                <H1 align="center" size={25}>
                                    {translate('My Deck in game')}
                                </H1>
                                <CardsDeck>
                                    <Deck
                                        cards={deck}
                                        scale={0.75}
                                        shadow={true}
                                        onClick={onCardInfo}
                                    />
                                </CardsDeck>
                            </ContentLeftBlock>

                            <ContentLeftBlock>
                                <CardsList
                                    scale={CARD_SCALE}
                                    list={mycards}
                                    label={translate('My Cards')}
                                    message={translate(
                                        'You do not own any cards yet'
                                    )}
                                    selecteds={state.mints}
                                    onCard={onCardInfo}
                                    onSelect={onSelectMint}
                                />
                                {cards.length > 0 && (
                                    <CardsListButtons>
                                        <Button
                                            width={300}
                                            height={75}
                                            fontSize={30}
                                            disabled={state.mints.length === 0}
                                            label={`${translate('Mint')} (${
                                                state.mints.length
                                            })`}
                                            onClick={onCardMint}
                                        />
                                    </CardsListButtons>
                                )}
                            </ContentLeftBlock>

                            <>
                                {Object.keys(CHAINS).map((chain_id) => {
                                    const { chainName } = CHAINS[chain_id]
                                    const nfts = sortCards(
                                        cards.filter(
                                            (c) =>
                                                !c.free &&
                                                c.minted?.chain_id === chain_id
                                        )
                                    )
                                    return (
                                        <ContentLeftBlock key={chain_id}>
                                            <CardsList
                                                scale={CARD_SCALE}
                                                list={nfts}
                                                label={translate(
                                                    'My Cards on ${chainName}',
                                                    { chainName }
                                                )}
                                                onCard={onCardInfo}
                                                message={translate(
                                                    'You do not have any card minted on ${chainName}',
                                                    { chainName }
                                                )}
                                            />
                                        </ContentLeftBlock>
                                    )
                                })}
                            </>

                            <ContentLeftBlock>
                                <CardsList
                                    scale={CARD_SCALE}
                                    list={freecards}
                                    label={translate('Free Cards')}
                                    onCard={onCardInfo}
                                />
                                <Message>
                                    {translate(
                                        'Free cards allow any player to compete fairly'
                                    )}
                                    .
                                </Message>
                                <Message>
                                    {translate(
                                        'These cards are rotated periodically'
                                    )}
                                    .
                                </Message>
                            </ContentLeftBlock>
                        </ContentLeft>
                        <ContentRight>
                            <Stat
                                scale={1}
                                label={translate('Power')}
                                value={power}
                                icon="icon-power.png"
                            />
                            {/* <Stat
                                label={translate('Cards')}
                                value={cards.length}
                                icon="icon-cards2.png"
                            /> */}
                            <Stat
                                label={translate(CARD_RARITY_ID[1])}
                                value={common}
                            />
                            <Stat
                                label={translate(CARD_RARITY_ID[2])}
                                value={uncommon}
                            />
                            <Stat
                                label={translate(CARD_RARITY_ID[3])}
                                value={rare}
                            />
                            <Stat
                                label={translate(CARD_RARITY_ID[4])}
                                value={epic}
                            />
                            <Stat
                                label={translate(CARD_RARITY_ID[5])}
                                value={legendary}
                            />
                        </ContentRight>
                    </Content>
                </ScrollView>
            </Lobby>
            {state.show_card_info !== null && (
                <CardInfo
                    card={state.show_card_info}
                    onClose={() => {
                        AUDIO.CLOSE()
                        setState({ show_card_info: null })
                    }}
                />
            )}
            {state.show_cards_mint && (
                <CardsMint
                    cards={state.mints}
                    onComplete={onCompleteMint}
                    onClose={() => {
                        AUDIO.CLOSE()
                        setState({ show_cards_mint: false })
                    }}
                />
            )}
        </>
    )
}

const ScrollView = styled.ScrollView``

const Content = styled.View`
    padding: ${px(75)} ${px(PADDING.SIDES)};
    flex-direction: row;
`

const ContentLeft = styled.View`
    // padding-right: ${px(0)};
    flex: 1;
`

const ContentRight = styled.View`
    width: ${px(440)};
    padding-top: ${px(60)};
`

const ContentLeftBlock = styled.View`
    margin-bottom: ${px(100)};
`

const Message = styled.Text`
    color: ${COLOR.BROWN};
    opacity: 0.4;
    text-align: center;
    font-family: Poppins-Medium;
    font-size: ${px(20)};
`

const CardsListButtons = styled.View`
    margin-top: ${px(20)};
`
