import React from 'react'
import styled from '@emotion/native'
import { COLOR } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { px } from '~/utils/device'

export default function Input({
    width = '100%',
    height = 100,
    color = COLOR.BROWNDARK,
    borderColor = COLOR.BROWN,
    fontSize = 35,
    textAlign = 'left',
    ...props
}) {
    // const ref = useRef(null)
    const { readOnly } = props
    return (
        <Container
            w={width}
            h={height}
            borderColor={borderColor}
            readOnly={readOnly}
            source={UX_ASSETS['input-bg.png']}
            imageStyle={{ resizeMode: 'stretch' }}
        >
            {/* <Background> */}
            <InputStyled
                keyboardAppearance="dark"
                placeholderTextColor={COLOR.DISABLED3}
                color={color}
                fontSize={fontSize}
                textAlign={textAlign}
                {...props}
            />
            {/* </Background> */}
        </Container>
    )
}

const Container = styled.ImageBackground`
    width: ${(p) => px(p.w)};
    height: ${(p) => px(p.h)};
    position: relative;
    background: white;
    border: ${px(8)} solid ${(p) => p.borderColor};
    border-radius: ${px(50)};
    overflow: hidden;
`

const InputStyled = styled.TextInput`
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0 ${px(30)};
    font-size: ${(p) => px(p.fontSize)};
    text-align: ${(p) => p.textAlign};
    font-family: Poppins-Black;
    font-weight: bold;
    background: ${(p) => (p.editable ? 'transparent' : '#CCCCCC')};
    color: ${(p) => (p.readOnly ? '#555' : p.color)};
`
