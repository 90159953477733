import React from 'react'
import styled from '@emotion/native'
import { px } from '~/utils/device'
import { useGlobalState } from '~/store/hooks'
import { COLOR } from '~/const'
import { UX_ASSETS, PROFILE_ICONS } from '~/const/assets'
import Window from '~/components/stateless/Window'

export default function ProfileIcon({ onSelect, onClose }) {
    const [{ user }] = useGlobalState()
    return (
        <Window
            width={'80%'}
            height={'85%'}
            onBackground={onClose}
            onClose={onClose}
        >
            <Content>
                {Object.keys(PROFILE_ICONS)
                    .map((icon_id) => Number(icon_id))
                    .filter((icon_id) => user.icons.includes(icon_id))
                    .map((icon_id) => {
                        const selected = icon_id === user.icon
                        return (
                            <Container
                                disabled={selected}
                                key={icon_id}
                                onPress={() => onSelect(icon_id)}
                            >
                                <Container2 selected={selected}>
                                    <Icon source={PROFILE_ICONS[icon_id]} />

                                    {selected && (
                                        <IconSelectedContainer>
                                            <IconSelected
                                                source={
                                                    UX_ASSETS['icon-check.png']
                                                }
                                            />
                                        </IconSelectedContainer>
                                    )}
                                </Container2>
                            </Container>
                        )
                    })}
            </Content>
        </Window>
    )
}

const Content = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: ${px(50)};
`

const Container = styled.TouchableOpacity`
    padding: ${px(20)};
`

const Container2 = styled.View`
    width: ${px(180)};
    height: ${px(180)};
    border-radius: ${px(180)};
    border: ${px(10)} solid ${(p) => (p.selected ? COLOR.GREEN : COLOR.BEIGE)};
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
`

const Icon = styled.Image`
    width: 100%;
    height: 100%;
    border-radius: ${px(180)};
`

const IconSelectedContainer = styled.View`
    position: absolute;
    width: ${px(64)};
    height: ${px(64)};
    border-radius: ${px(64)};
    background: ${COLOR.GREEN};
    right: ${px(-15)};
    top: ${px(-15)};
    align-items: center;
    justify-content: center;
`

const IconSelected = styled.Image`
    width: ${px(35)};
    height: ${px(35)};
`
