import React from 'react'
import { GLView } from 'expo-gl'
import { GAME_EVENT } from 'conkis-core'
import { createViewElement } from '~/npm/react-native-view-element'
import canvasEvents from './canvasEvents'

export default function Canvas({ emitter, board, state }) {
    const { getHandlers, getElement } = createViewElement()
    const handlers = getHandlers()

    canvasEvents({ emitter, board, state })

    function onContextCreate(gl) {
        const canvas =
            typeof document.getElementsByTagName === 'function'
                ? document.getElementsByTagName('canvas')[0]
                : getElement(gl)

        // FIX / HACK
        // https://github.com/expo/expo-three/issues/293#issuecomment-1585108487
        const pixelStorei = gl.pixelStorei.bind(gl)
        gl.pixelStorei = function (...args) {
            const [parameter] = args
            switch (parameter) {
                case gl.UNPACK_FLIP_Y_WEBGL:
                    return pixelStorei(...args)
            }
        }

        // window.loseContext = () =>
        //     gl.getExtension('WEBGL_lose_context').loseContext()

        canvas.addEventListener('webglcontextlost', (e) => {
            // alert('webglcontextlost: ' + JSON.stringify(e))
            emitter.emit(GAME_EVENT.CANVAS_UNREADY, {})
        })

        emitter.emit(GAME_EVENT.CANVAS_READY, { canvas, gl })
    }

    return (
        <>
            <GLView
                style={{ width: '100%', height: '100%' }}
                onContextCreate={onContextCreate}
                {...handlers}
            />
        </>
    )
}
