import * as THREE from 'three'
import { getUVOffsetByPosition } from './index'

export default function SpriteAnimated({ onEnterFrame } = {}) {
    const animation = {
        playing: true,
        current_frame: 0,
        current_display_time: 0,
        frames: [],
        objects: new THREE.Group(),
    }

    // https://discourse.threejs.org/t/how-to-reuse-the-same-texture-in-different-sprites-and-change-the-uv-individually/14780/3?u=enzo
    animation.addFrames = ({
        object,
        framesHorizontal,
        framesVertical,
        totalFrames = framesHorizontal * framesVertical,
        frameDisplayDuration = 1000 / 30, // 30 frames per second,
        flipHorizontal = false,
        flipVertical = false,
    }) => {
        const frameSet = {
            object,
            framesHorizontal,
            framesVertical,
            flipHorizontal,
            flipVertical,
        }

        // Creating Frames
        for (let frameIndex = 0; frameIndex < totalFrames; ++frameIndex) {
            animation.frames.push({
                frameIndex,
                frameSet,
                frameDisplayDuration,
            })
        }

        object.material.map.repeat.set(
            (flipHorizontal ? -1 : 1) / framesHorizontal,
            (flipVertical ? -1 : 1) / framesVertical
        )

        animation.objects.add(object)
        animation.goto(animation.current_frame)

        return frameSet
    }

    animation.update = (delta) => {
        if (animation.playing) {
            animation.current_display_time += delta * 1000

            const current_frame = animation.current_frame
            const { frameDisplayDuration, onLeaveFrame } =
                animation.frames[current_frame]

            // console.log(animation.current_display_time, frameDisplayDuration)
            while (animation.current_display_time > frameDisplayDuration) {
                animation.current_display_time -= frameDisplayDuration

                if (typeof onEnterFrame == 'function') {
                    onEnterFrame(current_frame)
                }

                if (typeof onLeaveFrame == 'function') {
                    const newCurrentFrame = onLeaveFrame(animation)
                    if (typeof newCurrentFrame == 'number') {
                        return animation.goto(newCurrentFrame)
                    }
                }

                animation.goto(
                    current_frame < animation.frames.length - 1
                        ? current_frame + 1
                        : 0
                )
            }
        }
    }

    animation.play = () => {
        animation.playing = true
        return animation
    }

    animation.pause = () => {
        animation.playing = false
        return animation
    }

    animation.goto = (current_frame) => {
        const { frameSet, frameIndex } = animation.frames[current_frame]
        const {
            framesHorizontal,
            framesVertical,
            flipHorizontal,
            flipVertical,
            object,
        } = frameSet

        // Hiding framesets animation are not being used
        animation.objects.children.forEach((s) => (s.visible = object === s))

        const { col, row } = getPositionByFrame({
            frame: frameIndex,
            framesHorizontal,
            framesVertical,
            flipHorizontal,
            flipVertical,
        })

        const { x, y } = getUVOffsetByPosition({
            col,
            row,
            framesHorizontal,
            framesVertical,
        })
        object.material.map.offset.x = x
        object.material.map.offset.y = y

        animation.current_frame = current_frame

        return animation
    }

    animation.setKeyFrame = (frame, { onLeaveFrame }) => {
        animation.frames[frame].onLeaveFrame = onLeaveFrame
    }

    return animation
}

function getPositionByFrame({
    frame,
    framesHorizontal,
    framesVertical,
    flipHorizontal,
    flipVertical,
}) {
    let col = frame % framesHorizontal
    let row = Math.floor(frame / framesHorizontal)

    if (flipHorizontal) col = framesHorizontal - col - 1
    if (flipVertical) row = framesVertical - row - 1

    return { col, row }
}
