export { default as createThree } from './createThree'

// Boards
import FIRST_BATTLE from './boards/first_battle'

// Assets
import flag from './entities/flag'
import knight from './entities/knight'
import spearman from './entities/spearman'
import axeman from './entities/axeman'
import archer from './entities/archer'
import slinger from './entities/slinger'
import crossbowoman from './entities/crossbowoman'

export const BOARDS = {
    FIRST_BATTLE,
}

export const ENTITIES = {
    flag,
    knight,
    spearman,
    axeman,
    archer,
    slinger,
    crossbowoman,
}
