import React from 'react'
import styled from '@emotion/native'
import { REWARD_ASSET } from 'conkis-core'
import { UX_ASSETS, CARD_ASSETS, PROFILE_ICONS } from '~/const/assets'
import { px } from '~/utils/device'

export default function RewardIcon({ size, asset, id, card, rarity }) {
    return (
        <Container size={size}>
            {asset === REWARD_ASSET.ICON && (
                <Icon
                    width={size * 0.8}
                    height={size * 0.8}
                    rounded={true}
                    source={PROFILE_ICONS[id]}
                />
            )}
            {asset === REWARD_ASSET.CARD && (
                <Icon
                    width={(size * 386) / 512}
                    height={size}
                    source={CARD_ASSETS[rarity + card.toLowerCase()]}
                />
            )}
            {(asset === REWARD_ASSET.TULIPS ||
                asset === REWARD_ASSET.COINS) && (
                <Icon
                    width={size * 0.8}
                    height={size * 0.8}
                    source={
                        asset === REWARD_ASSET.TULIPS
                            ? UX_ASSETS['icon-tulip.png']
                            : UX_ASSETS['icon-coin.png']
                    }
                />
            )}
        </Container>
    )
}

const Container = styled.View`
    width: ${(p) => px(p.size)};
    height: ${(p) => px(p.size)};
    align-items: center;
    justify-content: center;
`

const Icon = styled.Image`
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
    border-radius: ${(p) => (p.rounded ? px(p.width) : 0)};
`
