import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js'
import { loadSpriteMaterial } from './utils/Loader'

export default function createHandHelper({ url_hand, url_circle }, { scene }) {
    const hand = new THREE.Sprite(loadSpriteMaterial(url_hand))
    const circle = new THREE.Sprite(
        loadSpriteMaterial(url_circle, { transparent: true })
    )
    const scale_hand = 4
    const scale_circle = 0.5

    hand.scale.set(scale_hand, scale_hand, scale_hand)
    circle.scale.set(scale_circle, scale_circle, scale_circle)

    const group = new THREE.Group()
    group.add(hand)
    group.add(circle)
    scene.ux.add(group)

    let animation_hand
    let animation_circle

    return {
        element: group,

        remove: () => {
            scene.ux.remove(group)
        },

        show: () => {
            group.visible = true
        },

        hide: () => {
            group.visible = false
        },

        setPosition: ({ col, row }) => {
            group.position.x = col
            group.position.z = row
        },

        animateClick: ({ time = 1000, color }) => {
            if (color !== undefined) {
                circle.material.color = new THREE.Color(color)
            }

            animation_hand = new TWEEN.Tween({
                y: 1,
                rotation: -0.2,
                scale: scale_circle,
            })
                .to({ y: 0, rotation: 0, scale: 0.5 }, time)
                .repeat(Infinity)
                .easing(TWEEN.Easing.Bounce.Out)
                .onUpdate(({ y, rotation, scale }) => {
                    hand.position.y = y
                    hand.material.rotation = rotation
                    circle.scale.set(scale, scale, scale)
                })
                .start()

            animation_circle = new TWEEN.Tween({
                scale: scale_circle,
                opacity: 1.5,
            })
                .to({ scale: 1.5, opacity: 0 }, time / 2)
                .repeat(Infinity)
                .delay(time / 2)
                .easing(TWEEN.Easing.Cubic.Out)
                .onUpdate(({ scale, opacity }) => {
                    circle.scale.set(scale, scale, scale)
                    circle.material.opacity = opacity
                })
                .start()
        },

        stopAnimateClick: () => {
            if (animation_hand !== undefined) {
                animation_hand.stop()
                animation_circle.stop()
            }
        },

        // animateDrag: ({ to, time = 1500 }) => {
        //     animation_drag = new TWEEN.Tween({
        //         x: sprite.position.x,
        //         z: sprite.position.z,
        //     })
        //         .to({ x: to.col, z: to.row }, time)
        //         .repeat(Infinity)
        //         .easing(TWEEN.Easing.Quadratic.Out)
        //         .onUpdate(({ x, z }) => {
        //             sprite.position.x = x
        //             sprite.position.z = z
        //         })
        //         .start()
        // },
    }
}
