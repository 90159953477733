import React from 'react'
import styled from '@emotion/native'
import { UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { px } from '~/utils/device'
import Gradient from '~/components/stateless/Gradient'
import DialogBackground from '~/components/stateless/DialogBackground'

export default function Window({
    children,
    width = 900,
    height = 800,
    pattern = 'bg-pattern.png',
    sides = true,
    gradient1 = COLOR.BEIGE2,
    gradient2 = COLOR.BEIGE4,
    onClose,
    onBackground,
}) {
    return (
        <DialogBackground onPress={onBackground}>
            <Decorations width={width} height={height}>
                {sides && (
                    <DecorationLeft
                        source={UX_ASSETS['dialog-left.png']}
                        imageStyle={{
                            height: '100%',
                            width: '100%',
                            resizeMode: 'stretch', // or 'cover' 'repeat',
                        }}
                    />
                )}
                {sides && (
                    <DecorationRight
                        source={UX_ASSETS['dialog-right.png']}
                        imageStyle={{
                            height: '100%',
                            width: '100%',
                            resizeMode: 'stretch', // or 'cover' 'repeat',
                        }}
                    />
                )}
            </Decorations>
            <Border width={width} height={height}>
                <BackgroundGradient>
                    <Gradient
                        colors={[
                            [gradient1, 0],
                            [gradient2, 1],
                        ]}
                    />
                </BackgroundGradient>
                <BackgroundPattern
                    source={UX_ASSETS[pattern]}
                    imageStyle={{ resizeMode: 'repeat' }}
                />
                <ContainerScroll>
                    <ScrollView>
                        <Content onStartShouldSetResponder={() => true}>
                            {children}
                        </Content>
                    </ScrollView>
                </ContainerScroll>
                {onClose && (
                    <CloseContainer onPress={onClose}>
                        <Close source={UX_ASSETS['icon-close.png']} />
                    </CloseContainer>
                )}
            </Border>
        </DialogBackground>
    )
}

const Decorations = styled.View`
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
`
const DecorationLeft = styled.ImageBackground`
    position: absolute;
    left: -5%;
    height: 100%;
    width: 50%;
`
const DecorationRight = styled.ImageBackground`
    position: absolute;
    right: -5%;
    height: 100%;
    width: 50%;
`
const Border = styled.View`
    position: absolute;
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
    border: ${px(10)} solid rgba(0, 0, 0, 0.6);
    border-radius: ${px(15)};
`
const ContainerScroll = styled.Pressable`
    width: 100%;
    height: 100%;
    cursor: default;
`
const ScrollView = styled.ScrollView`
    width: 100%;
    height: 100%;
`
const Content = styled.View`
    width: 100%;
    height: 100%;
`
const BackgroundGradient = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: ${px(8)};
    overflow: hidden;
`
const BackgroundPattern = styled.ImageBackground`
    position: absolute;
    width: 100%;
    height: 100%;
`
const CloseContainer = styled.TouchableOpacity`
    position: absolute;
    right: ${px(-30)};
    top: ${px(-30)};
    z-index: 1;
`

const Close = styled.Image`
    width: ${px(100)};
    height: ${px(100)};
    transform: rotate(15deg);
`
