import * as THREE from 'three'
import { loadSpriteMaterial } from './utils/Loader'

export default function createTerrainSprite(
    { url, scale = 1, position_x = 0, position_y = 0, position_z = 0 },
    { scene }
) {
    const sprite = new THREE.Sprite(loadSpriteMaterial(url))
    sprite.position.x = position_x
    sprite.position.y = position_y
    sprite.position.z = position_z
    sprite.scale.set(scale, scale, scale)
    scene.terrainsprites.add(sprite)

    // console.log(sprite.geometry.attributes.uv.setXY)
    return {}
}
