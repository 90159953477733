import * as THREE from 'three'
import { createTileRaw } from './createTile'

export default function createRangeTiles(
    { url, tiles, position_y = 0, color = 0xffffff, opacity = 1 },
    { scene }
) {
    const group = new THREE.Group()
    group.position.y = position_y
    scene.tiles.add(group)

    tiles.forEach(({ col, row }) => {
        const tile = createTileRaw({
            url,
            position_x: col,
            position_z: row,
            opacity,
            scale: 1,
            // filter: THREE.NearestFilter,
        })
        tile.material.color = new THREE.Color(color)
        group.add(tile)
    })

    return {
        element: group,
        remove: () => {
            scene.tiles.remove(group)
        },
        show: () => {
            group.visible = true
        },
        hide: () => {
            group.visible = false
        },
        setPosition: ({ col, row }) => {
            group.position.x = col
            group.position.z = row
        },
    }
}
