import React, { useEffect, useState } from 'react'
import styled from '@emotion/native'
import { px, isWeb } from '~/utils/device'

export default function Gif({
    source,
    width,
    height,
    frames,
    ms = isWeb() ? 40 : 25,
}) {
    const [index, setIndex] = useState(0)
    // const [now] = useState(Date.now())

    useEffect(() => {
        setTimeout(() => {
            setIndex(index === frames - 1 ? 0 : index + 1)
        }, ms)
    }, [index])

    // useEffect(() => {
    //     requestAnimationFrame(() => {
    //         setIndex(index === frames - 1 ? 0 : index + 1)
    //         console.log((Date.now() - now) / ms)
    //     })
    // }, [index])

    return (
        <Container width={width} height={height}>
            <Image
                source={source}
                width={width}
                height={height}
                frames={frames}
                index={index}
            />
        </Container>
    )
}

const Container = styled.View`
    overflow: hidden;
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
`

const Image = styled.Image`
    position: relative;
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height * p.frames)};
    top: ${(p) => px(-p.index * p.height)};
`
