import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js'
import SpriteAnimated from './utils/SpriteAnimated'
import { loadSpriteMaterial } from './utils/Loader'
import createTile from './createTile'

const TILE_COLORS = {
    blue: 0x0055da,
    red: 0xf72020,
}

export default function createFlag(
    { body_asset, frames_horizontal, frames_vertical, tile_flag_asset },
    { scene, addSpriteAnimated, addUpdate }
) {
    const group = new THREE.Group()

    const body_material = loadSpriteMaterial(body_asset.url, { cache: false })
    const body_sprite = new THREE.Sprite(body_material)
    // body_sprite.center = new THREE.Vector2(0, 0)
    body_sprite.position.x = body_asset.offset_x || 0
    body_sprite.position.y = body_asset.offset_y || 0
    body_material.map.minFilter = THREE.LinearFilter

    const keyframes = {}
    const spriteanimated = SpriteAnimated()
    spriteanimated.addFrames({
        object: body_sprite,
        framesHorizontal: frames_horizontal,
        framesVertical: frames_vertical,
        frameDisplayDuration: 1000 / 25,
    })
    spriteanimated.pause()

    keyframes['blue'] = () => {
        spriteanimated.goto(0)
        spriteanimated.play()
    }
    spriteanimated.setKeyFrame(46, {
        onLeaveFrame: () => 19,
    })

    keyframes['red'] = () => {
        spriteanimated.goto(47)
        spriteanimated.play()
    }
    spriteanimated.setKeyFrame(93, {
        onLeaveFrame: () => 66,
    })

    keyframes['white'] = () => {
        spriteanimated.goto(94)
        spriteanimated.play()
    }
    spriteanimated.setKeyFrame(121, {
        onLeaveFrame: () => 94,
    })

    const sprites = spriteanimated.objects
    sprites.scale.set(body_asset.scale, body_asset.scale, body_asset.scale)
    group.add(sprites)

    scene.sprites.add(group)

    addSpriteAnimated({
        update: spriteanimated.update,
    })

    const tile = createTile({ ...tile_flag_asset, cache: false }, { scene })
    tile.element.position.y -= 0.01
    addUpdate(() => {
        tile.element.rotation.z += 0.003
    })

    let highlight

    return {
        remove: () => {
            scene.sprites.remove(group)
        },

        setPosition: ({ col, row }) => {
            group.position.x = col
            group.position.z = row
            tile.setPosition({ col, row })
        },

        setTeam: ({ color }) => {
            keyframes[color]()
            if (color === 'white') {
                tile.hide()
            } else {
                tile.show()
                tile.setColor(TILE_COLORS[color])
            }
        },

        highlight: () => {
            highlight = new TWEEN.Tween({
                scale: body_asset.scale * 1.25,
            })
                .to(
                    {
                        scale: body_asset.scale,
                    },
                    1000
                )
                .easing(TWEEN.Easing.Quadratic.Out)
                .repeat(Infinity)
                .yoyo(true)
                .onUpdate(({ scale }) => {
                    sprites.scale.set(scale, scale, scale)
                })
                .start()
        },

        unhighlight: () => {
            sprites.scale.set(
                body_asset.scale,
                body_asset.scale,
                body_asset.scale
            )
            if (highlight) {
                highlight.stop()
            }
        },
    }
}
