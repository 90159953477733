import { isDesktop } from '~/utils/device'

export const CONNECTION = {
    CLOSE: 'CLOSE',
    CONNECTING: 'CONNECTING',
    OPEN: 'OPEN',
}

export const LOCALSTORAGE = {
    EMAIL: 'EMAIL',
    TOKEN: 'TOKEN',
    LANGUAGE: 'LANGUAGE',
    SOUNDS: 'SOUNDS',
    MUSIC: 'MUSIC',
    GAME_ID: 'GAME_ID',
    WEB3: 'WEB3',
}

export const VIEW = {
    HOME: 'HOME',
    PROFILE: 'PROFILE',
    CARDS: 'CARDS',
    PLAY: 'PLAY',
    SHOP: 'SHOP',
    SEARCHING: 'SEARCHING',
    GAME: 'GAME',
}

export const CARD_SIZE = {
    WIDTH: 185,
    HEIGHT: 268,
}

export const COLOR = {
    DISABLED: '#84796f',
    DISABLED2: '#5f564e',
    DISABLED3: '#CCCCCC',

    BROWN: '#67372e', //'#6e4430',
    BROWNDARK: '#482019',
    BROWNBLACK: '#2b120e',

    BLUE: '#2c5171',
    BLUEDARK: '#1F384D',
    BLUEBLACK: '#0c2036',

    GREEN: '#91a627',
    GREENDARK: '#6a7821',
    RED: '#ff411d',
    RED2: '#AE331C',
    REDDARK: '#8e3726',
    REDPINK: '#DD4247',

    BEIGE: '#fff1de', //'#f1edd7',
    BEIGE2: '#f6ddbb', //'#e8c79b'
    BEIGE3: '#dabfa2',
    BEIGE4: '#c6a582', //'#be9968'
    BEIGE5: '#9c8266',

    ORANGE: '#fb9a5e',
    ORANGE2: '#f08e52',
}

export const TILE_COLORS = {
    BLUE: 0x4285ed,
    BLUELIGHT: 0x81d1ff,
    RED: 0xef2a03, //0xfa5e40,
    GREEN: 0x17ff4e,
    GREENLIGHT: 0x87ff67, // 0x17ff4e
    WHITE: 0xffffff,
    WHITELIGHT: 0xd9fba4,
    ORANGE: 0xf08000,
    ATTACK: 0xff0000,
    ATTACK_BONUS: 0x480010,
}

export const PADDING = {
    SIDES: isDesktop() ? 100 : 100,
}

export const MONTH = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]
