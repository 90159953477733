import * as THREE from 'three'
import { degreeToPosition, degToRad } from './utils/'
import { loadMeshMaterial, loadSpriteMaterial } from './utils/Loader'

export default function createIcons({}, { addUpdate }) {
    const group = new THREE.Group()
    const icons = {}

    function updatePositions() {
        const list = Object.keys(icons)
        const degree = 360 / list.length

        list.forEach((id, index) => {
            const { x, y } = degreeToPosition({
                degree: degree * index,
                radius: 0.5,
            })
            icons[id].position.x = x
            icons[id].position.z = y
        })
    }

    // const axis = new THREE.AxesHelper()
    // axis.scale.set(5, 5, 5)
    // group.add(axis)

    addUpdate(() => {
        group.rotation.y -= 0.005
        // group.children.forEach((cube) => {
        //     // cube.rotation.x -= 0.002
        //     // cube.rotation.y -= 0.002
        //     // cube.rotation.z += 0.002
        // })
    })

    return {
        element: group,

        add: ({ id, url, scale, shadow_url, shadow_scale }) => {
            // const sprite = new THREE.Sprite(loadSpriteMaterial(url))
            // icons[id] = sprite
            // sprite.scale.set(scale, scale, scale)
            // group.add(sprite)

            const groupcube = new THREE.Group()
            group.add(groupcube)
            icons[id] = groupcube

            const cube = new THREE.Mesh(
                new THREE.BoxGeometry(scale, scale, scale),
                loadMeshMaterial(url)
            )
            cube.position.y = 0.4
            cube.rotation.x = degToRad(-45)
            cube.rotation.x = degToRad(45)
            groupcube.add(cube)

            const shadow = new THREE.Mesh(
                new THREE.PlaneGeometry(1, 1),
                loadMeshMaterial(shadow_url, {
                    transparent: true,
                    // opacity: 1,
                })
            )
            shadow.position.y = 0.1
            shadow.rotation.x = -Math.PI / 2
            shadow.scale.set(shadow_scale, shadow_scale, shadow_scale)
            groupcube.add(shadow)

            updatePositions()
        },

        remove: ({ id }) => {
            group.remove(icons[id])
            delete icons[id]
            updatePositions()
        },
    }
}
