import * as web3 from 'conkis-core/src/web3'

const EVENTS = {
    EIP6963_ANNOUNCE: 'eip6963:announceProvider',
    EIP6963_REQUEST: 'eip6963:requestProvider',
}

export const COINS = web3.COINS
export const CHAIN = web3.CHAIN
export const CHAINS = web3.CHAINS
export const ethers = web3.ethers
export const getShorterAddress = web3.getShorterAddress

export function getInjectedWallets(callback) {
    const onAnnouncement = (event) => callback(event.detail)
    window.addEventListener(EVENTS.EIP6963_ANNOUNCE, onAnnouncement)
    window.dispatchEvent(new Event(EVENTS.EIP6963_REQUEST))

    return () => {
        window.removeEventListener(EVENTS.EIP6963_ANNOUNCE, onAnnouncement)
    }
}

export function getDefaultWallet() {
    return window.ethereum === undefined
        ? null
        : { info: {}, provider: window.ethereum }
}

export async function connectWallet({ wallet }) {
    return await wallet.provider.request({
        method: 'eth_requestAccounts',
    })
}

export async function getChainId({ wallet }) {
    return await wallet.provider.request({
        method: 'eth_chainId',
        params: [],
    })
}

export async function selectChain({ wallet, chain }) {
    try {
        await wallet.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chain.chainId }],
        })
    } catch (error) {
        if (error.code === 4902) {
            await wallet.provider.request({
                method: 'wallet_addEthereumChain',
                params: [chain],
            })
        } else {
            throw error
        }
    }
}

export async function signMessage({ wallet, message, address }) {
    // const message_hex = `0x${Buffer.from(message, 'utf8').toString('hex')}`
    const message_hex = ethers.hexlify(ethers.toUtf8Bytes(message))

    return await wallet.provider.request({
        method: 'personal_sign',
        params: [message_hex, address],
    })
}

export async function getBalance({ wallet, address, decimals = 18 }) {
    // const balance = await wallet.provider.request({
    //     method: 'eth_getBalance',
    //     params: [address, block],
    // })
    const provider = new ethers.BrowserProvider(wallet.provider)
    return await web3.getBalance({ provider, address, decimals })
}

export async function sendCoins({
    wallet,
    address,
    address_to,
    amount,
    decimals = 18,
}) {
    const provider = new ethers.BrowserProvider(wallet.provider)
    const signer = await provider.getSigner(address)
    return await web3.sendCoins({
        signer,
        address_to,
        amount,
        decimals,
    })
}
