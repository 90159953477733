const TRANSLATIONS = {
    '': { es: '' },

    January: { es: 'Enero' },
    February: { es: 'Febrero' },
    March: { es: 'Marzo' },
    Apr: { es: 'Abril' },
    May: { es: 'Mayo' },
    June: { es: 'Junio' },
    July: { es: 'Julio' },
    August: { es: 'Agosto' },
    September: { es: 'Septiembre' },
    October: { es: 'Octubre' },
    November: { es: 'Noviembre' },
    December: { es: 'Diciembre' },

    Email: { es: 'Email' },
    Password: { es: 'Contraseña' },

    Close: { es: 'Cerrar' },
    Play: { es: 'Jugar' },
    Shop: { es: 'Tienda' },
    Cards: { es: 'Cartas' },
    Coins: { es: 'Monedas' },
    Tulips: { es: 'Tulipanes' },
    Profile: { es: 'Perfil' },
    Trophies: { es: 'Trofeos' },
    'Highest Trophies': { es: 'Record Trofeos' },
    Games: { es: 'Partidas' },
    Wins: { es: 'Ganadas' },
    Draws: { es: 'Empatadas' },
    Loses: { es: 'Perdidas' },
    Level: { es: 'Nivel' },
    Sounds: { es: 'Sonidos' },
    Music: { es: 'Música' },
    'Latest Games': { es: 'Ultimas Partidas' },
    Confirm: { es: 'Confirmar' },
    Cancel: { es: 'Cancelar' },
    'Searching for players': { es: 'Buscando jugadores' },
    'Earn rewards by waiting in the queue': {
        es: 'Gana recompensas por esperar en la cola',
    },

    'Reload Client': { es: 'Recargar Cliente' },
    'Full Screen': { es: 'Pantalla Completa' },
    Loading: { es: 'Cargando' },
    'Your Turn': { es: 'Tu turno' },
    'Enemy Turn': {
        es: 'Turno enemigo',
    },

    Tutorial: { es: 'Tutorial' },
    Competitive: { es: 'Competitivo' },
    'Ranked games': { es: 'Partidas clasificatorias' },
    Custom: { es: 'Personalizada' },
    'Invite your friend': { es: 'Invita a un amigo' },
    Invite: { es: 'Invitar' },
    'Name#000': { es: 'Nombre#000' },
    'Send Invitation': { es: 'Enviar Invitación' },
    'Send another Invitation': { es: 'Enviar otra Invitación' },
    'Invitation Sent': { es: 'Invitación Enviada' },
    'Or share your username with your friends': {
        es: 'O comparte tu usuario con tus amigos',
    },
    'is not available to be invited': {
        es: 'no está disponible para ser invitado',
    },
    '${username} invites you to play a game': {
        es: '${username} te ha invitado a jugar una partida',
    },
    '${username} has canceled the game or is not ready': {
        es: '${username} ha cancelado la partida o no esta disponible',
    },

    Resign: { es: 'Rendirse' },
    Mint: { es: 'Mint' },
    Deck: { es: 'Mazo' },
    'My Cards': { es: 'Mis Cartas' },
    'My Cards on ${chainName}': { es: 'Mis Cartas en ${chainName}' },

    Acquisition: { es: 'Adquisición' },
    Reward: { es: 'Recompensa' },
    Shop: { es: 'Tienda' },
    'Give Away': { es: 'Regalo' },
    'Free Cards': { es: 'Cartas Gratis' },
    'Used Cards': { es: 'Cartas Usadas' },
    Accept: { es: 'Aceptar' },
    Round: { es: 'Ronda' },
    Disconnected: { es: 'Desconectado' },
    'Match Found': { es: 'Partida Encontrada' },
    'Hide Results': { es: 'Ocultar Resultados' },
    'Show Results': { es: 'Mostrar Resultados' },
    'Bonus time': { es: 'Tiempo extra' },
    'Date of mint': { es: 'Fecha de minteo' },
    'Manage NFT': { es: 'Gestionar NFT' },
    'You do not have any card minted on ${chainName}': {
        es: 'No tienes ninguna carta minteada en ${chainName}',
    },
    'Waiting for other players to accept': {
        es: 'Esperando a que los otros jugadores acepten',
    },
    '${flags} of ${flags_to_win}': { es: '${flags} de ${flags_to_win}' },
    '${username} has not used any cards yet': {
        es: '${username} no ha usado ninguna carta todavia',
    },
    "${username} hasn't played any games yet": {
        es: '${username} no ha jugado ninguna partida todavia',
    },
    Victory: { es: 'Victoria' },
    Defeat: { es: 'Derrota' },
    Draw: { es: 'Empate' },
    VICTORY: { en: 'Victory', es: 'Victoria' },
    DEFEAT: { en: 'Defeat', es: 'Derrota' },
    DRAW: { en: 'Draw', es: 'Empate' },
    ANNIHILATION: { en: 'By Annihilation', es: 'Por Aniquilación' },
    FLAGS: { en: 'Captured Flags', es: 'Banderas Capturadas' },
    RESIGN: { en: 'By Resignation', es: 'Por Abandono' },

    Power: { en: 'Power', es: 'Power' },
    COMMON: { en: 'Common', es: 'Common' },
    UNCOMMON: { en: 'Uncommon', es: 'Uncommon' },
    RARE: { en: 'Rare', es: 'Rare' },
    EPIC: { en: 'Epic', es: 'Epic' },
    LEGENDARY: { en: 'Legendary', es: 'Legendary' },

    'Log In': { es: 'Entrar' },
    'Sign Up': { es: 'Registrarse' },
    'Log Out': { es: 'Desconectar' },
    Logout: { es: 'Desconectar' },
    Exit: { es: 'Cerrar' },
    'Reset Password': {
        es: 'Recuperar Contraseña',
    },
    'Reload App': { es: 'Recargar App' },
    Sending: { es: 'Enviando' },
    Reconnect: { es: 'Reconectar' },
    Reconnecting: { es: 'Reconectando' },
    'We have lost the connection with the server.': {
        es: 'Se ha perdido la conexion con el servidor.',
    },
    'An update of the app is required.': {
        es: 'Se requiere una actualización de la aplicación.',
    },

    'Reconnecting in ${seconds}...': { es: 'Reconectando en ${seconds}...' },

    'No connection to the server': { es: 'Sin conexión con el servidor' },
    'Invalid app version': { es: 'Version de app invalida' },

    'Go Home': { es: 'Volver al inicio' },

    Code: { es: 'Código' },
    'Please enter the code sent to': { es: 'Introduce el código enviado a' },
    'Go back to send another code': { es: 'Ir atrás para enviar otro código' },

    'Select your User Name': { es: 'Elige tu Nombre de usuario' },
    Username: { es: 'Usuario' },
    Send: { es: 'Enviar' },
    Copied: { es: 'Copiado' },
    'Tap to copy': { es: 'Tap para copiar' },

    'You do not own any cards yet': { es: 'Aún no tienes ninguna carta' },
    'Free cards allow any player to compete fairly': {
        es: 'Las cartas gratis permiten a cualquier jugador competir de forma justa',
    },
    'These cards are rotated periodically': {
        es: 'Estas cartas son rotadas periodicamente',
    },
    'My Deck in game': { es: 'Mi Mazo en partida' },
    'Profile Icon': { es: 'Icono de Perfil' },
    Collect: { es: 'Recoger' },
    'Contains an Icon to customize your profile.': {
        es: 'Contiene un Icono para personalizar tu perfil.',
    },
    'Contains the ${label} card, ${rarity} rarity.': {
        es: 'Contiene la carta ${label}, de rareza ${rarity}.',
    },
    'Contains ${amount} Tulips. Collect Tulips to buy cards.': {
        es: 'Contiene ${amount} Tulipanes. Acumula Tulipanes para comprar cartas.',
    },
    'Contains ${amount} Coins. Collect Coins to buy special cards or tulips.': {
        es: 'Contiene ${amount} Monedas. Acumula Monedas para comprar cartas o tulipanes.',
    },

    'You have earned a new Icon!': {
        es: 'Has ganado un Icono!',
    },
    'You have earned a ${rarity} Card!': {
        es: 'Has ganado una Carta ${rarity}!',
    },
    'You have earned these Tulips!': { es: 'Has ganado estos Tulipanes!' },
    'You have earned these Coins!': { es: 'Has ganado estas Monedas!' },
    Continue: {
        es: 'Continuar',
    },
    'End Turn': {
        es: 'Terminar Turno',
    },
    Recruit: {
        es: 'Reclutar',
    },
    'Recruit your unit ${recruited} of ${units_player}': {
        es: 'Recluta tu unidad ${recruited} de ${units_player}',
    },
    'Drag and confirm position': { es: 'Arrastra y confirma posicion' },
    'Reload game': { es: 'Recargar partida' },
    'Load more': {
        es: 'Cargar más',
    },
    'Games are disabled because we are in maintenance mode. Please, try again later.':
        {
            es: 'Las partidas estan inactivas porque estamos en modo mantenimiento. Por favor, intentalo de nuevo mas tarde.',
        },

    // SHOP
    'Daily Offers': { es: 'Ofertas Diarias' },
    'All Cards': { es: 'Todas las Cartas' },
    'New balance': { es: 'Nuevo balance' },
    'Available soon': { es: 'Disponible pronto' },
    '${h}h ${m}m': { es: '${h}h ${m}m' },
    'Bonus: ${number}': { es: 'Bonus: ${number}' },
    'Purchase completed': { es: 'Compra completada' },
    'Error: ${error}': { es: 'Error: ${error}' },
    'Tulips after purchasing: ${number}': {
        es: 'Tulipanes después de la compra: ${number}',
    },
    'Coins after purchasing: ${number}': {
        es: 'Monedas después de la compra: ${number}',
    },
    'Cards you have of this kind: ${number}': {
        es: 'Cartas que tienes de este tipo: ${number}',
    },
    'These daily offers ends in ${h}h ${m}m': {
        es: 'Estas ofertas diaras acaban en ${h}h ${m}m',
    },
    'An error occurred during the purchase. Try again reloading the shop.': {
        es: 'Se produjo un error durante la compra. Inténtalo de nuevo recargando la tienda.',
    },
    'An error occurred during the purchase. Try again later or contact support.':
        {
            es: 'Se produjo un error durante la compra. Inténtalo de nuevo mas tarde o contacta con el soporte.',
        },
    'An error occurred during the minting. Try again later or contact support.':
        {
            es: 'Se produjo un error durante el minteo. Inténtalo de nuevo mas tarde o contacta con el soporte.',
        },
    'Processing payment with ${wallet}': {
        es: 'Procesando pago con ${wallet}',
    },
    'Payment Method': { es: 'Metodo de Compra' },
    'Wallet / MetaMask': { es: 'Monedero / MetaMask' },
    'Deposit / Send Tx': { es: 'Depósito / Enviar Tx' },
    'See tx on the block-explorer': { es: 'Ver tx en el block-explorer' },
    To: { es: 'A' },
    From: { es: 'Desde' },
    'Pay Now': { es: 'Pagar Ahora' },

    // WALLET / WEB3
    Disconnect: {
        es: 'Desconectar',
    },
    'No wallets detected to connect': {
        es: 'No se detectaron billeteras para conectar',
    },
    'Connect with:': {
        es: 'Conectar con:',
    },
    'An error occurred connecting the wallet': {
        es: 'Ha ocurrido un error al conectar la billetera',
    },
    'An error occurred switching the network': {
        es: 'Ha ocurrido un error al cambiar de red',
    },
    'User denied account authorization': {
        es: 'El usuario ha denegado conexion',
    },
    Free: { es: 'Gratis' },
    'Mint completed': { es: 'Minteo completado' },
    Address: { es: 'Dirección' },
    'Invalid Address': { es: 'Dirección invalida' },
    'Be sure you control this address. Otherwise, you will lose your cards': {
        es: 'Asegurate de controlar esta dirección. De lo contrario perderás tus cartas',
    },

    'Select Coin': { es: 'Selecciona Moneda' },
    'Select Address': { es: 'Selecciona Dirección' },
    'Select Blockchain': { es: 'Selecciona Blockchain' },
    'Enter Address': { es: 'Ingresa Dirección' },
    'Send ${amount} ${symbol} to this address and wait for the confirmation': {
        es: 'Envia ${amount} ${symbol} a esta dirección y espera la confirmación',
    },
    'Insufficient balance': { es: 'Saldo insuficiente' },
    'This price for the payment will be available for': {
        es: 'Este precio para el pago estará disponible durante',
    },
    'After canceling a match you must wait ${seconds} seconds to play again.': {
        es: 'Despues de cancelar una partida debes esperar ${seconds} segundos para jugar otra vez.',
    },

    // REWARDS
    // REWARDS
    // REWARDS
    // REWARDS
    SEARCH_QUEUE: { en: 'Search queue', es: 'Cola de búsqueda' },
    LEVEL_UP: { en: 'Level up', es: 'Subir de nivel' },
    FIRST_VICTORY: {
        en: 'First victory of the day',
        es: 'Primera victoria del dia',
    },
    FIRST_GAME: { en: 'First game of the day', es: 'Primera partida del dia' },
    UNITS_KILLED: { en: 'Units killed', es: 'Unidades asesinadas' },
    FLAGS_CAPTURED: { en: 'Flags captured', es: 'Banderas capturadas' },
    BONUS_TIME: { en: 'Bonus time', es: 'Tiempo extra' },

    // CARDS
    // CARDS
    // CARDS
    // CARDS
    'Poisoned Arrow': { es: 'Flecha Envenenada' },
    'The selected Archer or Crossbowoman will attack with a poisoned arrow, causing the targeted enemy to be unable to attack or move on their next turn.':
        {
            es: 'El Archer o Crossbowoman seleccionado atacará con una flecha envenenada. El enemigo alcanzado no será capaz de atacar o moverse en el siguiente turno.',
        },
    Downwind: { es: 'Viento a favor' },
    "Increases the unit's movement until reaching the maximum range of the Archer.":
        {
            es: 'Aumenta el movimiento de la unidad hasta obtener el alcance máximo del Arquero.',
        },

    'Wood Shield': { es: 'Escudo de madera' },
    'The selected unit will use the shield to block the next attack.': {
        es: 'La unidad seleccionada usará su escudo para bloquear el siguiente ataque.',
    },

    'Holy Berries': { es: 'Bayas Sagradas' },
    'It restores the maximum life to one unit.': {
        es: 'Restaura la vida maxima de una unidad.',
    },

    Adrenaline: { es: 'Adrenalina' },
    "The unit's damage is increased by one point for the next attack.": {
        es: 'Incrementa el daño de una unidad en un punto para el siguiente ataque.',
    },

    // Cards messages
    'Select one unit': {
        es: 'Selecciona una unidad',
    },
    'Select one Archer or Crossbowoman': {
        es: 'Selecciona un Archer o un Crossbowoman',
    },
    'Select one Spearman or Axeman': {
        es: 'Selecciona un Spearman o un Axeman',
    },
    'Select a low health unit': {
        es: 'Selecciona una unidad baja de vida',
    },

    '${username} has used ${type}': { es: '${username} ha usado ${type}' },

    // ERROR_DESCRIPTIONS
    // ERROR_DESCRIPTIONS
    // ERROR_DESCRIPTIONS
    // ERROR_DESCRIPTIONS
    'Please enter a valid email': { es: 'Por favor introduce un email válido' },
    'It has not been possible to send the email, try again in a few seconds': {
        es: 'No ha sido posible enviar el email, inténtalo otra vez en unos segundos',
    },
    'The code is invalid or has expired': {
        es: 'El código es invalido o ha expirado',
    },
    'Wait a few seconds and try again': {
        es: 'Espera unos segundos e intentalo de nuevo',
    },
    'Only letters and numbers are allowed': {
        es: 'Solo letras y numeros estan permitidos',
    },
    'Invalid username and tagid': {
        es: 'Nombre de usuario y tagid invalido',
    },

    'Game not found': { es: 'Partida no encontrada' },
}

export default TRANSLATIONS
