import React from 'react'
import styled from '@emotion/native'
import { COLOR } from '~/const'
import { px } from '~/utils/device'
import Counter from '~/components/stateless/Counter'

export default function Stat({ label, value, icon = null, scale = 1 }) {
    return (
        <StatRow>
            <StatRowLeft scale={scale}>{label}</StatRowLeft>
            <StatRowRight>
                <Counter value={value} icon={icon} scale={scale} />
            </StatRowRight>
        </StatRow>
    )
}

const StatRow = styled.View`
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: ${px(20)};
`
const StatRowLeft = styled.Text`
    font-family: Poppins-Black;
    font-size: ${(p) => px(25 * p.scale)};
    color: ${COLOR.BROWN};
    padding-right: ${(p) => px(45 * p.scale)};
    text-align: right;
`
const StatRowRight = styled.View``
