import * as THREE from 'three'
import { TextureLoader } from 'expo-three'
import { wait } from 'conkis-core/src/utils'

const sources = {}
const sprites = {}
const meshes = {}

// export function loadTexture(url) {
//     return new TextureLoader().load(url)
// }

// https://github.com/mrdoob/three.js/pull/22846
export function loadTexture(url, delay = 250) {
    let texture = new THREE.Texture()

    const applySource = async (texture, source) => {
        // Somehow we need this timeout to make it work in iOS/Android
        // https://github.com/mrdoob/three.js/pull/22846#issuecomment-1766524303
        // console.log(url, delay, Date.now())
        await wait(delay)
        texture.source = source
        texture.needsUpdate = true
    }

    if (sources[url] === undefined) {
        sources[url] = { loading: true, textures: [] }
        texture = new TextureLoader().load(url, async (texture) => {
            const source = texture.source
            sources[url].source = source
            sources[url].loading = false
            // sources[url].textures.forEach((texture) => {
            //     applySource(texture, source)
            // })
            for await (const texture of sources[url].textures) {
                await applySource(texture, source)
            }
            // sources[url].textures.push(texture)
        })
    } else if (sources[url].loading) {
        sources[url].textures.push(texture)
    } else {
        applySource(texture, sources[url].source)
    }

    return texture
}

export function loadSpriteMaterial(url, { cache = true, delay, ...opts } = {}) {
    if (cache && sprites[url] !== undefined) {
        return sprites[url]
    }
    return (sprites[url] = new THREE.SpriteMaterial({
        map: loadTexture(url, delay),
        ...opts,
    }))
}

export function loadMeshMaterial(url, { cache = true, delay, ...opts } = {}) {
    if (cache && meshes[url] !== undefined) {
        return meshes[url]
    }
    return (meshes[url] = new THREE.MeshBasicMaterial({
        map: loadTexture(url, delay),
        ...opts,
    }))
}
