import React, { useState, useRef } from 'react'
import styled from '@emotion/native'
import { CARDS, REWARD_ASSET, CARD_RARITY_ID } from 'conkis-core'
import { getMilestoneRewards } from 'conkis-core/src/rewards'
import { AnimationPulsate } from '~/components/Animations'
import { Server } from '~/server'
import { AUDIO } from '~/audio'
import { useGlobalState } from '~/store/hooks'
import { UX_ASSETS } from '~/const/assets'
import { PADDING, COLOR } from '~/const'
import { translate } from '~/locale'
import { px, isTouchable } from '~/utils/device'
import Lobby from '~/components/partials/Lobby'
import RewardIcon from '~/components/stateless/RewardIcon'
import HorizontalScroll from '~/components/stateless/HorizontalScroll'
import RewardCollected from '~/components/dialogs/RewardCollected'

export default function Progress() {
    const [{ user }] = useGlobalState(({ path }) => path[1] === 'rewards')
    const scrollview = useRef(null)
    const [leftbtn, setLeftbtn] = useState(false)
    const [rightbtn, setRightbtn] = useState(false)
    const [collect, setCollect] = useState(null)

    const milestones = getMilestoneRewards()
    const { trophies, rewards } = user
    const items = milestones.length
    const margin = 100 / items / 2
    const milestone = milestones
        .map(({ ...params }, index) => ({
            ...params,
            index,
            pvalue: milestones[index - 1]?.value || 0,
        }))
        .find(({ value, pvalue }) => trophies >= pvalue && trophies < value)

    const subindex =
        milestone === undefined
            ? 0
            : ((trophies - milestone.pvalue) * 100) /
              (milestone.value - milestone.pvalue)

    const index =
        milestones.length === 0
            ? 0
            : milestone === undefined
            ? (trophies * items) / milestones[items - 1].value
            : milestone.index + subindex / 100
    const indexfinal = index > items + 1 ? items + 1 : index
    const percent = (indexfinal * 100) / items
    const bar = percent - margin < 0 ? px(60) : `${percent - margin}%`

    function scrollTo(x) {
        scrollview.current.scrollTo({ x, y: 0, animated: true })
    }

    function scrollToTrophies() {
        const { width, maxwidth } = scrollview.current
        const halfcontent = width / 2
        const halfmilestone = maxwidth / items / 2
        const trophy_position = (percent * maxwidth) / 100
        scrollTo(trophy_position - halfmilestone - halfcontent)
    }

    function onContentSizeChange(width) {
        scrollview.current.maxwidth = width
        if (scrollview.current.width !== undefined) {
            scrollToTrophies()
        }
    }

    function onLayout(e) {
        const { width } = e.nativeEvent.layout
        scrollview.current.width = width
        if (scrollview.current.maxwidth !== undefined) {
            scrollToTrophies()
        }
    }

    function onScroll(e) {
        if (scrollview.current !== null) {
            const { x } = e.nativeEvent.contentOffset
            const { width, maxwidth } = scrollview.current
            const trophy_position = (percent * maxwidth) / 100
            const halfmilestone = maxwidth / items / 2
            const max = trophy_position - halfmilestone
            const min = trophy_position - width - halfmilestone
            setLeftbtn(x > 0 && x > max)
            setRightbtn(x < min)
        }
    }

    function onLeft() {
        AUDIO.CLICK()
        scrollToTrophies()
        // setLeftbtn(false)
    }

    function onRight() {
        AUDIO.CLICK()
        scrollToTrophies()
        // setRightbtn(false)
    }

    function onCollect(collect) {
        AUDIO.REWARD()
        const { value } = collect
        Server.userCollectReward({ value })
        setCollect(collect)
    }

    return (
        <>
            <Lobby footer={true}>
                {milestones.length === 0 ? null : (
                    <ContainerScroll>
                        <HorizontalScroll
                            ref={scrollview}
                            scrollEventThrottle={250}
                            onScroll={onScroll}
                            onContentSizeChange={onContentSizeChange}
                            onLayout={onLayout}
                        >
                            <Container>
                                <Content>
                                    <BarContainer>
                                        <BarBackground
                                            source={
                                                UX_ASSETS['bg-diagonal.png']
                                            }
                                            imageStyle={{
                                                resizeMode: 'repeat',
                                            }}
                                        ></BarBackground>
                                    </BarContainer>

                                    <List>
                                        {milestones.map((milestone, key) => {
                                            const { value, reward } = milestone
                                            const collected =
                                                rewards.hasOwnProperty(value)
                                            const collectable =
                                                trophies >= Number(value)
                                            const params = convertReward(
                                                collected
                                                    ? rewards[value]
                                                    : reward
                                            )
                                            // console.log(params)
                                            return (
                                                <Item key={key}>
                                                    <Reward
                                                        {...params}
                                                        collectable={
                                                            collectable
                                                        }
                                                        collected={collected}
                                                        onClick={() =>
                                                            onCollect({
                                                                value,
                                                                reward,
                                                                params,
                                                            })
                                                        }
                                                    />
                                                    <Milestone>
                                                        <MilestoneTop>
                                                            <MilestoneTrophies>
                                                                <MilestoneTrophiesIcon
                                                                    source={
                                                                        UX_ASSETS[
                                                                            'icon-trophy2.png'
                                                                        ]
                                                                    }
                                                                />
                                                                <MilestoneTrophiesValue>
                                                                    {value}
                                                                </MilestoneTrophiesValue>
                                                            </MilestoneTrophies>
                                                        </MilestoneTop>

                                                        <MilestoneBottom>
                                                            <MilestoneBottomImage
                                                                source={
                                                                    UX_ASSETS[
                                                                        'bg-rewardmilestone.png'
                                                                    ]
                                                                }
                                                            />
                                                        </MilestoneBottom>
                                                    </Milestone>
                                                </Item>
                                            )
                                        })}
                                    </List>

                                    <BarContainer>
                                        <BarFrontContainer>
                                            <BarFront
                                                bar={bar}
                                                source={UX_ASSETS['bg-bar.png']}
                                                imageStyle={{
                                                    resizeMode: 'stretch',
                                                    width: '100%',
                                                }}
                                            >
                                                <Trophies
                                                    source={
                                                        UX_ASSETS[
                                                            'bg-rewardtrophies.png'
                                                        ]
                                                    }
                                                >
                                                    <TrophiesContent>
                                                        <TrophiesIcon
                                                            source={
                                                                UX_ASSETS[
                                                                    'icon-trophy.png'
                                                                ]
                                                            }
                                                        />
                                                        <TrophiesValue>
                                                            {trophies}
                                                        </TrophiesValue>
                                                    </TrophiesContent>
                                                </Trophies>
                                            </BarFront>
                                        </BarFrontContainer>
                                    </BarContainer>
                                </Content>
                            </Container>
                        </HorizontalScroll>
                        {leftbtn && (
                            <Left onPress={onLeft}>
                                <RightImage
                                    source={
                                        UX_ASSETS['button-rewards-left.png']
                                    }
                                />
                            </Left>
                        )}
                        {rightbtn && (
                            <Right onPress={onRight}>
                                <RightImage
                                    source={
                                        UX_ASSETS['button-rewards-right.png']
                                    }
                                />
                            </Right>
                        )}
                    </ContainerScroll>
                )}
            </Lobby>
            {collect !== null && (
                <RewardCollected
                    collect={collect}
                    onClose={() => {
                        AUDIO.CLOSE()
                        setCollect(null)
                    }}
                />
            )}
        </>
    )
}

function Reward({
    collectable,
    collected,
    asset,
    id,
    card,
    rarity,
    label,
    description,
    width = 120,
    height = 120,
    rounded = false,
    onClick,
}) {
    const [showdesc, setDesc] = useState(false)
    const props = {
        [isTouchable() ? 'onPress' : 'onPressOut']:
            collectable && !collected
                ? onClick
                : (e) => {
                      AUDIO.CLICK()
                      setDesc(!showdesc)
                  },
    }

    return (
        <RewardContainer {...props}>
            <RewardBackground
                source={
                    UX_ASSETS[
                        collected
                            ? 'bg-reward2.png'
                            : collectable
                            ? 'bg-reward3.png'
                            : 'bg-reward.png'
                    ]
                }
            >
                <RewardContent>
                    {!showdesc ? (
                        <RewardIcon
                            asset={asset}
                            id={id}
                            card={card}
                            rarity={rarity}
                            size={160}
                        />
                    ) : (
                        <RewardInfo>{description}</RewardInfo>
                    )}
                </RewardContent>

                {(collected || !collectable) && (
                    <RewardLabelContainer highlight={collected}>
                        <RewardLabel>{label}</RewardLabel>
                    </RewardLabelContainer>
                )}

                {collectable && !collected && (
                    <AnimationPulsate>
                        <RewardCollectButton>
                            <RewardCollectText>
                                {translate('Collect')} !
                            </RewardCollectText>
                        </RewardCollectButton>
                    </AnimationPulsate>
                )}

                {collected && (
                    <RewardCheck>
                        <RewardCheckIcon source={UX_ASSETS['icon-check.png']} />
                    </RewardCheck>
                )}
            </RewardBackground>
        </RewardContainer>
    )
}

function convertReward(reward) {
    if (reward.asset === REWARD_ASSET.ICON) {
        return {
            ...reward,
            label: translate('Profile Icon'),
            description: translate(
                'Contains an Icon to customize your profile.'
            ),
        }
    }

    if (reward.asset === REWARD_ASSET.CARD) {
        const label = translate(CARDS[reward.card].title)
        const rarity = translate(CARD_RARITY_ID[reward.rarity])
        return {
            ...reward,
            label,
            description: translate(
                'Contains the ${label} card, ${rarity} rarity.',
                { label, rarity }
            ),
        }
    }

    if (reward.asset === REWARD_ASSET.TULIPS) {
        return {
            ...reward,
            label: `x ${reward.amount}`,
            description: translate(
                'Contains ${amount} Tulips. Collect Tulips to buy cards.',
                reward
            ),
        }
    }

    if (reward.asset === REWARD_ASSET.COINS) {
        return {
            ...reward,
            label: `x ${reward.amount}`,
            description: translate(
                'Contains ${amount} Coins. Collect Coins to buy special cards or tulips.',
                reward
            ),
        }
    }
}

const ContainerScroll = styled.View`
    width: 100%;
    height: 100%;
    justify-content: center;
`

const Left = styled.TouchableOpacity`
    position: absolute;
    bottom: ${px(0)};
    left: ${px(PADDING.SIDES / 3)};
    width: ${px(180)};
    height: ${px(180)};
`

const Right = styled.TouchableOpacity`
    position: absolute;
    bottom: ${px(0)};
    right: ${px(PADDING.SIDES / 3)};
    width: ${px(180)};
    height: ${px(180)};
`

const RightImage = styled.Image`
    width: ${px(180)};
    height: ${px(180)};
`

const Container = styled.View`
    width: 100%;
    height: 100%;
    justify-content: center;
`

const Content = styled.View`
    // margin: 0 ${px(PADDING.SIDES)};
`

const List = styled.View`
    flex-direction: row;
`

const Item = styled.View`
    padding: 0 ${px(50)};
    align-items: center;
`

const RewardContainer = styled.TouchableOpacity`
    width: ${px(230)};
    height: ${px(300)};
`

const RewardBackground = styled.ImageBackground`
    width: 100%;
    height: 100%;
`

const RewardContent = styled.View`
    width: 100%;
    height: 90%;
    align-items: center;
    justify-content: center;
`

const RewardInfo = styled.Text`
    width: 80%;
    // margin-top: ${px(70)};
    font-family: Poppins-Bold;
    color: ${COLOR.BROWN};
    font-size: ${px(18)};
    line-height: ${px(25)};
    text-align: center;
`

const RewardLabelContainer = styled.View`
    width: 100%;
    position: absolute;
    left: ${px(-5)};
    bottom: ${px(30)};
    height: ${px(40)};
    background: ${(p) => (p.highlight ? COLOR.ORANGE2 : COLOR.BROWNBLACK)};
    align-items: center;
    justify-content: center;
`

const RewardCollectButton = styled.View`
    width: 100%;
    top: ${px(-20)};
    height: ${px(50)};
    background: ${COLOR.GREEN};
    box-shadow: 0px ${px(4)} ${COLOR.GREENDARK};
    border-radius: ${px(100)};
    align-items: center;
    justify-content: center;
`

const RewardCollectText = styled.Text`
    font-family: Poppins-Bold;
    color: ${COLOR.BEIGE};
    font-size: ${px(22)};
    text-shadow: 0 ${px(2)} 0 rgba(0, 0, 0, 0.5);
`

const RewardCheck = styled.View`
    position: absolute;
    left: ${px(-10)};
    top: ${px(-10)};
    width: ${px(60)};
    height: ${px(60)};
    background: ${COLOR.GREEN};
    border-radius: ${px(50)};
    align-items: center;
    justify-content: center;
    box-shadow: 0 ${px(5)} ${px(5)} rgba(0, 0, 0, 0.4);
`

const RewardCheckIcon = styled.Image`
    width: ${px(30)};
    height: ${px(30)};
`

const RewardLabel = styled.Text`
    font-family: Poppins-Bold;
    color: ${COLOR.BEIGE};
    font-size: ${px(22)};
    text-shadow: 0 ${px(2)} 0 rgba(0, 0, 0, 0.5);
`

const Milestone = styled.View`
    // height: ${px(130)};
`

const MilestoneTop = styled.View``

const MilestoneTrophies = styled.View`
    flex-direction: row;
    align-items: center;
    margin: ${px(20)} 0;
`
const MilestoneTrophiesIcon = styled.Image`
    width: ${px(16)};
    height: ${px(16)};
`
const MilestoneTrophiesValue = styled.Text`
    font-family: Poppins-Bold;
    color: ${COLOR.BEIGE2};
    font-size: ${px(18)};
    // line-height: ${px(22)};
    padding-left: ${px(5)};
`

const MilestoneBottom = styled.View`
    align-items: center;
    height: ${px(70)};
`

const MilestoneBottomImage = styled.Image`
    width: ${px(16)};
    height: ${px(70)};
`

const BarContainer = styled.View`
    position: absolute;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    pointer-events: none;
`

const BarBackground = styled.ImageBackground`
    height: ${px(40)};
    width: 100%;
    background: ${COLOR.BROWNBLACK};
    justify-content: center;
`

const BarFrontContainer = styled.View`
    height: ${px(40)};
    width: 100%;
    justify-content: center;
`

const BarFront = styled.ImageBackground`
    height: ${px(32)};
    width: ${(p) => p.bar};
    background: ${COLOR.ORANGE};
`

const Trophies = styled.ImageBackground`
    position: absolute;
    top: ${px(-10)};
    right: ${px(-130 / 2)};
    width: ${px(130)};
    height: ${px(55)};
    padding-top: ${px(6)};
    align-items: center;
`

const TrophiesContent = styled.View`
    flex-direction: row;
    align-items: center;
`

const TrophiesIcon = styled.Image`
    width: ${px(26)};
    height: ${px(26)};
`
const TrophiesValue = styled.Text`
    font-family: Poppins-Bold;
    color: ${COLOR.BEIGE};
    // letter-spacing: ${px(1)};
    font-size: ${px(26)};
    padding-left: ${px(5)};
    text-shadow: 0 ${px(2)} 0 rgba(0, 0, 0, 0.5);
`
