import React from 'react'
import styled from '@emotion/native'
import { px } from '~/utils/device'
import { COLOR } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import Dialog from '~/components/stateless/Dialog'

export default function DialogShop({
    children,
    width = 600,
    height = 800,
    onClose,
}) {
    return (
        <Dialog
            width={width}
            height={height}
            gradient={false}
            borderWidth={15}
            borderRadius={30}
            borderRadiusInner={20}
            backgroundPattern={UX_ASSETS['dialog-bg-shop.png']}
            backgroundPatternResize="stretch"
            onClose={onClose}
            onBackground={onClose}
        >
            <Container>{children}</Container>
        </Dialog>
    )
}

const Container = styled.View`
    flex: 1;
    padding: ${px(40)};
`
