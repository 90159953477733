import * as THREE from 'three'

export function radToDeg(rad) {
    return rad * (180 / Math.PI)
}

export function degToRad(deg) {
    return deg * (Math.PI / 180)
}

export function degreeToPosition({ degree, radius }) {
    const x = radius * Math.cos(degToRad(degree))
    const y = radius * Math.sin(degToRad(degree))
    return { x, y }
}

export function randomInt(min, max) {
    const i = (Math.random() * 32768) >>> 0
    return (i % (min - (max + 1))) + min
}

// Usage:
// console.log(supplant('"${v|Hola} mundo ${v2}"', { v: 'Hola', v2: 'cruel' }))
export function supplant(str) {
    let args = arguments,
        t = args.length - 1,
        i = 1

    return (function _supplant(i) {
        str = str.replace(/\${([^{}]*)}/g, function (a, wordoptions) {
            // Spliting options
            let words = wordoptions.split('|')

            // Recruiting random word
            let word =
                words.length > 1
                    ? words[Math.floor(Math.random() * words.length)]
                    : words[0]

            // Grabing
            let parse = args[i][word]
            let tof = typeof parse
            return tof === 'string' || tof === 'number' ? parse : word
        })
        if (i < t) return _supplant(i + 1)

        return str
    })(i)
}

export function convertNegativeRadianIntoDouble(rad, max = Math.PI) {
    return rad < 0 ? max * 2 + rad : rad
}

export function changePosition({ x, z, camera, controls }) {
    const diffX = controls.target.x - x
    const diffZ = controls.target.z - z
    controls.target.setX(x)
    controls.target.setZ(z)
    camera.position.setX(camera.position.x - diffX)
    camera.position.setZ(camera.position.z - diffZ)
}

export function changeRotation({
    camera,
    controls,
    vertical = controls.getPolarAngle(),
    horizontal = controls.getAzimuthalAngle(),
    distance = camera.position.distanceTo(controls.target),
}) {
    const point = sphericalToCartesian({ vertical, horizontal, distance })
    camera.position.set(
        point.x + controls.target.x,
        point.y + controls.target.y,
        point.z + controls.target.z
    )
}

// https://gist.github.com/jhermsmeier/72626d5fd79c5875248fd2c1e8162489
export function sphericalToCartesian({ horizontal, vertical, distance = 1 }) {
    const theta = horizontal
    const phi = vertical
    const vector = new THREE.Vector3()
    vector.setFromSphericalCoords(distance, phi, theta)
    return vector
}

export function cartesianToSpherical({ x, y, z }) {
    const sphere = new THREE.Spherical()
    sphere.setFromCartesianCoords(x, y, z)
    return {
        vertical: sphere.phi,
        horizontal: sphere.theta,
        distance: sphere.radius,
    }
}

export function cartesianToSphericalByControls(controls) {
    return {
        vertical: controls.getPolarAngle(),
        horizontal: controls.getAzimuthalAngle(),
    }
}

export function getRotationFromPoints(from, to) {
    return convertNegativeRadianIntoDouble(
        Math.atan2(from.x - to.x, from.y - to.y)
    )
}

// https://stackoverflow.com/questions/27409074/converting-3d-position-to-2d-screen-position-r69
export function worldToScreen({ x, y, z, camera, canvasWidth, canvasHeight }) {
    const vector = new THREE.Vector3(x, y, z)
    const widthHalf = canvasWidth / 2
    const heightHalf = canvasHeight / 2

    vector.project(camera)
    const newX = vector.x * widthHalf + widthHalf
    const newY = -(vector.y * heightHalf) + heightHalf

    return { x: newX, y: newY }
}

// https://stackoverflow.com/questions/34660063/threejs-converting-from-screen-2d-coordinate-to-world-3d-coordinate-on-the-cane
// https://discourse.threejs.org/t/convert-screen-2d-to-world-3d-coordiate-system-without-using-raycaster/13739/7
export function screenToWorld({ x, y, canvas_width, canvas_height, camera }) {
    const worldPosition = new THREE.Vector3()
    const plane = new THREE.Plane(new THREE.Vector3(0.0, 1.0, 0.0))
    const raycaster = new THREE.Raycaster()
    const coords = new THREE.Vector3(
        (x / canvas_width) * 2 - 1,
        -(y / canvas_height) * 2 + 1,
        0.5
    )
    raycaster.setFromCamera(coords, camera)
    return raycaster.ray.intersectPlane(plane, worldPosition)
}

// https://discourse.threejs.org/t/how-to-limit-pan-in-orbitcontrols-for-orthographiccamera/9061/7
export function createLimitPan({ camera, controls }) {
    const v = new THREE.Vector3()
    const minPan = new THREE.Vector3()
    const maxPan = new THREE.Vector3()
    return ({
        maxX = Infinity,
        minX = -Infinity,
        maxY = Infinity,
        minY = -Infinity,
        maxZ = Infinity,
        minZ = -Infinity,
    }) => {
        minPan.set(minX, minY, minZ)
        maxPan.set(maxX, maxY, maxZ)
        v.copy(controls.target)
        controls.target.clamp(minPan, maxPan)
        v.sub(controls.target)
        camera.position.sub(v)
    }
}

export function getUVOffsetByPosition({
    col,
    row,
    framesVertical,
    framesHorizontal,
    flipHorizontal = false,
    flipVertical = false,
}) {
    const x = col / framesHorizontal
    const y = (framesVertical - row - 1) / framesVertical

    return {
        x: flipHorizontal ? 1 - x : x,
        y: flipVertical ? 1 - y : y,
    }
}
