import React, { useRef, useState, useEffect } from 'react'
import styled from '@emotion/native'
import Card from '~/components/stateless/Card'
import { CARD_ASSETS, UX_ASSETS } from '~/const/assets'
import { CARD_SIZE } from '~/const'
import { cardToAsset } from '~/utils'
import { px, isTouchable } from '~/utils/device'
import HorizontalScroll from '~/components/stateless/HorizontalScroll'

export default function Deck({ cards, scale = 1, shadow = false, onClick }) {
    const [centered, setCentered] = useState(false)
    const [length, setLength] = useState(cards.length)
    const scrollview = useRef(null)
    const touchable = isTouchable()

    useEffect(() => {
        // console.log('useEffect', length, cards.length)
        setLength(cards.length)
        if (!(cards.length < length && centered)) {
            onCentered(true)
        }
    }, [cards])

    function onLayout(e) {
        const { width } = e.nativeEvent.layout
        scrollview.current.width = width
        onCentered()
    }

    function onContentSizeChange(contentsize) {
        scrollview.current.contentsize = contentsize
        onCentered()
    }

    function onCentered(updated = false) {
        const { width, contentsize } = scrollview.current
        if (width !== undefined && contentsize !== undefined) {
            // console.log({
            //     centered,
            //     width,
            //     contentsize,
            //     length,
            //     cardslength: cards.length,
            // })

            if (centered === false && width > contentsize) {
                setCentered(true)
            } else if (
                centered === true &&
                width <= contentsize &&
                updated === true
            ) {
                setCentered(false)
            }
        }
    }

    return (
        <ContainerScroll scale={scale}>
            <HorizontalScroll
                ref={scrollview}
                // onScroll={onScroll}
                onContentSizeChange={onContentSizeChange}
                onLayout={onLayout}
                contentContainerStyle={
                    centered
                        ? {
                              flex: 1,
                              justifyContent: 'center',
                          }
                        : null
                }
            >
                <List>
                    {cards.map((card, index) => {
                        return (
                            <CardContainer scale={scale} key={index}>
                                {shadow && (
                                    <CardShadow
                                        scale={scale}
                                        source={UX_ASSETS['card-shadow.png']}
                                    />
                                )}
                                <Card
                                    scale={scale}
                                    power={card.power}
                                    counter={card.counter}
                                    disabled={card.disabled}
                                    touchable={touchable}
                                    onClick={(e) => {
                                        onClick(card, index, e)
                                    }}
                                    source={CARD_ASSETS[cardToAsset(card)]}
                                />
                            </CardContainer>
                        )
                    })}
                </List>
            </HorizontalScroll>
        </ContainerScroll>
    )
}

const padding_shadow = 8
const padding_scroll = 20
const padding_sides = 5

const ContainerScroll = styled.View`
    // background: red;
    width: 100%;
    height: ${(p) =>
        px((CARD_SIZE.HEIGHT + padding_shadow + padding_scroll) * p.scale)};
`

const List = styled.View`
    // background: green;
    flex-direction: row;
`

const CardContainer = styled.View`
    padding: 0 ${(p) => px(padding_sides * p.scale)};
    height: ${(p) => px((CARD_SIZE.HEIGHT + padding_shadow) * p.scale)};
`

const CardShadow = styled.Image`
    position: absolute;
    left: ${(p) => px(padding_sides * p.scale)};
    bottom: ${px(0)};
    opacity: 0.15;
    width: ${(p) => px(CARD_SIZE.WIDTH * p.scale)};
    height: ${(p) => px(50 * p.scale)};
`
