import * as THREE from 'three'
import { MeshLine, MeshLineMaterial } from '~/npm/three.meshline'

// let zindex = 1

export default function createArea(
    {
        from,
        to,
        holes = [],
        color = 0xffffff,
        opacity = 0.4,
        lineColor = 0xffffff,
        lineOpacity = 0,
        lineWidth = 0.1,
        position_y = 0,
    },
    { scene }
) {
    const group = new THREE.Group()
    const from_x = from.col - 0.5
    const from_y = from.row - 0.5
    const to_x = to.col + 1 - 0.5
    const to_y = to.row + 1 - 0.5

    const shape = new THREE.Shape()
    shape.moveTo(from_x, from_y)
    shape.lineTo(to_x, from_y)
    shape.lineTo(to_x, to_y)
    shape.lineTo(from_x, to_y)

    holes.forEach((hole) => {
        const hole_path = new THREE.Path()
        const { from, to } = hole
        const from_x = from.col - 0.5
        const from_y = from.col - 0.5
        const to_x = to.col + 1 - 0.5
        const to_y = to.row + 1 - 0.5
        hole_path.moveTo(from_x, from_y)
        hole_path.lineTo(to_x, from_y)
        hole_path.lineTo(to_x, to_y)
        hole_path.lineTo(from_x, to_y)
        shape.holes.push(hole_path)
    })

    const geometry = new THREE.ShapeGeometry(shape)
    const area = new THREE.Mesh(
        geometry,
        new THREE.MeshBasicMaterial({
            color,
            opacity,
            side: THREE.DoubleSide,
            transparent: true,
            depthWrite: false,
        })
    )
    group.add(area)

    if (lineOpacity > 0) {
        const meshline = new MeshLine()
        const points = []
        points.push(from_x, from_y, 0)
        points.push(to_x, from_y, 0)
        points.push(to_x, to_y, 0)
        points.push(from_x, to_y, 0)
        points.push(from_x, from_y, 0)
        meshline.setPoints(points)
        const line = new THREE.Mesh(
            meshline,
            new MeshLineMaterial({
                lineWidth,
                color: lineColor,
                opacity: lineOpacity,
                transparent: true,
                // dashArray: 0.05,
                // dashRatio: 0.95,
            })
        )
        group.add(line)
    }

    // zindex += 10
    group.position.y = position_y //|| zindex / 10000
    group.rotation.x = Math.PI / 2
    scene.areas.add(group)

    return {
        element: group,
        remove: () => {
            scene.areas.remove(group)
            // zindex -= 10
        },
        setPosition: ({ col, row }) => {
            group.position.x = col
            group.position.z = row
        },
    }
}
