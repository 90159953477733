import React from 'react'
import styled from '@emotion/native'
import { UX_ASSETS, PROFILE_ICONS } from '~/const/assets'
import { COLOR } from '~/const'
import { px } from '~/utils/device'
import Counter from '~/components/stateless/Counter'
import CircleProgress from '~/components/stateless/CircleProgress'

export default function Player({
    username,
    trophies,
    level,
    progress,
    icon,
    onPress,
}) {
    return (
        <PlayerComponent onPress={onPress}>
            <PlayerContainer>
                <PlayerContainer2>
                    <PlayerContainer3
                        source={UX_ASSETS['input-bg.png']}
                        imageStyle={{
                            resizeMode: 'stretch',
                        }}
                    >
                        <PlayerName>
                            <PlayerNameValue>{username}</PlayerNameValue>
                        </PlayerName>
                        <PlayerTrophys>
                            <Counter
                                value={trophies}
                                icon="icon-trophy.png"
                                scale={0.75}
                                align="center"
                                color={COLOR.BEIGE}
                                color_text={COLOR.BROWNDARK}
                            />
                        </PlayerTrophys>
                    </PlayerContainer3>
                    <Xp value={level} progress={progress} />
                </PlayerContainer2>

                <PlayerPicContainer>
                    <PlayerPic source={PROFILE_ICONS[icon]} />
                </PlayerPicContainer>
            </PlayerContainer>
        </PlayerComponent>
    )
}

const PlayerComponent = styled.TouchableOpacity`
    width: auto;
    display: flex;
    // top: ${px(15)};
`
const PlayerContainer = styled.View`
    flex-direction: row;
`

const PlayerContainer2 = styled.View`
    left: ${px(20)};
    background: ${COLOR.BROWNDARK + 'dd'};
    border-radius: ${px(5)};
    flex-direction: row;
    align-items: center;
`

const PlayerContainer3 = styled.ImageBackground`
    height: ${px(65)};
    flex-direction: row;
    align-items: center;
    padding-left: ${px(15)};
    padding-right: ${px(50)};
    border-radius: ${px(5)};
    overflow: hidden;
`

const PlayerPicContainer = styled.View`
    position: absolute;
    left: ${px(-20)};
    top: ${px(-5)};
    width: ${px(75)};
    height: ${px(75)};
    border-radius: ${px(75 / 2)};
    border: ${px(6)} solid ${COLOR.BEIGE};
    background: white;
    overflow: hidden;
`

const PlayerPic = styled.Image`
    width: 100%;
    height: 100%;
    background: ${COLOR.DISABLED2};
`

const PlayerTrophys = styled.View`
    // position: absolute;
    // left: ${px(0)};
    // top: ${px(-25)};
`

const PlayerName = styled.View`
    min-width: ${px(200)};
    align-items: center;
`
const PlayerNameValue = styled.Text`
    padding: 0 ${px(40)};
    font-family: Poppins-Black;
    font-size: ${px(30)};
    color: ${COLOR.BEIGE};
    position: relative;
    text-shadow: 0 ${px(5)} 0 rgba(0, 0, 0, 0.25);
`

function Xp({ value, progress, size = 50, strokeWidth = 7 }) {
    return (
        <XpContainer size={size} strokeWidth={strokeWidth}>
            <XpCircle size={size}>
                <CircleProgress
                    size={size}
                    strokeWidth={strokeWidth}
                    progress={progress}
                />
            </XpCircle>
            <XpValue size={size / 2.5}>{value}</XpValue>
            {/* <XpValue size={size / 4}>xp</XpValue> */}
        </XpContainer>
    )
}

const XpContainer = styled.View`
    position: absolute;
    width: ${(p) => px(p.size)};
    height: ${(p) => px(p.size)};
    right: ${(p) => px(-(p.size - p.strokeWidth) / 2)};
    align-items: center;
    justify-content: center;
    // border-radius: ${(p) => px(p.size)};
    // box-shadow: 0 ${px(0)} ${px(10)} black;
`

const XpCircle = styled.View`
    position: absolute;
    width: ${(p) => px(p.size)};
    height: ${(p) => px(p.size)};
`

const XpValue = styled.Text`
    font-family: Poppins-Bold;
    font-size: ${(p) => px(p.size)};
    // line-height: ${(p) => px(p.size)};
    color: ${COLOR.BROWNDARK};
    position: relative;
    // background: red;
`
