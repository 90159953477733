import React, { useEffect } from 'react'
import styled from '@emotion/native'
import { GAME_TYPE, GAME_RESULT } from 'conkis-core'
import { getLevel } from 'conkis-core/src/utils/xp'
import { UX_ASSETS, PROFILE_ICONS } from '~/const/assets'
import { COLOR, PADDING, MONTH } from '~/const'
import { AUDIO } from '~/audio'
import { useGlobalState, useLocalState } from '~/store/hooks'
import { Server } from '~/server'
import { translate } from '~/locale'
import { px } from '~/utils/device'
import Lobby from '~/components/partials/Lobby'
import Stat from '~/components/stateless/Stat'
import Gradient from '~/components/stateless/Gradient'
import { H1 } from '~/components/stateless/Texts'
import ProfileIcon from '~/components/dialogs/ProfileIcon'

const scalecounter = 1

export default function Profile() {
    const [{ user }] = useGlobalState()
    const { level, percent, xpnext } = getLevel(user.xp)
    const progress = `${percent}%`
    const [state, setState] = useLocalState({
        profileicon: false,
        games: [],
        games_page: 1,
        games_loading: false,
    })

    useEffect(() => {
        fetchGames()
    }, [])

    async function onSelectProfileIcon(icon) {
        AUDIO.CLICK()
        await Server.userSetProfileIcon({ icon })
        setState({ profileicon: false })
    }

    async function fetchGames() {
        setState({
            games_loading: true,
        })
        const result = await Server.userGames({
            user_id: user._id,
            page: state.games_page,
        })
        setState({
            games: state.games.concat(result.games),
            games_page: state.games_page + 1,
            games_loading: false,
            games_more: result.more,
        })
    }

    return (
        <>
            <Lobby
                title={translate('Profile')}
                bgpattern="bg-weapons-white.png"
                bgcolors={[
                    [COLOR.BEIGE2, 0],
                    [COLOR.BEIGE4, 1],
                ]}
            >
                <ScrollView>
                    <Content>
                        <Left>
                            <LeftTop>
                                <ProfileContainer>
                                    <ProfileUserName
                                        source={UX_ASSETS['bg-diagonal.png']}
                                        imageStyle={{ resizeMode: 'repeat' }}
                                    >
                                        <ProfileName>
                                            {user.username}
                                        </ProfileName>
                                        <ProfileTag>#{user.tagid}</ProfileTag>
                                    </ProfileUserName>
                                    <ProfileProgress>
                                        <ProfileProgressBarContainer
                                            progress={progress}
                                        >
                                            <ProfileProgressBar>
                                                <Gradient
                                                    colors={[
                                                        ['#f9cbaf', 0.04],
                                                        ['#faaf80', 0.11],
                                                        ['#fb9b60', 0.5],
                                                        ['#e99663', 0.93],
                                                        ['#de8854', 1],
                                                    ]}
                                                />
                                            </ProfileProgressBar>
                                        </ProfileProgressBarContainer>
                                        <ProfileProgressData>
                                            <ProfileProgressDataLeft>
                                                {translate('Level')} {level}
                                            </ProfileProgressDataLeft>
                                            <ProfileProgressDataRight>
                                                <ProfileProgressDataRightText1>
                                                    {user.xp}
                                                </ProfileProgressDataRightText1>
                                                <ProfileProgressDataRightText2>
                                                    {' '}
                                                    / {xpnext}
                                                </ProfileProgressDataRightText2>
                                            </ProfileProgressDataRight>
                                        </ProfileProgressData>
                                    </ProfileProgress>
                                    <ProfilePicContainer
                                        onPress={() => {
                                            AUDIO.CLICK()
                                            setState({ profileicon: true })
                                        }}
                                    >
                                        <ProfilePicContainer2
                                            source={PROFILE_ICONS[user.icon]}
                                        ></ProfilePicContainer2>
                                        <ProfilePicContainerEdit>
                                            <ProfilePicContainerEditIcon
                                                source={
                                                    UX_ASSETS['icon-edit.png']
                                                }
                                            />
                                        </ProfilePicContainerEdit>
                                    </ProfilePicContainer>
                                </ProfileContainer>
                            </LeftTop>
                            <LeftBottom>
                                <H1>{translate('Latest Games')}</H1>
                                {state.games.length > 0 && (
                                    <GameList>
                                        {state.games.map((game, key) => {
                                            const user_ids = Object.keys(
                                                game.users
                                            )
                                            const players = user_ids.map(
                                                (user_id) => {
                                                    const isme =
                                                        user._id === user_id
                                                    const { trophies } =
                                                        game.users[user_id]
                                                            .before

                                                    const { player_id } =
                                                        game.users[user_id]
                                                    const trophiesnew =
                                                        game.users[user_id]
                                                            .after.trophies -
                                                        trophies

                                                    const username =
                                                        game.board.state
                                                            .players[player_id]
                                                            .username
                                                    return {
                                                        isme,
                                                        user_id,
                                                        player_id,
                                                        trophies,
                                                        trophiesnew,
                                                        username,
                                                    }
                                                }
                                            )
                                            const player1 = players.find(
                                                (p) => p.isme
                                            )
                                            const player2 = players.find(
                                                (p) => !p.isme
                                            )

                                            const { created, type } =
                                                game.board.state
                                            const date = new Date(created)
                                            const day = date.getDate()
                                            const month = MONTH[date.getMonth()]
                                            const year = date.getFullYear()
                                            const is_competitive =
                                                type === GAME_TYPE.COMPETITIVE

                                            return (
                                                <GameRow key={key}>
                                                    <GameLeft>
                                                        {is_competitive ? (
                                                            <ResultCompetitive
                                                                trophies={
                                                                    player1.trophiesnew
                                                                }
                                                            />
                                                        ) : (
                                                            <ResultCustom
                                                                player={player1}
                                                                board={
                                                                    game.board
                                                                }
                                                            />
                                                        )}
                                                    </GameLeft>
                                                    <GameSeparator
                                                        source={
                                                            UX_ASSETS[
                                                                'bg-gamelist.png'
                                                            ]
                                                        }
                                                    />
                                                    <GameRight>
                                                        <GameRightLeft>
                                                            <GamePlayerContainer>
                                                                <GamePlayer1Name>
                                                                    {
                                                                        player1.username
                                                                    }
                                                                </GamePlayer1Name>
                                                                {is_competitive && (
                                                                    <GamePlayerTrophies>
                                                                        <GamePlayerTrophiesIcon
                                                                            source={
                                                                                UX_ASSETS[
                                                                                    'icon-trophy2.png'
                                                                                ]
                                                                            }
                                                                        />
                                                                        <GamePlayerTrophiesValue>
                                                                            {
                                                                                player1.trophies
                                                                            }
                                                                        </GamePlayerTrophiesValue>
                                                                    </GamePlayerTrophies>
                                                                )}
                                                            </GamePlayerContainer>
                                                            <Vs>Vs</Vs>
                                                            <GamePlayerContainer>
                                                                <GamePlayer2Name>
                                                                    {
                                                                        player2.username
                                                                    }
                                                                </GamePlayer2Name>
                                                                {is_competitive && (
                                                                    <GamePlayerTrophies>
                                                                        <GamePlayerTrophiesIcon
                                                                            source={
                                                                                UX_ASSETS[
                                                                                    'icon-trophy2.png'
                                                                                ]
                                                                            }
                                                                        />
                                                                        <GamePlayerTrophiesValue>
                                                                            {
                                                                                player2.trophies
                                                                            }
                                                                        </GamePlayerTrophiesValue>
                                                                    </GamePlayerTrophies>
                                                                )}
                                                            </GamePlayerContainer>
                                                        </GameRightLeft>
                                                        <GameRightRight>
                                                            <GameDateBig>
                                                                {day}
                                                            </GameDateBig>
                                                            <GameDateSmall>
                                                                {translate(
                                                                    month
                                                                ).slice(0, 3)}
                                                            </GameDateSmall>
                                                            <GameDateYear>
                                                                {year}
                                                            </GameDateYear>
                                                        </GameRightRight>
                                                    </GameRight>
                                                </GameRow>
                                            )
                                        })}
                                    </GameList>
                                )}

                                {state.games_loading && (
                                    <GameListBottom>
                                        <GameListBottomInfo>
                                            <GameListTextInfo>
                                                {translate('Loading') + '...'}
                                            </GameListTextInfo>
                                        </GameListBottomInfo>
                                    </GameListBottom>
                                )}

                                {!state.games_loading && state.games_more && (
                                    <GameListBottom>
                                        <GameListLoadMore
                                            onPress={() => {
                                                fetchGames()
                                                AUDIO.CLICK()
                                            }}
                                        >
                                            <GameListTextInfo>
                                                {translate('Load more')}
                                            </GameListTextInfo>
                                        </GameListLoadMore>
                                    </GameListBottom>
                                )}

                                {state.games.length === 0 &&
                                    !state.games_loading && (
                                        <GameListBottom>
                                            <GameListBottomInfo>
                                                <GameListTextInfo>
                                                    {translate(
                                                        "${username} hasn't played any games yet",
                                                        user
                                                    )}
                                                </GameListTextInfo>
                                            </GameListBottomInfo>
                                        </GameListBottom>
                                    )}
                            </LeftBottom>
                        </Left>

                        <Right>
                            <RightTop>
                                <RightTopColumn>
                                    <Stat
                                        label={translate('Trophies')}
                                        value={user.trophies}
                                        icon="icon-trophy.png"
                                        scale={scalecounter}
                                    />
                                    <Stat
                                        label={translate('Highest Trophies')}
                                        value={user.trophies_highest}
                                        scale={scalecounter}
                                    />
                                    <Stat
                                        label={translate('Games')}
                                        value={
                                            user.wins + user.draws + user.loses
                                        }
                                        scale={scalecounter}
                                    />
                                    {/* </RightTopColumn>
                                <RightTopColumn> */}
                                    <Stat
                                        label={translate('Wins')}
                                        value={user.wins}
                                        scale={scalecounter}
                                    />
                                    {/* <Stat
                                        label={translate('Draws')}
                                        value={user.draws}
                                        scale={scalecounter}
                                    /> */}
                                    <Stat
                                        label={translate('Loses')}
                                        value={user.loses}
                                        scale={scalecounter}
                                    />
                                </RightTopColumn>
                            </RightTop>
                        </Right>
                    </Content>
                </ScrollView>
            </Lobby>
            {state.profileicon && (
                <ProfileIcon
                    onSelect={onSelectProfileIcon}
                    onClose={() => {
                        AUDIO.CLOSE()
                        setState({ profileicon: false })
                    }}
                />
            )}
        </>
    )
}

function ResultCompetitive({ trophies }) {
    const value = trophies > 0 ? `+${trophies}` : trophies
    const color =
        trophies === 0 ? COLOR.BEIGE4 : trophies > 0 ? COLOR.GREEN : COLOR.RED
    return <Result value={value} color={color} />
}

function ResultCustom({ board, player }) {
    const { players, winner } = board.state
    const { team_id } = players[player.player_id]
    const result =
        winner.team_id === undefined
            ? GAME_RESULT.DRAW
            : winner.team_id === team_id
            ? GAME_RESULT.VICTORY
            : GAME_RESULT.DEFEAT

    const color =
        result === GAME_RESULT.DRAW
            ? COLOR.BEIGE4
            : result === GAME_RESULT.VICTORY
            ? COLOR.GREEN
            : COLOR.RED

    return <Result value={translate(result)} color={color} showicon={false} />
}

function Result({ value, color, showicon = true }) {
    return (
        <ResultContainer>
            {/* <ResultType>{label}</ResultType> */}
            <ResultTrophies>
                {showicon && <Icon source={UX_ASSETS['icon-trophy.png']} />}
                <ResultValue color={color}>{value}</ResultValue>
            </ResultTrophies>
        </ResultContainer>
    )
}

const ScrollView = styled.ScrollView``

const Content = styled.View`
    padding: ${px(75)} ${px(PADDING.SIDES)};
    flex-direction: row;
`

const Left = styled.View`
    width: 65%;
    // padding-left: ${px(50)};
`
const LeftTop = styled.View``

const LeftBottom = styled.View`
    margin-top: ${px(75)};
`

const ProfileContainer = styled.View`
    padding-left: ${px(150 / 2)};
`
const ProfileUserName = styled.ImageBackground`
    height: ${px(130)};
    border-radius: ${px(10)} ${px(10)} ${px(50)} ${px(10)};
    padding-left: ${px(200 / 2 + 40)};
    background: ${COLOR.BROWNDARK};
    border: ${px(10)} solid ${COLOR.BROWN};
    flex-direction: row;
    align-items: center;
    box-shadow: 0 0 10px white;
    overflow: hidden;
`
const ProfileName = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(50)};
    color: ${COLOR.BEIGE};
`
const ProfileTag = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(25)};
    color: ${COLOR.BEIGE};
    padding-top: ${px(10)};
    opacity: 0.6;
`

const ProfileProgress = styled.View`
    margin-left: ${px(50)};
    background: ${COLOR.BEIGE};
    border-radius: ${px(50)};
    height: ${px(50)};
    width: 85%;
`

const ProfileProgressBarContainer = styled.View`
    width: ${(p) => p.progress};
    height: 100%;
    padding: ${px(8)};
`

const ProfileProgressBar = styled.View`
    flex: 1;
    border-radius: ${px(50)};
    overflow: hidden;
`

const ProfileProgressData = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: ${px(75)};
    padding-right: ${px(20)};
`

const ProfileProgressDataLeft = styled.Text`
    margin-top: ${px(-3)};
    font-family: ChangaOne;
    font-size: ${px(24)};
    // text-shadow: 0 ${px(2)} 0 rgba(0, 0, 0, 0.2);
    color: ${COLOR.BROWN};
    // opacity: 0.3;
`
const ProfileProgressDataRight = styled.View`
    flex-direction: row;
`
const ProfileProgressDataRightText1 = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(18)};
    color: ${COLOR.BROWN};
`
const ProfileProgressDataRightText2 = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(18)};
    color: ${COLOR.BROWN};
    opacity: 0.3;
`

const ProfilePicContainer = styled.TouchableOpacity`
    width: ${px(200)};
    height: ${px(200)};
    border-radius: ${px(200)};
    left: ${px(0)};
    top: ${px(-10)};
    position: absolute;
    border-width: ${px(10)};
    border-color: ${COLOR.BEIGE};
    box-shadow: 0 ${px(8)} 5px rgba(0, 0, 0, 0.1);
    background: ${COLOR.DISABLED2};
`

const ProfilePicContainerEdit = styled.View`
    position: absolute;
    width: ${px(65)};
    height: ${px(65)};
    border-radius: ${px(65)};
    background: ${COLOR.BEIGE};
    left: ${px(-15)};
    top: ${px(-15)};
    align-items: center;
    justify-content: center;
`

const ProfilePicContainerEditIcon = styled.Image`
    width: ${px(45)};
    height: ${px(45)};
`

const ProfilePicContainer2 = styled.Image`
    width: ${px(180)};
    height: ${px(180)};
    border-radius: ${px(180)};
`

const Right = styled.View`
    flex: 1;
`

const RightTop = styled.View`
    flex-direction: row;
`
const RightTopColumn = styled.View`
    margin-left: ${px(70)};
    flex: 1;
`

const GameList = styled.View`
    margin-top: ${px(10)};
`

const GameListBottom = styled.View`
    margin-top: ${px(10)};
`

const GameListBottomInfo = styled.View`
    justify-content: center;
    align-items: center;
    border-radius: ${px(8)};
    height: ${px(80)};
    border: ${px(4)} solid transparent;
`

const GameListLoadMore = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    border-radius: ${px(8)};
    height: ${px(80)};
    border: ${px(4)} dashed ${COLOR.BEIGE};
`

const GameListTextInfo = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(22)};
    color: ${COLOR.BROWNDARK};
    opacity: 0.5;
`

// TouchableOpacity
const GameRow = styled.View`
    flex-direction: row;
    border-radius: ${px(8)};
    height: ${px(80)};
    background: ${COLOR.BEIGE};
    overflow: hidden;
    margin-bottom: ${px(3)};
`
const GameLeft = styled.View`
    width: ${px(130)};
    padding-left: ${px(10)};
    height: 100%;
    background: ${COLOR.BEIGE2};
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const GameSeparator = styled.Image`
    width: ${px(16)};
    height: ${px(77)};
`

const GameRight = styled.View`
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
`
const GameRightLeft = styled.View`
    flex-direction: row;
    align-items: center;
    padding-left: ${px(40)};
`
const GameRightRight = styled.View`
    align-items: center;
    justify-content: center;
    padding-right: ${px(30)};
`
const GameDateBig = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(24)};
    line-height: ${px(25)};
    letter-spacing: ${px(3)};
    color: ${COLOR.BEIGE3};
`
const GameDateSmall = styled.Text`
    font-family: Poppins-Bold;
    font-size: ${px(16)};
    line-height: ${px(18)};
    color: ${COLOR.BEIGE3};
`
const GameDateYear = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${px(16)};
    color: ${COLOR.BEIGE3};
    position: absolute;
    transform: rotate(90deg) translateX(${px(28)});
`

const GamePlayerContainer = styled.View``
const GamePlayerTrophies = styled.View`
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    // background: red;
`

const GamePlayerTrophiesIcon = styled.Image`
    width: ${px(16)};
    height: ${px(16)};
`
const GamePlayerTrophiesValue = styled.Text`
    font-family: Poppins-SemiBold;
    color: ${COLOR.BEIGE3};
    font-size: ${px(20)};
    // line-height: ${px(22)};
    padding-left: ${px(5)};
`

const GamePlayer1Name = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(28)};
    line-height: ${px(30)};
    color: ${COLOR.BROWN};
`

const GamePlayer2Name = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${px(28)};
    line-height: ${px(30)};
    color: ${COLOR.BROWN};
`
const Vs = styled.Text`
    font-family: ChangaOne;
    color: ${COLOR.BEIGE3};
    font-size: ${px(40)};
    padding: 0 ${px(40)};
`

const Icon = styled.Image`
    width: ${px(28)};
    height: ${px(28)};
    position: absolute;
    left: ${px(-10)};
`

const ResultContainer = styled.View`
    justify-content: center;
    align-items: center;
`

const ResultTrophies = styled.View`
    flex-direction: row;
    background: ${COLOR.BEIGE};
    justify-content: flex-end;
    align-items: center;
    min-width: ${px(90)};
    border-radius: ${px(5)};
`

const ResultType = styled.Text`
    font-family: Poppins-Bold;
    color: ${COLOR.BEIGE4};
    font-size: ${px(16)};
    line-height: ${px(18)};
`

const ResultValue = styled.Text`
    font-family: Poppins-Bold;
    text-align: right;
    font-size: ${px(22)};
    padding: 0 ${px(10)};
    color: ${(p) => p.color};
`
