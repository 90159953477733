import React, { useState, useEffect } from 'react'
import styled from '@emotion/native'
import { COLOR, LOCALSTORAGE } from '~/const'
import Loading from '~/components/stateless/Loading'
import { Server } from '~/server'
import { setGlobalState } from '~/store'
import { useGlobalState } from '~/store/hooks'
import {
    COINS,
    CHAINS,
    connectWallet,
    getInjectedWallets,
    getChainId,
    selectChain,
    getShorterAddress,
    getBalance,
    sendCoins,
    signMessage,
} from '~/npm/web3'
import {
    px,
    isWeb,
    localStorageGet,
    localStorageSet,
    localStorageRemove,
} from '~/utils/device'
import { translate } from '~/locale'
import { AUDIO } from '~/audio'
import Dialog from '~/components/stateless/Dialog'
import Button from '~/components/stateless/Button'
import DropDown from '~/components/stateless/DropDown'

const WIDTH = 700
const HEIGHT = 600

export default React.memo(function Web3() {
    const [{ web3 }, setGlobalState] = useGlobalState(({ path }) => {
        return path[0] === 'web3'
    })
    const show_payment =
        web3.payment !== null &&
        web3.wallet !== null &&
        web3.coin !== null &&
        web3.address !== null

    useEffect(() => {
        if (web3.open && !web3.loaded) {
            onLoadComponent()
        }
    }, [web3.open])

    async function onLoadComponent() {
        if (isWeb()) {
            const web3storage = await getLocalStorage()
            let wallets = web3.wallets.slice(0)
            const removeEvents = getInjectedWallets((wallet) => {
                if (
                    web3.wallets
                        .map((w) => w.info.uuid)
                        .includes(wallet.info.uuid) === false
                ) {
                    wallets = [...wallets, wallet]
                    setGlobalState({ web3: { wallets } })
                    onAutoConnect(wallet, web3storage)
                }
            })
            removeEvents()
            setGlobalState({ web3: { loaded: true } })
        }
    }

    async function onAutoConnect(wallet, web3storage) {
        if (wallet.info.rdns === web3storage.rdns) {
            await onConnect(wallet)
            const coin = COINS.find((c) => c.id === web3storage.coin_id)
            if (coin !== undefined) {
                onSelectCoin(coin)
            }
        }
    }

    async function getLocalStorage() {
        return (await localStorageGet(LOCALSTORAGE.WEB3)) || {}
    }

    async function updateLocalStorage(patch) {
        const current_state = await getLocalStorage()
        const new_state = { ...current_state, ...patch }
        await localStorageSet(LOCALSTORAGE.WEB3, new_state)
        return new_state
    }

    async function onConnect(wallet) {
        try {
            setGlobalState({ web3: { error: null } })
            const addresses = await connectWallet({ wallet })
            const [address] = addresses
            // const chain_id = await getChainId({ wallet })
            setGlobalState({ web3: { wallet, addresses } })
            onSelectAddress(address)
            updateLocalStorage({ rdns: wallet.info.rdns })
        } catch (e) {
            setGlobalState({
                web3: {
                    error: {
                        e,
                        message:
                            e.message ||
                            'An error occurred connecting the wallet',
                    },
                },
            })
        }
    }

    async function onSelectCoin(coin) {
        try {
            const chain_data = CHAINS[coin.chain_id]
            await selectChain({ wallet: web3.wallet, chain: chain_data })
            const chain_id = await getChainId({ wallet: web3.wallet })

            if (chain_id === coin.chain_id) {
                setGlobalState({ web3: { coin } })
                updateLocalStorage({ coin_id: coin.id })
                return coin.chain_id
            }
            // CoinbaseWallet does not throw error, so we do it manually
            throw { code: 4001, message: 'User rejected the request.' }
        } catch (e) {
            setGlobalState({
                web3: {
                    error: {
                        e,
                        message:
                            e.message || 'An error occurred selecting chain',
                    },
                },
            })
        }
    }

    async function onSelectAddress(address) {
        setGlobalState({ web3: { address } })

        const { message, verified } = await Server.userAddressVerified({
            address,
        })
        if (verified !== true) {
            try {
                const message_signed = await signMessage({
                    wallet: web3.wallet,
                    message,
                    address,
                })
                await Server.userAddressVerify({
                    address: address,
                    message_signed,
                })
            } catch (e) {
                setGlobalState({
                    web3: {
                        error: {
                            e,
                            message:
                                e.message ||
                                'An error occurred signing a message',
                        },
                    },
                })
            }
        }
    }

    async function onDisconnect() {
        AUDIO.CLOSE()
        localStorageRemove(LOCALSTORAGE.WEB3)
        setGlobalState({
            web3: {
                error: null,
                wallet: null,
                addresses: [],
                address: null,
                chain_id: null,
                coin: null,
            },
        })
    }

    function onClose() {
        AUDIO.CLOSE()
        setGlobalState({
            web3: {
                open: false,
                error: null,
                payment: null,
            },
        })
    }

    return !web3.open ? null : (
        <Dialog
            width={WIDTH}
            height={HEIGHT}
            error={web3.error !== null ? translate(web3.error.message) : null}
            onClose={onClose}
            onBackground={onClose}
        >
            <Container>
                <Content>
                    <ScrollView>
                        <ScrollViewContent>
                            {web3.wallet === null && (
                                <WalletsList
                                    wallets={web3.wallets}
                                    onConnect={onConnect}
                                />
                            )}

                            {web3.wallet !== null && !show_payment && (
                                <Wallet
                                    wallet={web3.wallet}
                                    coin={web3.coin}
                                    addresses={web3.addresses}
                                    address={web3.address}
                                    setGlobalState={setGlobalState}
                                    onDisconnect={onDisconnect}
                                    onSelectCoin={onSelectCoin}
                                    onSelectAddress={onSelectAddress}
                                />
                            )}

                            {show_payment && (
                                <Payment
                                    web3={web3}
                                    onSelectCoin={onSelectCoin}
                                    onClose={onClose}
                                />
                            )}
                        </ScrollViewContent>
                    </ScrollView>
                </Content>
            </Container>
        </Dialog>
    )
})

function WalletsList({ wallets, onConnect }) {
    return (
        <>
            <Block padding={0}>
                <Title>
                    {translate(
                        wallets.length > 0
                            ? 'Connect with:'
                            : 'No wallets detected to connect'
                    )}
                </Title>
            </Block>

            {wallets.map((wallet, index) => {
                return (
                    <Block key={index}>
                        <Button
                            icon={wallet.info.icon}
                            iconUri={true}
                            width={'90%'}
                            fontSize={35}
                            label={wallet.info.name}
                            onClick={() => {
                                AUDIO.CLICK()
                                onConnect(wallet)
                            }}
                        />
                    </Block>
                )
            })}
        </>
    )
}

function Wallet({
    wallet,
    coin,
    addresses,
    address,
    onDisconnect,
    onSelectCoin,
    onSelectAddress,
}) {
    const [coins_open, setCoinsOpen] = useState(false)
    const [addresses_open, setAddressesOpen] = useState(false)
    const coins = COINS.slice(0)
    const coin_selected = coins.findIndex((c) => c.name === coin?.name)
    const address_selected = addresses.indexOf(address)

    return (
        <>
            <Block zIndex={coins_open ? '1' : '0'}>
                <DropDown
                    placeholder={translate('Select Coin')}
                    open={coins_open}
                    selected={coin_selected}
                    options={coins.map((c) => c.name)}
                    width={'90%'}
                    fontSize={35}
                    onOpen={(open) => {
                        AUDIO.CLICK()
                        setGlobalState({ web3: { error: null } })
                        setCoinsOpen(open)
                    }}
                    onSelect={(selected) => {
                        onSelectCoin(coins[selected])
                    }}
                />
            </Block>

            {coin !== null && (
                <Block zIndex={addresses_open ? '1' : '0'}>
                    <DropDown
                        placeholder={translate('Select Address')}
                        disabled={addresses.length < 2}
                        open={addresses_open}
                        selected={address_selected}
                        options={addresses.map((addrs) =>
                            getShorterAddress(addrs)
                        )}
                        width={'90%'}
                        fontSize={35}
                        onOpen={(open) => {
                            setGlobalState({ web3: { error: null } })
                            setAddressesOpen(open)
                        }}
                        onSelect={(selected) => {
                            AUDIO.CLICK()
                            onSelectAddress(addresses[selected])
                        }}
                    />
                </Block>
            )}

            <Block>
                <Button
                    color={COLOR.RED}
                    icon={wallet.info.icon}
                    iconUri={true}
                    width={'90%'}
                    fontSize={35}
                    label={translate('Disconnect')}
                    onClick={onDisconnect}
                />
            </Block>
        </>
    )
}

function Payment({ web3, onSelectCoin, onClose }) {
    useEffect(() => {
        ;(async () => {
            try {
                const coin = COINS.find((c) => c.id === web3.payment.coin_id)
                const chain_id = await onSelectCoin(coin)
                if (chain_id === coin.chain_id) {
                    const balance = await getBalance({
                        wallet: web3.wallet,
                        address: web3.address,
                        decimals: coin.decimals,
                    })

                    if (Number(balance) < Number(web3.payment.amount)) {
                        throw { message: 'Insufficient balance' }
                    }

                    const receipt = await sendCoins({
                        wallet: web3.wallet,
                        address: web3.address,
                        address_to: web3.payment.to,
                        amount: web3.payment.amount,
                        decimals: coin.decimals,
                    })
                    setGlobalState({
                        web3: {
                            open: Boolean(web3.payment.keep_open),
                            payment: { receipt },
                        },
                    })
                }
            } catch (e) {
                setGlobalState({
                    web3: {
                        error: {
                            e,
                            message:
                                e.shortMessage ||
                                e.message ||
                                'An error occurred processing the payment',
                        },
                    },
                })
            }
        })()
    }, [])

    return web3.error === null ? (
        <>
            <Block>
                <Title>
                    {translate('Processing payment with ${wallet}', {
                        wallet: web3.wallet.info.name,
                    })}
                </Title>
            </Block>
            <Block />
            <Block>
                <Loading size={100} />
            </Block>
        </>
    ) : (
        <Block>
            <Button
                width={'90%'}
                fontSize={35}
                label={translate('Close')}
                onClick={() => {
                    AUDIO.CLICK()
                    onClose()
                }}
            />
        </Block>
    )
}

const Container = styled.View`
    height: 100%;
    width: 100%;
`
const Content = styled.View`
    width: 100%;
    padding: ${px(30)};
`
// const ContentTop = styled.View`
//     width: 100%;
//     height: ${px(60)};
//     justify-content: center;
//     align-items: center;
//     background: red;
// `
// const ContentBottom = styled.View`
//     flex: 1;
//     width: 100%;
//     height: 100%;
// `

const ScrollView = styled.ScrollView`
    width: 100%;
    height: ${px(HEIGHT - 50)};
`
const ScrollViewContent = styled.View`
    width: 100%;
    min-height: ${px(HEIGHT - 50)};
    justify-content: center;
`

const Block = styled.View`
    padding: ${(p) => px(p.padding || 15)} 0;
    justify-content: center;
    align-items: center;
    z-index: ${(p) => px(p.zIndex || '0')};
`

const Title = styled.Text`
    text-align: ${(p) => p.align || 'left'};
    font-family: Poppins-Black;
    font-size: ${(p) => px(p.size || 25)};
    color: ${(p) => p.color || COLOR.BEIGE4};
`
