import React from 'react'
import styled from '@emotion/native'
import { COLOR } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { useGlobalState, useLocalState } from '~/store/hooks'
import { px, isDesktop } from '~/utils/device'
import { translate, getError } from '~/locale'
import { Server } from '~/server'
import { AUDIO } from '~/audio'
import Dialog from '~/components/stateless/Dialog'
import Input from '~/components/stateless/Input'
import Button from '~/components/stateless/Button'
import { FormField, FormFieldSeparator } from '~/components/stateless/Form'
import FormValue from '~/components/stateless/FormValue'
import CopyValue from '~/components/stateless/CopyValue'

export default function Invite({ onClose }) {
    const [{ user }, setGlobalState] = useGlobalState()
    const myusernamefull = `${user.username}#${user.tagid}`
    const [state, setState] = useLocalState({
        usernamefull: '',
        error: null,
        invited: false,
    })

    function onChangeUsername(usernamefull) {
        setState({ error: null, usernamefull })
    }

    async function onSendAnotherInvitation() {
        AUDIO.CLICK()
        setState({ error: null, usernamefull: '', invited: false })
    }

    async function onSendInvitation() {
        AUDIO.CLICK()
        try {
            const invited = await Server.userInviteGame({
                usernamefull: state.usernamefull,
            })

            if (invited) {
                setState({ error: null, invited })
            } else {
                setState({
                    error: `${state.usernamefull} ${translate(
                        'is not available to be invited'
                    )}`,
                })
            }
        } catch (e) {
            setState({ error: translate(getError(e)) })
        }
    }

    return (
        <Dialog
            width={700}
            height={600}
            title={translate('Custom')}
            titleScale={0.8}
            error={state.error}
            contentColor={COLOR.BEIGE2}
            contentColor2={COLOR.BEIGE4}
            onClose={onClose}
            onBackground={onClose}
        >
            <Container>
                {!state.invited && (
                    <Content>
                        <FormField>
                            <Input
                                placeholder={translate('Name#000')}
                                returnKeyType="done"
                                value={state.usernamefull}
                                editable={true}
                                onChangeText={onChangeUsername}
                                onFocus={() => {
                                    if (!isDesktop()) {
                                        setGlobalState({
                                            input: {
                                                text: state.usernamefull,
                                                onChange: onChangeUsername,
                                                onCancel: onChangeUsername,
                                            },
                                        })
                                    }
                                }}
                            />
                        </FormField>
                        <FormFieldSeparator />
                        <FormField>
                            <Button
                                width={'100%'}
                                fontSize={35}
                                label={translate('Send Invitation')}
                                color={COLOR.BLUEDARK}
                                onClick={onSendInvitation}
                            />
                        </FormField>
                        <FormFieldSeparator />
                        <FormFieldSeparator />
                        <ShareText>
                            {translate(
                                'Or share your username with your friends'
                            )}
                        </ShareText>
                        <FormField>
                            <CopyValue value={myusernamefull}>
                                <FormValue
                                    value={myusernamefull}
                                    infovalue={translate('Tap to copy')}
                                    icon={UX_ASSETS['icon-copy.png']}
                                    iconsize={35}
                                    backgroundColor={COLOR.BEIGE + '66'}
                                />
                            </CopyValue>
                        </FormField>
                    </Content>
                )}

                {state.invited && (
                    <Content>
                        <CompletedImage
                            source={UX_ASSETS['icon-check-green.png']}
                        />
                        <FormFieldSeparator />
                        <CompletedText>
                            {translate('Invitation Sent')}!
                        </CompletedText>
                        <FormFieldSeparator />
                        <FormField>
                            <Button
                                width={'100%'}
                                fontSize={35}
                                label={translate('Send another Invitation')}
                                color={COLOR.BLUEDARK}
                                onClick={onSendAnotherInvitation}
                            />
                        </FormField>
                    </Content>
                )}
            </Container>
        </Dialog>
    )
}

const Container = styled.View`
    flex: 1;
    padding-top: ${px(50)};
`
const Content = styled.View`
    flex: 1;
    padding: 0 ${px(60)};
    align-items: center;
    justify-content: center;
`
const ShareText = styled.Text`
    color: ${COLOR.BROWN};
    font-family: Poppins-Medium;
    font-size: ${px(16)};
    margin-bottom: ${px(10)};
`
const CompletedImage = styled.Image`
    width: ${px(150)};
    height: ${px(150)};
`
const CompletedText = styled.Text`
    font-family: Poppins-Black;
    color: ${COLOR.BROWNDARK};
    font-size: ${px(32)};
`
