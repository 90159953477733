import React, { useEffect, useState } from 'react'
import styled from '@emotion/native'

export default function Dots() {
    const [dots, setDots] = useState('...')

    useEffect(() => {
        const interval = setTimeout(() => {
            setDots(dots.length === 3 ? '' : dots + '.')
        }, 500)

        return () => {
            clearInterval(interval)
        }
    }, [dots])

    return <DotsContainer>{dots}</DotsContainer>
}

const DotsContainer = styled.Text``
