import React, { useEffect } from 'react'
import styled from '@emotion/native'
import { COLOR } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { useGlobalState, useLocalState } from '~/store/hooks'
import { px } from '~/utils/device'
import { translate, getError } from '~/locale'
import { Server } from '~/server'
import { AUDIO } from '~/audio'
import Dialog from '~/components/stateless/Dialog'
import Button from '~/components/stateless/Button'
import { FormField, FormFieldSeparator } from '~/components/stateless/Form'

export default React.memo(function Invited() {
    const [{ user }, setGlobalState] = useGlobalState('user.invited')
    const [state, setState] = useLocalState({
        canceled: false,
    })

    useEffect(() => {
        if (user.invited !== null) {
            AUDIO.MATCH_FOUND()
        }
    }, [user.invited])

    async function onAcceptCancel(accept) {
        AUDIO.CLICK()
        const accepted = await Server.userInvitedGame({ accept })
        if (accept && !accepted) {
            setState({ canceled: true })
        } else {
            setGlobalState({ user: { invited: null } })
        }
    }

    async function onClose() {
        AUDIO.CLICK()
        setState({ canceled: false })
        setGlobalState({ user: { invited: null } })
    }

    return user.invited === null ? null : (
        <Dialog
            width={700}
            height={400}
            borderColor={'transparent'}
            backgroundColor={COLOR.BLUEBLACK + '99'}
            background={UX_ASSETS['bg-pattern.png']}
            contentColor={COLOR.BEIGE2}
            contentColor2={COLOR.BEIGE4}
        >
            <Container>
                {!state.canceled && (
                    <Content>
                        <TextContainer>
                            <Text>
                                {translate(
                                    '${username} invites you to play a game',
                                    user.invited
                                )}
                                !
                            </Text>
                        </TextContainer>
                        <FormFieldSeparator />
                        <FormField>
                            <Players>
                                <Player
                                    key={'a'}
                                    source={UX_ASSETS['icon-user.png']}
                                />
                                <Player
                                    key={'b'}
                                    source={UX_ASSETS['icon-user2.png']}
                                />
                            </Players>
                        </FormField>
                        <FormFieldSeparator />
                        <FormField>
                            <Buttons>
                                <ButtonContainer>
                                    <Button
                                        width={'100%'}
                                        height={80}
                                        fontSize={35}
                                        color={COLOR.GREENDARK}
                                        label={translate('Accept')}
                                        onClick={() => onAcceptCancel(true)}
                                    />
                                </ButtonContainer>
                                <ButtonContainer>
                                    <Button
                                        width={'100%'}
                                        height={80}
                                        fontSize={35}
                                        color={COLOR.RED2}
                                        label={translate('Cancel')}
                                        onClick={() => onAcceptCancel(false)}
                                    />
                                </ButtonContainer>
                            </Buttons>
                        </FormField>
                    </Content>
                )}

                {state.canceled && (
                    <Content>
                        {/* <TextContainer> */}
                        <Text>
                            {translate(
                                '${username} has canceled the game or is not ready',
                                user.invited
                            )}
                            .
                        </Text>
                        {/* </TextContainer> */}
                        <FormFieldSeparator />
                        <FormFieldSeparator />
                        <FormField>
                            <Button
                                width={'100%'}
                                height={80}
                                fontSize={35}
                                label={translate('Continue')}
                                onClick={() => onClose(false)}
                            />
                        </FormField>
                    </Content>
                )}
            </Container>
        </Dialog>
    )
})

const Container = styled.View`
    flex: 1;
    padding-top: ${px(50)};
`
const Content = styled.View`
    flex: 1;
    padding: 0 ${px(30)};
    align-items: center;
    justify-content: center;
`
const TextContainer = styled.View`
    border-radius: ${px(30)};
    width: 100%;
    height: ${px(60)};
    background: ${COLOR.BROWN + '28'};
    justify-content: center;
    align-items: center;
`
const Text = styled.Text`
    font-family: Poppins-Black;
    color: ${COLOR.BROWN};
    font-size: ${px(25)};
    text-align: center;
`
const Players = styled.View`
    width: 100%;
    flex-direction: row;
    justify-content: center;
`
const Player = styled.Image`
    width: ${px(100)};
    height: ${px(100)};
`
const Buttons = styled.View`
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
`
const ButtonContainer = styled.View`
    width: 49%;
`

// <Dialog
// width={850}
// height={500}
// titleScale={0.8}
// contentColor={COLOR.BEIGE2}
// contentColor2={COLOR.BEIGE4}
// >
// <Container>
//     <Content>
//         <Text>
//             {translate(
//                 '${username} invites you to play a game',
//                 user.invited
//             )}
//             !
//         </Text>
//         <FormFieldSeparator />
//         <FormFieldSeparator />
//         <FormField>
//             <Button
//                 width={'100%'}
//                 fontSize={35}
//                 label={translate('Accept')}
//                 color={COLOR.GREENDARK}
//                 onClick={() => onAcceptCancel(true)}
//             />
//         </FormField>
//         <FormFieldSeparator />
//         <FormField>
//             <Button
//                 width={'100%'}
//                 fontSize={35}
//                 label={translate('Cancel')}
//                 color={COLOR.RED2}
//                 onClick={() => onAcceptCancel(false)}
//             />
//         </FormField>
//     </Content>
// </Container>
// </Dialog>
