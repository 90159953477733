import unit from './unit'

export default (color) => {
    return {
        ...unit,
        color,
        body_asset: {
            url: `units/slinger-${color}.png`,
            offset_x: 0,
            offset_y: 0,
            scale: 3.2,
        },
        loops: [
            { name: 'iddle', frames: 86 },
            { name: 'attack', frames: 31 },
            { name: 'walk', frames: 16 },
        ],
        shadow_asset: { url: 'unit-shadow.png', scale: 0.5 },
    }
}
