import { createNode, TYPE } from 'dop'
import { randomInt } from 'conkis-core/src/utils/index'
import package_core from 'conkis-core'
import package_app from '../../package.json'
import { getGlobalState, setGlobalState, getDefaultState } from '~/store'
import { CONNECTION, VIEW } from '~/const'
import SETTINGS from '~/settings'
import { ERROR_DESCRIPTION } from 'const/errors'
import { createRemoteNow } from '~/utils'
import { isWeb, getPlatform, getUserAgent } from '~/utils/device'

const state = {
    // ws
    // node
    // endpoints
    // servernow
}

// PUBLIC
export function connect() {
    onConnect(SETTINGS.WS_URL)
}

export const Server = new Proxy(
    {},
    {
        get: (obj, name) => {
            return (params = {}) => {
                const { connection } = getGlobalState()
                if (CONNECTION.OPEN !== connection) {
                    throw {
                        error: ERROR_DESCRIPTION.NOT_CONNECTION,
                        name,
                        params,
                    }
                }
                if (!state.endpoints.hasOwnProperty(name)) {
                    throw {
                        error: ERROR_DESCRIPTION.ENDPOINT_NOT_FOUND,
                        name,
                        params,
                    }
                }
                return state.endpoints[name](params)
            }
        },
    }
)

export function getConnection() {
    return state
}

export function serverNow() {
    return state.servernow()
}

// PRIVATE
function onConnect(url) {
    clearTimeout(state.timeout)
    const gstate = getGlobalState()
    if (gstate.connection === CONNECTION.CLOSE) {
        const ws = new WebSocket(url)
        const node = createNode()
        state.ws = ws
        state.node = node

        setGlobalState({ connection: CONNECTION.CONNECTING })
        ws.onopen = async () => {
            const getServerEndpoints = node.open(
                ws.send.bind(ws),
                clientEndpoints
            )
            state.endpoints = await getServerEndpoints()
            const server_info = await state.endpoints.getServerInfo({})
            if (
                server_info.core_version !== package_core.version ||
                server_info.app_version !== package_app.version
            ) {
                console.log({
                    server_core: server_info.core_version,
                    client_core: package_core.version,
                })
                console.log({
                    server_app: server_info.app_version,
                    client_app: package_app.version,
                })
                setGlobalState({
                    outdated: TYPE.Replace({
                        error: ERROR_DESCRIPTION.INVALID_APP_VERSION,
                        server_core: server_info.core_version,
                        client_core: package_core.version,
                        server_app: server_info.app_version,
                        client_app: package_app.version,
                    }),
                })
                return
            }
            state.servernow = createRemoteNow(server_info.now)
            setGlobalState({
                server_info: TYPE.Replace(server_info),
                connection: CONNECTION.OPEN,
                connection_trys: 0,
            })
        }
        ws.onmessage = (e) => {
            if (state.ws === ws) {
                node.message(e.data)
            }
            // else {
            //     ws.close()
            // }
        }
        ws.onclose = () => {
            if (ws === state.ws) {
                onClose()
            }
        }
    }
}

function onClose() {
    const gstate = getGlobalState()
    const connection_trys = gstate.connection_trys + 1
    setGlobalState(getDefaultState({ connection_trys }))
    state.timeout = setTimeout(connect, connection_trys * 1000)
}

function clientEndpoints() {
    return {
        pingPong: (value) => {
            state.pingpong = { value, now: Date.now() }
            return value
        },
    }
}

setInterval(() => {
    if (Date.now() - state.pingpong?.now > 10000) {
        delete state.pingpong
        state.ws.close()
    }
}, 1000)

function handleErrors({ getGlobalState }) {
    if (isWeb()) {
        window.addEventListener('error', (e) => {
            const gstate = getGlobalState()
            if (gstate.connection === CONNECTION.OPEN) {
                const error = JSON.stringify({
                    type: 'error',
                    view: gstate.view,
                    message: e.message,
                    stack: e.error.stack,
                    filename: e.filename,
                    lineno: e.lineno,
                    colno: e.colno,
                })
                // console.log(error)
                state.endpoints.userSendError({
                    error,
                    platform: getPlatform(),
                    useragent: getUserAgent(),
                })
            }
        })
        // window.addEventListener('unhandledrejection', (e) => {
        //     const { connection } = getGlobalState()
        //     if (connection === CONNECTION.OPEN) {
        //         const { state.endpoints } = getConnection()
        //         const error = JSON.stringify({
        //             listener: 'unhandledrejection',
        //             message: e.message || e.reason.message,
        //             error: e.error || e.reason.error,
        //             stack: e.reason.stack,
        //         })
        //         state.endpoints.userSendError({ error })
        //     }
        // })
    }
}

handleErrors({ getGlobalState })

// setTimeout(() => {
//     getConnection.f()
// }, 2000)

// window.getConnection = getConnection
// window.onClose = onClose // <-- with onClose we can force multiple clients per user in server
