import React, { useEffect } from 'react'
import Animated, {
    Easing,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withSequence,
    withTiming,
    runOnJS,
    runOnUI,
} from 'react-native-reanimated'

// https://docs.swmansion.com/react-native-reanimated/docs/fundamentals/getting-started/

export function AnimationPulsate({
    children,
    style = {},
    active = true,
    scale_max = 1.2,
    delay = 0,
}) {
    const easing = Easing.out(Easing.ease)
    const scale = useSharedValue(1)
    const repeat = Infinity

    useEffect(() => {
        scale.value = withDelay(
            delay,
            withRepeat(
                withSequence(
                    withTiming(1, { duration: 300, easing }),
                    withTiming(scale_max, { duration: 200, easing }),
                    withTiming(1, { duration: 300, easing })
                ),
                repeat
            )
        )
    }, [])

    const animatedStyle = useAnimatedStyle(() => ({
        transform: [{ scale: scale.value }],
    }))

    return active ? (
        <Animated.View style={[style, animatedStyle]}>{children}</Animated.View>
    ) : (
        children
    )
}

export function AnimationBlink({
    children,
    style = {},
    active = true,
    delay = 0,
}) {
    const easing = Easing.out(Easing.ease)
    const opacity = useSharedValue(1)
    const repeat = Infinity

    useEffect(() => {
        //https://docs.swmansion.com/react-native-reanimated/docs/animations/withRepeat
        opacity.value = withDelay(
            delay,
            withRepeat(
                withSequence(
                    withTiming(1, { duration: 400, easing }),
                    withTiming(0.2, { duration: 200, easing }),
                    withTiming(1, { duration: 400, easing })
                ),
                repeat
            )
        )
    }, [])

    const animatedStyle = useAnimatedStyle(() => ({
        opacity: opacity.value,
    }))

    return active ? (
        <Animated.View style={[style, animatedStyle]}>{children}</Animated.View>
    ) : (
        children
    )
}

export function AnimationSpin({
    children,
    duration = 2000,
    style = {},
    active = true,
}) {
    const easing = Easing.linear
    const rotate = useSharedValue(0)
    const repeat = Infinity

    useEffect(() => {
        //https://docs.swmansion.com/react-native-reanimated/docs/animations/withRepeat
        rotate.value = withRepeat(
            withSequence(
                withTiming(0, { duration: 0, easing }),
                withTiming(360, { duration, easing })
            ),
            repeat
        )
    }, [])

    const animatedStyle = useAnimatedStyle(() => ({
        transform: [{ rotate: `${rotate.value}deg` }],
    }))

    return active ? (
        <Animated.View style={[style, animatedStyle]}>{children}</Animated.View>
    ) : (
        children
    )
}

export function AnimationSlideInOut({
    children,
    style = {},
    keys = [
        { value: 0, duration: 0 },
        { value: 1000, duration: 1000 },
    ],
    active = true,
    delay = 0,
    onFinish = () => {},
}) {
    const easing = Easing.inOut(Easing.quad)
    const translateXsv = useSharedValue(keys[0].value)
    const repeat = 1

    const sequence = keys
        .slice(1)
        .map((key) => withTiming(key.value, { duration: key.duration, easing }))

    useEffect(() => {
        translateXsv.value = withDelay(
            delay,
            withRepeat(
                withSequence.apply(this, sequence),
                repeat,
                false,
                () => {
                    runOnJS(onFinish)(true)
                }
            )
        )
    }, [])

    const animatedStyle = useAnimatedStyle(() => ({
        transform: [{ translateX: translateXsv.value }],
    }))

    return active ? (
        <Animated.View style={[style, animatedStyle]}>{children}</Animated.View>
    ) : (
        children
    )
}

export function AnimationFadeOut({
    children,
    style = {},
    active = true,
    delay = 0,
    keys = [
        { value: 1, duration: 0 },
        { value: 0, duration: 1000 },
    ],
    onFinish = () => {},
}) {
    const easing = Easing.out(Easing.ease)
    const opacity = useSharedValue(keys[0].value)
    const repeat = 1
    const sequence = keys
        .slice(1)
        .map((key) => withTiming(key.value, { duration: key.duration, easing }))

    useEffect(() => {
        opacity.value = withDelay(
            delay,
            withRepeat(
                withSequence.apply(this, sequence),
                repeat,
                false,
                () => {
                    runOnJS(onFinish)(true)
                }
            )
        )
    }, [])

    const animatedStyle = useAnimatedStyle(() => ({
        opacity: opacity.value,
    }))

    return active ? (
        <Animated.View style={[style, animatedStyle]}>{children}</Animated.View>
    ) : (
        children
    )
}

export function AnimationVibrate({
    children,
    style = {},
    active = true,
    offset = 2,
    delay = 0,
}) {
    const easing = Easing.out(Easing.ease)
    const translateX = useSharedValue(0)
    const translateY = useSharedValue(0)
    const repeat = Infinity

    useEffect(() => {
        translateX.value = withDelay(
            delay,
            withRepeat(
                withSequence(
                    withTiming(-offset, { duration: 50, easing }),
                    withTiming(offset, { duration: 50, easing }),
                    withTiming(-offset, { duration: 50, easing }),
                    withTiming(offset, { duration: 50, easing }),
                    withTiming(-offset, { duration: 50, easing }),
                    withTiming(offset, { duration: 50, easing }),
                    withTiming(-offset, { duration: 50, easing }),
                    withTiming(-offset, { duration: 50, easing }),
                    withTiming(offset, { duration: 50, easing })
                ),
                repeat
            )
        )

        translateY.value = withDelay(
            delay,
            withRepeat(
                withSequence(
                    withTiming(-offset, { duration: 50, easing }),
                    withTiming(-offset, { duration: 50, easing }),
                    withTiming(offset, { duration: 50, easing }),
                    withTiming(offset, { duration: 50, easing }),
                    withTiming(-offset, { duration: 50, easing }),
                    withTiming(-offset, { duration: 50, easing }),
                    withTiming(offset, { duration: 50, easing }),
                    withTiming(-offset, { duration: 50, easing }),
                    withTiming(-offset, { duration: 50, easing })
                ),
                repeat
            )
        )
    }, [])

    const animatedStyle = useAnimatedStyle(() => ({
        transform: [
            { translateX: translateX.value },
            { translateY: translateY.value },
        ],
    }))

    return active ? (
        <Animated.View style={[style, animatedStyle]}>{children}</Animated.View>
    ) : (
        children
    )
}
