import React, { useEffect, useState } from 'react'
import { AppState } from 'react-native'
import styled from '@emotion/native'
import {
    GAME_TYPE,
    GAME_SIZE,
    REWARD_ASSET,
    USER_STATUS,
    PARTY_STATUS,
    TEAM,
} from 'conkis-core'
import { getRewardSearchQueue } from 'conkis-core/src/rewards'
import { localStorageSet } from '~/utils/device'
import { secondsToTime } from '~/utils'
import { px, isDesktop } from '~/utils/device'
import { CONNECTION, COLOR, VIEW, LOCALSTORAGE } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { ERROR } from '~/const/errors'
import { translate } from '~/locale'
import { AUDIO } from '~/audio'
import { changeView } from '~/store'
import { useTimer } from '~/store/hooks'
import { useGlobalState } from '~/store/hooks'
import { Server, serverNow } from '~/server'
import Gif from '~/components/stateless/Gif'
import Counter from '~/components/stateless/Counter'
import Dialog from '~/components/stateless/Dialog'
import Button from '~/components/stateless/Button'
import Dots from '~/components/stateless/Dots'

export default function Searching({}) {
    const [{ connection, user }, setGlobalState] = useGlobalState(
        ({ path }) => {
            return path[0] === 'connection' || path[0] === 'user'
        }
    )
    const [statustype_before, setStatusType] = useState(user.status.type)
    const [, forceUpdate] = useState({})

    useEffect(() => {
        const listener = AppState.addEventListener('change', (app_state) => {
            const party_search_start =
                user.status.party_search_start !== undefined
            if (party_search_start) {
                if (app_state === 'background' && !isDesktop()) {
                    onCancel()
                }
                forceUpdate({})
            }
        })
        return () => {
            listener.remove()
        }
    }, [])

    useEffect(() => {
        if (connection !== CONNECTION.OPEN) {
            changeView(VIEW.PLAY)
        }
    }, [connection])

    useEffect(() => {
        if (user.status.type === USER_STATUS.NONE) {
            if (statustype_before === USER_STATUS.NONE) {
                onSearch()
            } else if (statustype_before === USER_STATUS.PARTY) {
                // Settimeout needed because does not work otherwise
                setTimeout(() => {
                    changeView(VIEW.PLAY)
                }, 100)
            }
        } else if (user.status.type === USER_STATUS.PLAYING) {
            // This logic is now on components/Views.js
        }
        setStatusType(user.status.type)
    }, [user.status.type])

    async function onSearch() {
        try {
            await Server.partySearch({
                type: GAME_TYPE.COMPETITIVE,
                size: GAME_SIZE.VS1,
            })
        } catch (e) {
            const patch = { view: VIEW.PLAY }

            if (
                e.error === ERROR.SERVER_MAINTENANCE ||
                e.error === ERROR.CANCELATION_PENALTY
            ) {
                patch.playerror = e
            }

            setGlobalState(patch)
        }
    }

    function onAccept() {
        AUDIO.UNIT_SELECT()
        Server.partyAccept()
    }

    function onCancel() {
        AUDIO.CLOSE()
        Server.partyLeave()
        changeView(VIEW.PLAY)
    }

    return (
        <>
            <Container>
                <Background
                    imageStyle={{ resizeMode: 'stretch' }}
                    source={UX_ASSETS['bg-searching.png']}
                />
                <Content>
                    <Top>
                        <TitleContainer border={false} background={true}>
                            <Title>
                                {translate('Searching for players')}
                                <Dots />
                            </Title>
                        </TitleContainer>
                    </Top>
                    <Middle>
                        <Gif
                            width={500}
                            height={500}
                            frames={11}
                            source={UX_ASSETS['animation-knight.png']}
                        />
                    </Middle>
                    <Bottom>
                        <ColumnSide>
                            <Counter
                                width={150}
                                value={user.trophies}
                                icon="icon-trophy.png"
                            />
                        </ColumnSide>
                        <Column>
                            <Cancel onPress={onCancel}>
                                <CancelBorder>
                                    <CancelText>
                                        {translate('Cancel')}
                                    </CancelText>
                                </CancelBorder>
                            </Cancel>
                        </Column>
                        <ColumnSide align="flex-end">
                            {user.status.party_search_start !== undefined && (
                                <TimerReward
                                    party_search_start={
                                        user.status.party_search_start
                                    }
                                    server_now={serverNow()}
                                />
                            )}
                        </ColumnSide>
                    </Bottom>
                </Content>
                <Pattern
                    source={UX_ASSETS['bg-pattern.png']}
                    imageStyle={{ resizeMode: 'repeat' }}
                />
            </Container>
            {user.status?.type === USER_STATUS.PARTY &&
                user.status?.party_status === PARTY_STATUS.MATCH_FOUND && (
                    <MatchFound
                        user={user}
                        onAccept={onAccept}
                        onCancel={onCancel}
                    />
                )}
        </>
    )
}

function MatchFound({ user, onAccept, onCancel }) {
    const size = user.status.party_size
    const ready = user.status.party_user_ready
    const users_ready = user.status.party_users_ready
    const seconds = Math.round((user.status.party_end - serverNow()) / 1000)
    const alert = seconds <= 5

    useTimer({ interval: 100 })

    useEffect(() => {
        AUDIO.MATCH_FOUND()
    }, [])

    useEffect(() => {
        if (ready === false && alert) {
            AUDIO.TIMER()
        }
    }, [seconds])

    return (
        <Dialog
            width={700}
            height={400}
            borderColor={'transparent'}
            backgroundColor={COLOR.BLUEBLACK + '99'}
            background={UX_ASSETS['bg-pattern.png']}
            contentColor={COLOR.BEIGE2}
            contentColor2={COLOR.BEIGE4}
            onClose={onCancel}
        >
            <ContentMatchFound>
                <BlockMatchFound>
                    <TitleContainer border={true}>
                        <CounterMatchFound alert={alert}>
                            <CounterTextMatchFound>
                                {seconds}
                            </CounterTextMatchFound>
                        </CounterMatchFound>
                        <Title>{translate('Match Found')}</Title>
                    </TitleContainer>
                </BlockMatchFound>
                <BlockMatchFound>
                    <Players>
                        {Array(size)
                            .fill(null)
                            .map((n, index) => (
                                <Player
                                    key={index}
                                    source={
                                        UX_ASSETS[
                                            index < users_ready
                                                ? 'icon-user.png'
                                                : 'icon-user2.png'
                                        ]
                                    }
                                />
                            ))}
                    </Players>
                </BlockMatchFound>
                <BlockMatchFound>
                    {ready ? (
                        <ReadyText>
                            {translate('Waiting for other players to accept')}.
                        </ReadyText>
                    ) : (
                        <Button
                            width={'75%'}
                            height={80}
                            fontSize={35}
                            label={translate('Accept')}
                            onClick={onAccept}
                        />
                    )}
                </BlockMatchFound>
            </ContentMatchFound>
        </Dialog>
    )
}

function TimerReward({ server_now, party_search_start }) {
    const [ms] = useTimer({ initial: server_now - party_search_start })
    const seconds = Math.round(ms / 1000)
    const { amount, asset } = getRewardSearchQueue({
        seconds: Math.round((serverNow() - party_search_start) / 1000),
    })

    return (
        <TimerContainer>
            <TimerTop>
                <TimeContainer>
                    <TimeReward>
                        <TimeRewardContainer>
                            <Counter
                                width={160}
                                value={`+ ${amount}`}
                                icon={
                                    asset === REWARD_ASSET.COINS
                                        ? 'icon-coin.png'
                                        : 'icon-tulips.png'
                                }
                            />
                        </TimeRewardContainer>
                    </TimeReward>
                    <Time>{secondsToTime(seconds)}</Time>
                </TimeContainer>
            </TimerTop>
            <TimerBottom>
                {translate('Earn rewards by waiting in the queue')}.
            </TimerBottom>
        </TimerContainer>
    )
}

const Container = styled.View`
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${COLOR.BEIGE2};
`

const Background = styled.ImageBackground`
    width: 100%;
    height: 100%;
`

const Content = styled.ImageBackground`
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
`

const Pattern = styled.ImageBackground`
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
`

const Top = styled.View`
    width: ${px(600)};
    padding: ${px(25)} 0;
`
const Middle = styled.View``
const Bottom = styled.View`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 ${px(100)};
`

const ColumnSide = styled.View`
    flex: 1;
    justify-content: center;
    align-items: ${(p) => p.align || 'normal'};
`

const Column = styled.View`
    justify-content: center;
`

const Cancel = styled.TouchableOpacity`
    padding: ${px(25)} ${px(75)};
`

const CancelBorder = styled.View`
    border-bottom-width: ${px(3)};
    border-bottom-color: ${COLOR.BROWN};
`

const CancelText = styled.Text`
    font-family: Poppins-Medium;
    color: ${COLOR.BROWN};
    font-size: ${px(55)};
    line-height: ${px(60)};
    padding-bottom: ${px(5)};
`

const TitleContainer = styled.View`
    border-radius: ${px(30)};
    width: 100%;
    height: ${px(60)};
    background: ${COLOR.BROWN + '28'};
    justify-content: center;
    align-items: center;
`

const Title = styled.Text`
    font-family: Poppins-Black;
    color: ${COLOR.BROWN};
    font-size: ${px(25)};
`

const TimerContainer = styled.View``
const TimerTop = styled.View`
    align-items: center;
`
const TimerBottom = styled.Text`
    font-family: Poppins-Medium;
    color: ${COLOR.BROWN};
    font-size: ${px(18)};
    padding-top: ${px(20)};
    padding-right: ${px(25)};
    text-align: center;
`

const TimeReward = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    // left: 0;
`

const TimeRewardContainer = styled.View``

const TimeContainer = styled.View`
    height: ${px(50)};
    border-radius: ${px(50)};
    background: ${COLOR.BEIGE2};
    align-items: flex-end;
    justify-content: center;
    width: ${px(300)};
`
const Time = styled.Text`
    padding-right: ${px(30)};
    color: ${COLOR.BROWNDARK};
    font-size: ${px(28)};
    font-family: Poppins-Bold;
`

// MatchFound

const ContentMatchFound = styled.View`
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: ${px(30)};
`

const BlockMatchFound = styled.View`
    width: 100%;
    min-height: ${px(85)};
    justify-content: center;
    align-items: center;
`

const CounterMatchFound = styled.View`
    width: ${px(50)};
    height: ${px(50)};
    left: ${px(5)};
    border-radius: 50%;
    position: absolute;
    background: ${(p) => (p.alert ? COLOR.RED : COLOR.BROWNDARK + '55')};
    align-items: center;
    justify-content: center;
`

const CounterTextMatchFound = styled.Text`
    color: white;
    font-size: ${px(22)};
    font-family: Poppins-Bold;
`
const Players = styled.View`
    width: 100%;
    flex-direction: row;
    justify-content: center;
`

const Player = styled.Image`
    width: ${px(100)};
    height: ${px(100)};
`

const ReadyText = styled.Text`
    color: ${COLOR.BEIGE5};
    font-size: ${px(22)};
    font-family: Poppins-Medium;
    width: 100%;
    text-align: center;
`
