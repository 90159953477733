import React from 'react'
import styled from '@emotion/native'
import { UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { px } from '~/utils/device'
import { AnimationPulsate, AnimationBlink } from '~/components/Animations'

export default function Counter({
    value,
    icon,
    icon_size = 60,
    align = 'flex-end',
    color = COLOR.BROWNDARK,
    color_text = COLOR.BEIGE,
    rounded = false,
    border_radius = 8,
    scale = 1,
    width = 'auto',
    height = 50,
    highlight = null,
    shadow = null,
}) {
    return (
        <AnimationBlink active={highlight !== null}>
            <CounterContainer>
                <CounterValueContainer
                    scale={scale}
                    align={align}
                    color={highlight !== null ? highlight : color}
                    width={width}
                    height={height}
                    rounded={rounded}
                    border_radius={border_radius}
                    shadow={shadow}
                >
                    <CounterValue
                        scale={scale}
                        icon={icon}
                        icon_size={icon_size}
                        color_text={color_text}
                    >
                        {value}
                    </CounterValue>
                </CounterValueContainer>

                {icon && (
                    <CounterIcon
                        scale={scale}
                        icon_top={icon_size - height}
                        icon_size={icon_size}
                        source={UX_ASSETS[icon]}
                    />
                )}
            </CounterContainer>
        </AnimationBlink>
    )
}

// Counter
const CounterContainer = styled.View`
    position: relative;
`
const CounterValueContainer = styled.View`
    border-radius: ${(p) =>
        p.rounded ? px(p.height * p.scale) : px(p.border_radius * p.scale)};

    background: ${(p) => p.color};
    min-width: ${(p) => (p.width === 'auto' ? px(150 * p.scale) : 'auto')};
    width: ${(p) => (p.width === 'auto' ? 'auto' : px(p.width * p.scale))};
    height: ${(p) => px(p.height * p.scale)};
    align-items: ${(p) => p.align};
    justify-content: center;
    box-shadow: ${(p) => p.shadow || 'none'};
`
const CounterValue = styled.Text`
    color: ${(p) => p.color_text};
    font-size: ${(p) => px(26 * p.scale)};
    font-family: Poppins-Bold;
    letter-spacing: ${(p) => px(1 * p.scale)};
    padding-left: ${(p) =>
        p.icon === undefined
            ? px(15 * p.scale)
            : px((p.icon_size / 2) * p.scale)};
    padding-right: ${(p) => px(15 * p.scale)};
    padding-top: ${(p) => px(5 * p.scale)};
    padding-bottom: ${(p) => px(5 * p.scale)};
`
const CounterIcon = styled.Image`
    position: absolute;
    width: ${(p) => px(p.icon_size * p.scale)};
    height: ${(p) => px(p.icon_size * p.scale)};
    left: ${(p) => px(-(p.icon_size / 2.5) * p.scale)};
    top: ${(p) => px(-(p.icon_top / 2) * p.scale)};
`
