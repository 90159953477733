import app_package from '../../../package.json'
import * as core from 'conkis-core'
import React, { useEffect } from 'react'
import { VALIDATIONS } from 'conkis-core/'
import { AUDIO } from '~/audio'
import styled from '@emotion/native'
import { Server } from '~/server'
import { onServerPatch, setGlobalState } from '~/store'
import { useLocalState, useGlobalState } from '~/store/hooks'
import { COLOR, LOCALSTORAGE } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { translate, getError } from '~/locale'
import { px, isDesktop, isElectron, windowClose } from '~/utils/device'
import {
    getPlatform,
    getUserAgent,
    localStorageGet,
    localStorageSet,
    localStorageRemove,
    openUrl,
} from '~/utils/device'
import Dialog from '~/components/stateless/Dialog'
import Input from '~/components/stateless/Input'
import Button from '~/components/stateless/Button'

const STEP = {
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
}

export default function Login({}) {
    const [{ logged }] = useGlobalState('logged')
    const [state, setState] = useLocalState(getInitialState())

    useEffect(() => {
        ;(async () => {
            const email = await localStorageGet(LOCALSTORAGE.EMAIL)
            const token = await localStorageGet(LOCALSTORAGE.TOKEN)
            if (email !== null && token !== null) {
                autoLogin(email, token)
            } else if (email !== null) {
                setState({ step: STEP.SECOND, email })
            }
        })()
    }, [])

    function getInitialState() {
        return {
            step: STEP.FIRST,
            email: '',
            code: '',
            username: '',
            error: null,
            loading: false,
        }
    }

    function onChangeEmail(email) {
        setState({ email: email.toLowerCase(), error: null })
    }

    function onChangeCode(code) {
        if (!isNaN(Number(code))) {
            setState({ code, error: null })
            if (code.length === VALIDATIONS.LOGIN_CODE_LENGTH) {
                setGlobalState({ input: null })
                onSecond(code)
            }
        }
    }

    function onChangeUsername(username) {
        setState({ username: username, error: null })
    }

    async function onFirst() {
        AUDIO.CLICK()
        try {
            setState({ loading: true })
            const { expires } = await Server.userGetCode({ email: state.email })
            await localStorageSet(LOCALSTORAGE.EMAIL, state.email)
            setState({ loading: false, code: '', step: STEP.SECOND })
        } catch (e) {
            setState({
                loading: false,
                error: translate(getError(e), state),
            })
        }
    }

    async function onSecond(code) {
        AUDIO.CLICK()
        try {
            setState({ loading: true })
            const { email } = state
            const { token } = await Server.userGetToken({
                email,
                code,
                platform: getPlatform(),
                useragent: getUserAgent(),
            })
            await localStorageSet(LOCALSTORAGE.TOKEN, token)
            await autoLogin(email, token)
        } catch (e) {
            setState({
                loading: false,
                error: translate(getError(e), state),
            })
        }
    }

    async function onThird() {
        // AUDIO.CLICK()
        try {
            setState({ loading: true })
            await Server.userChangeUsername({ username: state.username })
            setState(getInitialState())
            setGlobalState({ logged: true })
        } catch (e) {
            setState({
                loading: false,
                error: translate(getError(e), state),
            })
        }
    }

    function onBackFirst() {
        AUDIO.CLICK()
        setState({ step: STEP.FIRST, error: null })
        localStorageRemove(LOCALSTORAGE.EMAIL)
    }

    async function autoLogin(email, token) {
        setState({ loading: true })
        try {
            const user = await Server.userLogin({
                email,
                token,
                subscription: onServerPatch,
            })

            const patch = { user, logged: true }
            if (user.username === null) {
                patch.logged = false
            }

            setGlobalState(patch)
            if (patch.logged) {
                setState(getInitialState())
            } else {
                setState({ loading: false, step: STEP.THIRD })
            }
        } catch (e) {
            setState({ loading: false, step: STEP.FIRST })
            localStorageRemove(LOCALSTORAGE.EMAIL)
            localStorageRemove(LOCALSTORAGE.TOKEN)
        }
    }

    return logged ? null : (
        <>
            <Dialog
                width={850}
                height={700}
                contentColor="transparent"
                borderColor="transparent"
                backgroundColor={COLOR.BLUEBLACK}
                backgroundResize="stretch"
                background={UX_ASSETS['dialog-bg-login.png']}
                onClose={isElectron() ? windowClose : undefined}
            >
                <LogoContainer>
                    <Logo source={UX_ASSETS['logo.png']} />
                </LogoContainer>

                <Container>
                    <Content>
                        {state.error !== null && (
                            <MessageContainer>
                                <MessageContainer2>
                                    <Message>{state.error}</Message>
                                </MessageContainer2>
                            </MessageContainer>
                        )}

                        {state.step === STEP.FIRST && (
                            <Step1
                                state={state}
                                onChangeEmail={onChangeEmail}
                                onSubmit={onFirst}
                            />
                        )}

                        {state.step === STEP.SECOND && (
                            <Step2
                                state={state}
                                onChangeCode={onChangeCode}
                                onBack={onBackFirst}
                            />
                        )}

                        {state.step === STEP.THIRD && (
                            <Step3
                                state={state}
                                onChangeUsername={onChangeUsername}
                                onSubmit={onThird}
                            />
                        )}
                    </Content>
                </Container>
            </Dialog>
            <Footer>
                <Versions>
                    <Version>app: {app_package.version}</Version>
                    <Version>core: {core.version}</Version>
                </Versions>
                {/* <Discord> */}
                <Button
                    icon="icon-discord.png"
                    bg={null}
                    color="rgb(111, 133, 213)"
                    colorShadow="rgb(94, 112, 178)"
                    width={300}
                    height={75}
                    iconSize={40}
                    iconWidth={200}
                    onClick={() => {
                        openUrl('https://discord.gg/uNccPs6Q8B')
                    }}
                />
                {/* </Discord> */}
            </Footer>
        </>
    )
}

function Step1({ state, onChangeEmail, onSubmit }) {
    return (
        <Form>
            <Field>
                <Input
                    inputMode="email"
                    placeholder={translate('Email')}
                    returnKeyType="done"
                    value={state.email}
                    editable={!state.loading}
                    onChangeText={onChangeEmail}
                    onFocus={() => {
                        if (!isDesktop()) {
                            setGlobalState({
                                input: {
                                    text: state.email,
                                    inputMode: 'email',
                                    onChange: onChangeEmail,
                                    onCancel: onChangeEmail,
                                },
                            })
                        }
                    }}
                />
            </Field>

            <Field>
                <Button
                    width={'100%'}
                    fontSize={35}
                    disabled={state.error !== null || state.loading}
                    label={
                        state.loading
                            ? `${translate('Sending')}...`
                            : `${translate('Log In')}    |    ${translate(
                                  'Sign Up'
                              )}`
                    }
                    onClick={() => {
                        onSubmit()
                    }}
                />
            </Field>
        </Form>
    )
}

function Step2({ state, onChangeCode, onBack }) {
    return (
        <Form>
            <Field>
                <Text1>{translate('Please enter the code sent to')}</Text1>
                <Text2>{state.email}</Text2>
            </Field>

            <Field>
                <Input
                    inputMode="numeric"
                    textAlign="center"
                    maxLength={VALIDATIONS.LOGIN_CODE_LENGTH}
                    fontSize={45}
                    placeholder={translate('Code')}
                    returnKeyType="done"
                    value={state.code}
                    editable={!state.loading}
                    onChangeText={onChangeCode}
                    onFocus={() => {
                        if (!isDesktop()) {
                            setGlobalState({
                                input: {
                                    text: state.code,
                                    inputMode: 'numeric',
                                    maxLength: VALIDATIONS.LOGIN_CODE_LENGTH,
                                    onChange: onChangeCode,
                                    onCancel: onChangeCode,
                                },
                            })
                        }
                    }}
                />
            </Field>

            <Field>
                <Links>
                    <LinkContainer onPress={onBack}>
                        <Link>{translate('Go back to send another code')}</Link>
                    </LinkContainer>
                </Links>
            </Field>
        </Form>
    )
}

function Step3({ state, onChangeUsername, onSubmit }) {
    return (
        <Form>
            <Field>
                <Text2>{translate('Select your User Name')}</Text2>
            </Field>
            <Field>
                {/* <InputLabel>Email</InputLabel> */}
                <Input
                    placeholder={translate('Username')}
                    returnKeyType="done"
                    maxLength={VALIDATIONS.USERNAME_LENGTH}
                    value={state.username}
                    editable={!state.loading}
                    onChangeText={onChangeUsername}
                    onFocus={() => {
                        if (!isDesktop()) {
                            setGlobalState({
                                input: {
                                    text: state.username,
                                    maxLength: VALIDATIONS.USERNAME_LENGTH,
                                    onChange: onChangeUsername,
                                    onCancel: onChangeUsername,
                                },
                            })
                        }
                    }}
                />
            </Field>

            <Field>
                <Button
                    width={'100%'}
                    fontSize={35}
                    disabled={state.error !== null || state.loading}
                    label={
                        state.loading
                            ? `${translate('Sending')}...`
                            : `${translate('Send')}`
                    }
                    onClick={() => {
                        onSubmit()
                    }}
                />
            </Field>
        </Form>
    )
}

const Container = styled.View`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

const Content = styled.View`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: ${px(50)};
`

const Form = styled.View`
    padding: ${px(0)} ${px(125)} 0 ${px(125)};
    width: 100%;
`

const LogoContainer = styled.View`
    width: 100%;
    position: absolute;
    top: ${px(-40)};
    align-items: center;
`

const Logo = styled.Image`
    width: ${px(360 * 0.8)};
    height: ${px(165 * 0.8)};
`

const Field = styled.View`
    margin-bottom: ${px(30)};
    width: 100%;
`

const MessageContainer = styled.View`
    width: 75%;
`

const MessageContainer2 = styled.View`
    position: absolute;
    top: ${px(-80)};
    border-radius: ${px(50)};
    padding: ${px(10)} ${px(25)};
    width: 100%;
    background: ${COLOR.RED};
`

const Message = styled.Text`
    color: white;
    text-align: center;
    font-family: Poppins-Bold;
    font-size: ${px(20)};
`

const Text1 = styled.Text`
    color: ${COLOR.BROWNBLACK};
    text-align: center;
    font-family: Poppins-Medium;
    font-size: ${px(35)};
    width: 100%;
`
const Text2 = styled.Text`
    color: ${COLOR.BROWNBLACK};
    text-align: center;
    font-family: Poppins-Bold;
    font-size: ${px(38)};
    width: 100%;
`

const Links = styled.View`
    flex-direction: row;
    justify-content: space-between;
`

const LinkContainer = styled.TouchableOpacity`
    padding: ${px(20)} ${px(20)};
    width: 100%;
`

const Link = styled.Text`
    font-family: Poppins-SemiBold;
    color: ${COLOR.BROWN};
    font-size: ${px(25)};
    text-decoration: underline;
    text-align: center;
    text-decoration-color: ${COLOR.BROWN};
`

const Footer = styled.View`
    position: absolute;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    z-index: 1;
    bottom: ${px(50)};
    padding: 0 ${px(50)};
`
const Versions = styled.View`
    margin-top: ${px(15)};
    flex-direction: row;
`
const Version = styled.Text`
    color: ${COLOR.BLUE};
    font-size: ${px(20)};
    font-family: Poppins-Medium;
    margin-right: ${px(20)};
    // opacity: 0.2;
`
