import styled from '@emotion/native'
import { px } from '~/utils/device'
import { COLOR } from '~/const'

export default function FormValue({
    icon,
    label,
    value,
    infovalue,
    infolabel,
    backgroundColor = COLOR.BEIGE3 + '66',
    iconsize = 45,
    width = '100%',
    height = 100,
    align = 'space-between',
}) {
    return (
        <DataBlockCounter
            w={width}
            h={height}
            align={align}
            backgroundColor={backgroundColor}
        >
            {icon && <DataBlockIcon size={iconsize} source={icon} />}
            <DataBlockLeft>
                {label && (
                    <DataBlockText>
                        <DataBlockTextMedium>{label}</DataBlockTextMedium>
                    </DataBlockText>
                )}
                {infolabel && (
                    <DataBlockTextExtra>{infolabel}</DataBlockTextExtra>
                )}
            </DataBlockLeft>

            <DataBlockRight>
                <DataBlockText>{value}</DataBlockText>
                {infovalue && (
                    <DataBlockTextExtra>{infovalue}</DataBlockTextExtra>
                )}
            </DataBlockRight>
        </DataBlockCounter>
    )
}

const DataBlockCounter = styled.View`
    background: ${(p) => p.backgroundColor};
    width: ${(p) => px(p.w)};
    height: ${(p) => px(p.h)};
    border-radius: ${px(50)};
    right: 0;
    flex-direction: row;
    justify-content: ${(p) => p.align};
    align-items: center;
    position: absolute;
    padding: 0 ${px(35)};
`
const DataBlockIcon = styled.Image`
    width: ${(p) => px(p.size)};
    height: ${(p) => px(p.size)};
`
const DataBlockText = styled.Text`
    font-family: Poppins-Black;
    color: ${COLOR.BROWNDARK};
    font-size: ${px(32)};
    // line-height: ${px(40)};
`
const DataBlockTextMedium = styled.Text`
    font-family: Poppins-Medium;
`
const DataBlockRight = styled.View`
    align-items: flex-end;
    justify-content: center;
`
const DataBlockLeft = styled.View`
    align-items: flex-start;
    justify-content: center;
`
const DataBlockTextExtra = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${px(15)};
    line-height: ${px(16)};
    color: ${COLOR.BEIGE5};
`
