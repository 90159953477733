import React from 'react'
import styled from '@emotion/native'
import { CARD_SIZE } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { px } from '~/utils/device'
import { translate } from '~/locale'
import { CARD_RARITY_ID, CARD_RARITY_COLORS } from 'conkis-core/'

export default function Card({
    source,
    power,
    rarity,
    touchable = true,
    counter = 1,
    width = CARD_SIZE.WIDTH,
    height = CARD_SIZE.HEIGHT,
    scale = 1,
    disabled = false,
    onClick,
}) {
    const cardwidth = width * scale
    const cardheight = height * scale
    const press = {
        [touchable ? 'onPress' : 'onPressOut']: onClick,
    }
    const CardContainer =
        typeof onClick === 'function'
            ? CardContainerTouchable
            : CardContainerUntouchable

    return (
        <CardContainer
            width={cardwidth}
            height={cardheight}
            disabled={disabled}
            {...press}
        >
            {/* <CardImageBg source={source}></CardImageBg> */}
            <CardImage source={source}></CardImage>

            {rarity && (
                <CardRarity>
                    <CardRarityText scale={scale} rarity={rarity}>
                        {translate(CARD_RARITY_ID[rarity])}
                    </CardRarityText>
                </CardRarity>
            )}

            <CardPower scale={scale} source={UX_ASSETS['card-power.png']}>
                <CardPowerNumber scale={scale}>{power}</CardPowerNumber>
            </CardPower>

            {counter > 1 && (
                <CardCounter scale={scale} source={UX_ASSETS['card-count.png']}>
                    <CardCounterNumber scale={scale}>
                        x{counter}
                    </CardCounterNumber>
                </CardCounter>
            )}
        </CardContainer>
    )
}

const CardContainerTouchable = styled.TouchableOpacity`
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
    opacity: ${(p) => (p.disabled ? '0.5' : '1')};
`

const CardContainerUntouchable = styled.View`
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
    opacity: ${(p) => (p.disabled ? '0.5' : '1')};
`

const CardImage = styled.Image`
    width: 100%;
    height: 100%;
`

const CardPower = styled.ImageBackground`
    position: absolute;
    left: ${px(0)};
    width: ${(p) => px(60 * p.scale)};
    height: ${(p) => px(32 * p.scale)};
    align-items: center;
    justify-content: center;
`

const CardPowerNumber = styled.Text`
    font-family: Poppins-Bold;
    font-size: ${(p) => px((p.size || 20) * p.scale)};
    margin-left: ${(p) => px(p.scale * 8)};
    color: white;
`

const CardCounter = styled.ImageBackground`
    position: absolute;
    top: ${px(0)};
    right: ${px(0)};
    width: ${(p) => px(42 * p.scale)};
    height: ${(p) => px(42 * p.scale)};
    align-items: center;
    justify-content: center;
`

const CardCounterNumber = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${(p) => px((p.size || 15) * p.scale)};
    // margin-top: ${px(10)};
    color: white;
`

const CardRarity = styled.View`
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
`

const CardRarityText = styled.Text`
    font-size: ${(p) => px(p.scale * 13)};
    font-family: Poppins-Bold;
    color: white;
    background: ${(p) => CARD_RARITY_COLORS[p.rarity][0]};
    padding: ${(p) => px(p.scale * 3)} ${(p) => px(p.scale * 15)};
    border-radius: ${(p) => px(p.scale * 5)};
`
