import React, { useState } from 'react'
import styled from '@emotion/native'
import { UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { px } from '~/utils/device'

export default function DropDown({
    open,
    selected,
    options,
    placeholder = '',
    onOpen,
    onSelect,
    disabled = false,
    width = 400,
    height = 100,
    fontSize = 45,
    iconDrop = 'icon-drop-light.png',
    iconSelected = 'icon-check-input-light.png',
    iconSize = 60,
    color = COLOR.BROWNDARK,
    colorText = '#FFFFFF',
}) {
    const borderColor = colorText + '11'
    const is_selected = options.hasOwnProperty(selected)

    return (
        <Container width={width} height={height} open={open}>
            {!open && (
                <Selected
                    color={color}
                    borderColor={borderColor}
                    source={UX_ASSETS['bg-button.png']}
                >
                    <Option
                        height={height}
                        onPress={() => {
                            if (!disabled) onOpen(true)
                        }}
                    >
                        <Text
                            fontSize={fontSize}
                            colorText={
                                is_selected ? colorText : colorText + '55'
                            }
                        >
                            {is_selected ? options[selected] : placeholder}
                        </Text>

                        {!disabled && (
                            <Icon
                                iconSize={iconSize}
                                source={UX_ASSETS[iconDrop]}
                            />
                        )}
                    </Option>
                </Selected>
            )}
            {open && (
                <List top={height} color={color} borderColor={borderColor}>
                    {(options.length === 0 ? [null] : options).map(
                        (option, index) => (
                            <OptionContainer
                                key={index}
                                source={UX_ASSETS['bg-button.png']}
                            >
                                {index > 0 && (
                                    <OptionSeparator
                                        borderColor={borderColor}
                                    />
                                )}
                                <Option
                                    height={height}
                                    onPress={() => {
                                        onOpen(false)
                                        if (index !== selected) {
                                            onSelect(index)
                                        }
                                    }}
                                >
                                    <Text
                                        fontSize={fontSize}
                                        colorText={colorText}
                                    >
                                        {option}
                                    </Text>
                                    {selected === index && (
                                        <Icon
                                            iconSize={iconSize}
                                            source={UX_ASSETS[iconSelected]}
                                        />
                                    )}
                                </Option>
                            </OptionContainer>
                        )
                    )}
                </List>
            )}
        </Container>
    )
}

const paddingsides = 20
const borderradius = 25

const Container = styled.View`
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
    position: relative;
    z-index: ${(p) => (p.open ? '999999' : '0')};
`
const Selected = styled.ImageBackground`
    width: 100%;
    background: ${(p) => p.color};
    border-radius: ${px(100)};
    border: ${px(5)} solid ${(p) => p.borderColor};
    overflow: hidden;
`
const List = styled.View`
    width: 100%;
    background: ${(p) => p.color};
    border-radius: ${px(borderradius)};
    border: ${px(2)} solid ${(p) => p.borderColor};
    position: absolute;
    top: 0;
    box-shadow: 0 ${px(5)} ${px(10)} rgba(0, 0, 0, 0.5);
`
const OptionContainer = styled.ImageBackground`
    overflow: hidden;
    border: ${px(3)} solid transparent;
`
const Option = styled.TouchableOpacity`
    width: 100%;
    height: ${(p) => px(p.height)};
    padding: 0 ${px(paddingsides)};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
const OptionSeparator = styled.View`
    width: 100%;
    height: ${px(2)};
    background: ${(p) => p.borderColor};
`
const Text = styled.Text`
    margin: 0 ${px(paddingsides)};
    font-family: ChangaOne;
    color: ${(p) => p.colorText};
    font-size: ${(p) => px(p.fontSize)};
`
const Icon = styled.Image`
    width: ${(p) => px(p.iconSize)};
    height: ${(p) => px(p.iconSize)};
`
