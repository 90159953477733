import React from 'react'
import styled from '@emotion/native'
import { ERROR } from '~/const/errors'
import { px } from '~/utils/device'
import { UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { translate } from '~/locale'
import { useGlobalState } from '~/store/hooks'
import Button from '~/components/stateless/Button'
import { AUDIO } from '~/audio'

export default React.memo(function PlayError() {
    const [{ playerror }, setGlobalState] = useGlobalState('playerror')

    return playerror === null ? null : (
        <Background>
            <Title>
                {translate(
                    playerror.error === ERROR.SERVER_MAINTENANCE
                        ? 'Games are disabled because we are in maintenance mode. Please, try again later.'
                        : 'After canceling a match you must wait ${seconds} seconds to play again.',
                    playerror
                )}
            </Title>
            <Image
                source={
                    UX_ASSETS[
                        playerror.error === ERROR.SERVER_MAINTENANCE
                            ? 'icon-maintenance.png'
                            : 'icon-penalty.png'
                    ]
                }
            />
            <Ok>
                <OkButton>
                    <Button
                        label={translate('Ok')}
                        width={300}
                        height={80}
                        fontSize={30}
                        color={COLOR.BLUE}
                        onClick={() => {
                            AUDIO.CLICK()
                            setGlobalState({ playerror: null })
                        }}
                    />
                </OkButton>
            </Ok>
        </Background>
    )
})

const Background = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background: ${COLOR.BLUEBLACK + 'fd'};
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    cursor: default;
    z-index: 3;
`

const Title = styled.Text`
    font-family: Poppins-Bold;
    color: white;
    font-size: ${px(25)};
`

const SubTitle = styled.Text`
    font-family: Poppins-Medium;
    color: white;
    font-size: ${px(18)};
`

const Image = styled.Image`
    width: ${px(270)};
    height: ${px(270)};
    margin: ${px(100)} 0;
`

const Ok = styled.View`
    align-items: center;
`

const OkButton = styled.View`
    padding-top: ${px(40)};
`
