import { createLimitPan, randomInt } from './utils/'

export default function createBoard(
    {
        cols,
        rows,
        background,
        angle_horizontal,
        angle_vertical,
        distance,
        distance_min,
        distance_max,
        pan_min_col = 1,
        pan_max_col = cols,
        pan_min_row = 1,
        pan_max_row = rows,
        sprites_random_frequency,
        assets,
    },
    {
        renderer,
        camera,
        controls,
        sprite_animateds,
        changeCameraPosition,
        changeCameraRotation,
        createTerrainPattern,
        createTerrainSprite,
        createTerrainTile,
        createVolumeSprite,
    }
) {
    renderer.setClearColor(background)

    // CHANGING CAMERA
    changeCameraPosition({
        col: (cols - 1) / 2,
        row: (rows - 1) / 2,
    })
    changeCameraRotation({
        angle_horizontal,
        angle_vertical,
        distance,
    })

    // LIMIT CONTROLS
    controls.minDistance = distance_min
    controls.maxDistance = distance_max
    const limitPan = createLimitPan({ camera, controls })
    controls.addEventListener('change', (e) => {
        limitPan({
            minX: pan_min_col,
            maxX: pan_max_col,

            minY: pan_min_col,
            maxY: pan_max_col,

            minZ: pan_min_row,
            maxZ: pan_max_row,
        })

        sprite_animateds.forEach((sprite) => {
            if (sprite.hasOwnProperty('updateRotation')) {
                sprite.updateRotation()
            }
        })
    })

    // CREATING DECORATIVE PATTERNS
    Object.keys(assets.patterns).map((key) => {
        createTerrainPattern(assets.patterns[key])
    })

    // // CREATING TERRAIN TILES
    // const tiles_textures = Object.keys(assets.tiles).map(
    //     (key) => assets.tiles[key].url
    // )
    // for (let position_x = 0; position_x < cols; ++position_x) {
    //     for (let position_z = 0; position_z < rows; ++position_z) {
    //         const random = randomInt(0, tiles_textures.length - 1)
    //         const url = tiles_textures[randomInt(0, tiles_textures.length - 1)]
    //         const allowed = [0, 1]
    //         if (allowed.includes(random)) {
    //             createTerrainTile({
    //                 url,
    //                 position_x,
    //                 position_z,
    //             })
    //         }
    //     }
    // }

    // CREATING RANDOM SPRITES
    const sprites_keys = []
    Object.keys(assets.sprite_random).forEach((key) => {
        const { frecuency } = assets.sprite_random[key]
        sprites_keys.push.apply(sprites_keys, Array(frecuency).fill(key))
    })
    for (let position_x = 0; position_x < cols; ++position_x) {
        for (let position_z = 0; position_z < rows; ++position_z) {
            if (sprites_random_frequency > randomInt(0, 100)) {
                const key = sprites_keys[randomInt(0, sprites_keys.length - 1)]
                const config = assets.sprite_random[key]
                createTerrainSprite({
                    url: config.url,
                    scale: config.scale,
                    position_x,
                    position_z,
                })
            }
        }
    }

    // CREATING DEFINED SPRITES
    assets.sprites_defined.forEach((data) => {
        createVolumeSprite(data)
    })
}
