import styled from '@emotion/native'
import { COLOR } from '~/const'

const DialogBackground = styled.Pressable`
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background: ${(p) => p.backgroundColor || COLOR.BLUEBLACK};
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    cursor: default;
    z-index: ${(p) => p.zindex || '1'};
`

export default DialogBackground
