import { useState, useEffect, useReducer } from 'react'
import { Server } from '~/server'
import { store, setGlobalState } from '~/store'

export function useGlobalState(...props) {
    const [, forceUpdate] = useState()
    useEffect(() => {
        const filter =
            typeof props[0] === 'function'
                ? props[0]
                : ({ path }) => props.includes(path.join('.'))

        return store.subscribe((patch) => {
            forceUpdate(patch)
        }, filter)
    }, [props])
    return [store.state, setGlobalState]
}

export function useLocalState(initial) {
    return useReducer((state, patch) => {
        return { ...state, ...patch }
    }, initial)
}

export function useServer(initial, endpoint, params = {}) {
    const [data, setData] = useState(initial)
    const [error, setError] = useState(null)
    useEffect(() => {
        Server[endpoint](params).then(setData).catch(setError)
    }, [])
    return [data, error]
}

export function useTimer({
    initial = 0,
    increment = 1000,
    interval = 1000,
    stop = false,
}) {
    const [miliseconds, setMiliseconds] = useState(initial)

    useEffect(() => {
        setMiliseconds(initial)
    }, [initial])

    useEffect(() => {
        let timeout
        if (stop === false) {
            timeout = setTimeout(() => {
                setMiliseconds(miliseconds + increment)
            }, interval)
        }
        return () => clearTimeout(timeout)
    }, [miliseconds, increment, stop])

    return [miliseconds]
}
