import { Audio } from 'expo-av'
import { AUDIOS } from '~/const/assets'
import { getGlobalState } from '~/store'

const cached = {}

export const AUDIO = {}

function createAudio(name, { isLooping = false, volume = 0.5, filter } = {}) {
    filter =
        filter ||
        (() => {
            const { sounds } = getGlobalState()
            return sounds
        })

    // const audio = new window.Audio(AUDIOS[name])

    return async function (play = true) {
        const audio = await Audio.Sound.createAsync(AUDIOS[name], {
            volume,
            isLooping,
        })

        if (play) {
            cached[name] = audio
        }

        if (play && filter()) {
            audio.sound.playAsync().catch(() => {})
        } else if (cached.hasOwnProperty(name)) {
            cached[name].sound.stopAsync().catch(() => {})
        }

        return audio
    }
}

const options = {
    CLICK: {
        volume: 0.8,
    },
    OPEN: {
        volume: 0.3,
    },
    CLOSE: {
        volume: 0.3,
    },
    VICTORY: {
        volume: 0.1,
    },
    DEFEAT: {
        volume: 0.2,
    },
    MATCH_FOUND: {
        volume: 0.2,
    },
    NOTIFICATION: {
        volume: 0.4,
    },
    TIMER: {
        volume: 0.3,
    },
    MUSIC: {
        cache: true,
        isLooping: true,
        volume: 0.2,
        filter: () => true,
    },
}

Object.keys(AUDIOS).forEach((name) => {
    AUDIO[name] = createAudio(name, options[name])
})

// window.AUDIO = AUDIO
