import React from 'react'
import styled from '@emotion/native'
import { px } from '~/utils/device'
import { UX_ASSETS } from '~/const/assets'
import { AnimationSpin } from '~/components/Animations'

export default function Loading({ size = 45 }) {
    return (
        <AnimationSpin>
            <LoadingIcon size={size} source={UX_ASSETS['icon-loading.png']} />
        </AnimationSpin>
    )
}

const LoadingIcon = styled.Image`
    width: ${(p) => px(p.size)};
    height: ${(p) => px(p.size)};
    opacity: 0.3;
`
