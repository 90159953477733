import React from 'react'
import styled from '@emotion/native'
import { px } from '~/utils/device'
import { UX_ASSETS } from '~/const/assets'

export default function CardsDeck({ children }) {
    return (
        <Container>
            <BarContainer>
                <BarSide source={UX_ASSETS['card-deck-left.png']} />
                <BarCenter
                    source={UX_ASSETS['card-deck-center.png']}
                    imageStyle={{
                        resizeMode: 'stretch',
                    }}
                />
                <BarSide source={UX_ASSETS['card-deck-right.png']} />
            </BarContainer>
            <DeckCardsContainer>{children}</DeckCardsContainer>
        </Container>
    )
}

const Container = styled.View`
    padding-top: ${px(30)};
    margin-right: ${px(50)};
    width: 100%;
`
const DeckCardsContainer = styled.View`
    padding: 0 ${px(60)};
`
const BarContainer = styled.View`
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: ${px(84)};
    bottom: ${px(-10)};
`
const BarCenter = styled.ImageBackground`
    flex: 1;
`
const BarSide = styled.Image`
    width: ${px(60)};
    height: ${px(84)};
`
