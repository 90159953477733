import React, { useState } from 'react'
import styled from '@emotion/native'
import { GAME_RESULT } from 'conkis-core'
import { px } from '~/utils/device'
import { translate } from '~/locale'
import { AUDIO } from '~/audio'
import { COLOR, VIEW } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { useGlobalState } from '~/store/hooks'
import Button from '~/components/stateless/Button'
import Counter from '~/components/stateless/Counter'

const BUTTON_WIDTH = 325
const BUTTON_HEIGHT = 80
const BUTTON_FONTSIZE = 35
const ASSET_TYPE = {
    3: 'tulip',
    4: 'coin',
}

export default function Winner({ me, winner }) {
    const [{ user }, setGlobalState] = useGlobalState('user.status')
    const [closed, setClosed] = useState(false)

    if (user.status.game_results === undefined) {
        return null
    }

    const result =
        winner.team_id === undefined
            ? GAME_RESULT.DRAW
            : winner.team_id === me.team_id
            ? GAME_RESULT.VICTORY
            : GAME_RESULT.DEFEAT

    const getNumber = (n) => (n > 0 ? `+${n}` : n)
    const { game_results } = user.status
    const { rewards } = game_results
    const trophies = game_results.after.trophies - game_results.before.trophies
    const xp = game_results.after.xp - game_results.before.xp

    function onContinue() {
        AUDIO.CLOSE()
        setGlobalState({
            view: VIEW.HOME,
            game_id: null,
        })
    }

    return (
        <WinnerContainer>
            {!closed && (
                <WinnerBackground
                    imageStyle={{ resizeMode: 'stretch' }}
                    source={UX_ASSETS['bg-game-winner-bg.png']}
                />
            )}
            <WinnerContent>
                {!closed && (
                    <WinnerTop>
                        <WinnerTopContainer>
                            <WinnerTopBackgroundContainer>
                                <WinnerTopBackground
                                    source={UX_ASSETS['bg-game-parchment.png']}
                                />
                            </WinnerTopBackgroundContainer>
                            <WinnerTopContent>
                                {/* <AnimationPulsate
                                    active={result === GAME_RESULT.VICTORY}
                                    scale_max={1.1}
                                > */}
                                <WinnerRibbon
                                    source={
                                        UX_ASSETS[
                                            `bg-game-winner-${result.toLowerCase()}.png`
                                        ]
                                    }
                                >
                                    <WinnerRibbonText>
                                        {translate(result)}
                                    </WinnerRibbonText>
                                </WinnerRibbon>
                                {/* </AnimationPulsate> */}
                                <WinnerReason>
                                    {translate(winner.reason)}
                                </WinnerReason>
                                <WinnerData>
                                    <WinnerProgress>
                                        <WinnerProgressCol>
                                            <Counter
                                                value={getNumber(trophies)}
                                                icon="icon-trophy.png"
                                                scale={0.9}
                                            />
                                        </WinnerProgressCol>
                                        <Counter
                                            value={getNumber(xp)}
                                            icon="icon-xp.png"
                                            scale={0.9}
                                        />
                                    </WinnerProgress>
                                    <WinnerRewards>
                                        {rewards.map((reward, index) => {
                                            return (
                                                <WinnerReward key={index}>
                                                    <WinnerRewardText>
                                                        {translate(reward.type)}
                                                    </WinnerRewardText>
                                                    <Counter
                                                        value={getNumber(
                                                            reward.amount
                                                        )}
                                                        icon={`icon-${
                                                            ASSET_TYPE[
                                                                reward.asset
                                                            ]
                                                        }.png`}
                                                        scale={0.7}
                                                    />
                                                </WinnerReward>
                                            )
                                        })}
                                    </WinnerRewards>
                                </WinnerData>
                            </WinnerTopContent>
                        </WinnerTopContainer>
                    </WinnerTop>
                )}
                <WinnerBottom>
                    <Button
                        width={BUTTON_WIDTH}
                        height={BUTTON_HEIGHT}
                        // color={COLOR.BROWN}
                        fontSize={BUTTON_FONTSIZE}
                        label={translate('Continue')}
                        onClick={onContinue}
                    />
                    <WinnerCloseContainer
                        color={closed ? COLOR.BROWNDARK : COLOR.BEIGE}
                        onPress={() => setClosed(!closed)}
                    >
                        <WinnerClose
                            color={closed ? COLOR.BROWNDARK : COLOR.BEIGE}
                        >
                            {translate(
                                closed ? 'Show Results' : 'Hide Results'
                            )}
                        </WinnerClose>
                    </WinnerCloseContainer>
                </WinnerBottom>
            </WinnerContent>
        </WinnerContainer>
    )
}
const WinnerContainer = styled.View`
    pointer-events: box-none;
    position: absolute;
    width: 100%;
    height: 100%;
`
const WinnerBackground = styled.ImageBackground`
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
`
const WinnerContent = styled.View`
    pointer-events: box-none;
    position: absolute;
    width: 100%;
    height: 100%;
`
const WinnerTop = styled.View`
    pointer-events: box-none;
    flex: 1;
    align-items: center;
    padding-top: ${px(50)};
`
const WinnerTopContainer = styled.View`
    width: ${px(800)};
    height: ${px(758)};
`
const WinnerTopBackgroundContainer = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
`
const WinnerTopBackground = styled.ImageBackground`
    width: ${px(600)};
    height: ${px(758)};
`
const WinnerTopContent = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
`
const WinnerRibbon = styled.ImageBackground`
    width: ${px(700)};
    height: ${px(145)};
    margin-top: ${px(75)};
    align-items: center;
`
const WinnerRibbonText = styled.Text`
    margin-top: ${px(60)};
    color: white;
    font-family: ChangaOne;
    font-size: ${px(50)};
    text-shadow: 0 ${px(3)} #00000055;
`
const WinnerReason = styled.Text`
    margin-top: ${px(25)};
    color: ${COLOR.BROWN};
    font-family: Poppins-Black;
    font-size: ${px(22)};
    opacity: 0.5;
`
const WinnerData = styled.View`
    margin-top: ${px(30)};
    width: ${px(350)};
    height: ${px(300)};
`
const WinnerProgress = styled.View`
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const WinnerProgressCol = styled.View`
    padding-left: ${px(15)};
`
const WinnerRewards = styled.ScrollView`
    width: 100%;
    height: 100%;
    margin-top: ${px(30)};
`
const WinnerReward = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: ${px(8)} 0;
    background: ${COLOR.BEIGE2};
    border-radius: ${px(5)};
`
const WinnerRewardText = styled.Text`
    color: ${COLOR.BROWN};
    font-family: Poppins-Medium;
    font-size: ${px(15)};
    line-height: ${px(18)};
    padding-top: ${px(2)};
    padding-left: ${px(10)};
    letter-spacing: ${px(-0.5)};
`
const WinnerBottom = styled.View`
    position: absolute;
    width: 100%;
    bottom: ${px(75)};
    align-items: center;
`
const WinnerCloseContainer = styled.TouchableOpacity`
    border-bottom-width: ${px(2)};
    border-bottom-color: ${(p) => p.color};
`
const WinnerClose = styled.Text`
    font-family: Poppins-Bold;
    color: ${(p) => p.color};
    font-size: ${px(25)};
    padding-top: ${px(30)};
`
