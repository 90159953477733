import React, { useEffect, useState } from 'react'
import styled from '@emotion/native'
import { translate } from '~/locale'
import { COLOR_NAME, COLOR_TEAMS_CODE } from 'conkis-core'
import { COLOR } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { secondsToTime } from '~/utils'
import { px } from '~/utils/device'
import { AUDIO } from '~/audio'
import { AnimationBlink } from '~/components/Animations'
import Counter from '~/components/stateless/Counter'

export default function Timer({ player, color, board }) {
    const [, forceUpdate] = useState(null)
    const { turn_time, available_timer, available_bonus } =
        board.getTurnStatusByPlayer({ player_id: player.player_id })

    const is_me = color === COLOR_NAME.BLUE
    const available = available_timer + available_bonus
    const seconds = available_timer || available_bonus
    const bonus = available_timer === 0
    const alert = is_me && available < 10 * 1000
    const percent = bonus ? 100 : (seconds * 100) / turn_time
    const round = Math.ceil(board.state.turn / 2)
    // const delay = board.now() - Math.floor(board.now() / 1000) * 1000

    useEffect(() => {
        const timeout = setInterval(() => forceUpdate({}), 100)
        return () => clearInterval(timeout)
    }, [player])

    // console.log(secondsToTime(board.now() / 1000), Math.round(seconds / 1000))
    // return bonus && !is_me ? null : (
    return (
        <TimerContainer>
            <TimerBarContainer>
                <TimerBarSubcontainer style={{ width: percent + '%' }}>
                    {alert ? (
                        <TimerBarPlain color={COLOR.RED} />
                    ) : (
                        <TimerBarGradient
                            imageStyle={{ resizeMode: 'stretch' }}
                            source={
                                UX_ASSETS[`bg-timer-${color.toLowerCase()}.png`]
                            }
                        />
                    )}
                </TimerBarSubcontainer>
            </TimerBarContainer>

            <TimerExtraInfoContainer>
                <TimerExtraInfo>
                    <TimerExtraInfoText>
                        {translate('Round') + ' ' + round}
                    </TimerExtraInfoText>
                </TimerExtraInfo>
                <TimerExtraInfo>
                    <TimerExtraInfoText>
                        {secondsToTime(board.now() / 1000)}
                    </TimerExtraInfoText>
                </TimerExtraInfo>
            </TimerExtraInfoContainer>

            <TimerSecond
                seconds={seconds < 0 ? 0 : Math.round(seconds / 1000)}
                alert={alert}
            />

            {bonus && (
                <BonusContainer>
                    <AnimationBlink>
                        <Counter
                            value={' ' + translate('Bonus time')}
                            icon="icon-clock.png"
                            scale={0.8}
                            height={50}
                            color={COLOR.BROWNBLACK}
                            color_text={'white'}
                        />
                    </AnimationBlink>
                </BonusContainer>
            )}
        </TimerContainer>
    )
}

function TimerSecond({ seconds, alert }) {
    useEffect(() => {
        if (alert && seconds < 10 && seconds > 0) {
            // console.log('TimerSecond', { alert, seconds })
            AUDIO.TIMER()
        }
    }, [seconds, alert])

    return (
        <SecondsContainer>
            <SecondsBackground>
                <Seconds color={alert ? COLOR.RED : 'white'}>{seconds}</Seconds>
            </SecondsBackground>
        </SecondsContainer>
    )
}

const TimerContainer = styled.View`
    max-width: ${px(475)};
    width: 100%;
`
const TimerBarContainer = styled.View`
    width: 100%;
    height: ${px(50)};
    border-radius: ${px(8)};
    background: ${COLOR.BROWNBLACK};
    padding: ${px(8)};
`
const TimerBarSubcontainer = styled.View`
    height: 100%;
`
const TimerBarGradient = styled.ImageBackground`
    width: 100%;
    height: 100%;
    border-radius: ${px(4)};
    overflow: hidden;
    background: ${(p) => (p.alert ? COLOR.RED : 'transparent')};
`
const TimerBarPlain = styled.View`
    width: 100%;
    height: 100%;
    border-radius: ${px(4)};
    overflow: hidden;
    background: ${(p) => p.color};
`
const TimerExtraInfoContainer = styled.View`
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: ${px(-5)};
`
const TimerExtraInfo = styled.View`
    min-width: ${px(100)};
    text-align: center;
    background: ${COLOR.BROWNBLACK};
    border-radius: ${px(8)};
    margin: 0 ${px(5)};
    padding: ${px(4)} ${px(10)};
`
const TimerExtraInfoText = styled.Text`
    color: white;
    text-align: center;
    font-size: ${px(15)};
    font-family: Poppins-Bold;
    letter-spacing: ${px(2)};
`

const SecondsContainer = styled.View`
    position: absolute;
    align-items: center;
    width: 100%;
    top: ${px(45)};
`
// const SecondsBackground = styled.View`
//     align-items: center;
//     width: ${px(130)};
// `
// const Seconds = styled.Text`
//     font-size: ${px(80)};
//     font-family: ChangaOne;
//     color: ${(p) => p.color};
//     text-shadow: 0 ${px(6)} 0 ${COLOR.BROWNBLACK};
// `

const SecondsBackground = styled.View`
    background: ${COLOR.BROWNBLACK};
    align-items: center;
    height: ${px(60)};
    width: ${px(130)};
    // padding: ${px(20)} ${px(40)};
    border-radius: 0 ${px(0)} ${px(200)} ${px(200)};
`
const Seconds = styled.Text`
    font-size: ${px(48)};
    line-height: ${px(48)};
    // height: ${px(55)};
    font-family: ChangaOne;
    color: ${(p) => p.color};
`

const BonusContainer = styled.View`
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: ${px(100)};
    flex-direction: row;
`
