import * as THREE from 'three'
import { loadMeshMaterial } from './utils/Loader'

const tile_geometry = new THREE.PlaneGeometry(1, 1)
export default function createTerrainTile(
    { url, position_x = 0, position_y = 0, position_z = 0 },
    { scene }
) {
    const tile = new THREE.Mesh(tile_geometry, loadMeshMaterial(url))
    tile.position.x = position_x
    // tile.position.y = position_y
    tile.position.z = position_z
    tile.rotation.x = -Math.PI / 2
    scene.terrain.add(tile)

    return {}
}
