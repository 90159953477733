import unit from './unit'

export default (color) => {
    return {
        ...unit,
        color,
        body_asset: {
            url: `units/crossbowoman-${color}.png`,
            offset_x: 0,
            offset_y: 0.05,
            scale: 3.5,
        },
        loops: [
            { name: 'iddle', frames: 51 },
            { name: 'attack', frames: 31 },
            { name: 'walk', frames: 11 },
        ],
        shadow_asset: { url: 'unit-shadow.png', scale: 0.5 },
    }
}
