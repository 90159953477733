const lifescale = 1.4
const hitscale = 3
const healthscale = 3

const unit = {
    hit: { url: 'unit-hit.png' },
    death: { url: 'unit-death.png' },
    tile_walkable: { url: 'tile-doublecircle.png' },
    tile_attackable: { url: 'tile-circle.png' },
    icons: { scale: 0.35 },
    icons_shadow: { url: 'icons-shadow.png', scale: 0.5 },
    'numberhit-0': { url: 'numberhit-0.png', scale: hitscale },
    'numberhit-1': { url: 'numberhit-1.png', scale: hitscale },
    'numberhit-2': { url: 'numberhit-2.png', scale: hitscale },
    'numberhit-3': { url: 'numberhit-3.png', scale: hitscale },
    'numberhit-4': { url: 'numberhit-4.png', scale: hitscale },
    'numberhit-5': { url: 'numberhit-5.png', scale: hitscale },
    'numberhealth-1': { url: 'numberhealth-1.png', scale: healthscale },
    'numberhealth-2': { url: 'numberhealth-2.png', scale: healthscale },
    'numberhealth-3': { url: 'numberhealth-3.png', scale: healthscale },
    'numberhealth-4': { url: 'numberhealth-4.png', scale: healthscale },
    'life-blue-3': {
        url: `unit-life-blue3.png`,
        scale: lifescale,
        max: 3,
        cells: 2,
    },
    'life-blue-4': {
        url: `unit-life-blue4.png`,
        scale: lifescale,
        max: 4,
        cells: 2,
    },
    'life-blue-5': {
        url: `unit-life-blue5.png`,
        scale: lifescale,
        max: 5,
        cells: 3,
    },
    'life-red-3': {
        url: `unit-life-red3.png`,
        scale: lifescale,
        max: 3,
        cells: 2,
    },
    'life-red-4': {
        url: `unit-life-red4.png`,
        scale: lifescale,
        max: 4,
        cells: 2,
    },
    'life-red-5': {
        url: `unit-life-red5.png`,
        scale: lifescale,
        max: 5,
        cells: 3,
    },
}

export default unit
