export default () => {
    return {
        body_asset: {
            url: `flags.png`,
            offset_x: 0,
            offset_y: 0,
            scale: 2,
        },
        frames_horizontal: 12,
        frames_vertical: 12,
        tile_flag_asset: { url: 'tile-flag.png', scale: 2 },
        loops: [],
    }
}
