import * as THREE from 'three'
import { loadSpriteMaterial } from './utils/Loader'

export default function createLife(asset_config) {
    const element = new THREE.Group()
    let maxlife = 3

    function setLife(life, max = maxlife) {
        const { url, cells, scale } =
            asset_config[`life-${asset_config.color}-${max}`]
        element.remove(sprite)

        const material = loadSpriteMaterial(url, { cache: false })
        const sprite = new THREE.Sprite(material)
        const { x, y } = getOffsetByFrame({
            frame: life - 1,
            cells,
        })
        material.map.repeat.set(1 / cells, 1 / cells)
        material.map.offset.x = x
        material.map.offset.y = y
        sprite.scale.set(scale, scale, scale)
        maxlife = max

        // element.position.y = -0.25
        element.add(sprite)
    }

    return {
        element,
        setLife,
    }
}

function getOffsetByFrame({ frame, cells }) {
    let col = frame % cells
    let row = Math.floor(frame / cells)

    const x = col / cells
    const y = (cells - row - 1) / cells

    return { x, y }
}
