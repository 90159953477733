import * as React from 'react'
import Svg, {
    Rect,
    Defs,
    RadialGradient,
    LinearGradient,
    Stop,
} from 'react-native-svg'

// https://www.learnui.design/tools/gradient-generator.html
// https://react-svgr.com/playground/?native=true

export default function Gradient({
    colors,
    linear = true,
    x1 = '0',
    y1 = '0',
    x2 = '0',
    y2 = '100%',
}) {
    const id = 'id' + Math.random()
    const Type = linear ? LinearGradient : RadialGradient
    return (
        <Svg
            width="100%"
            height="100%"
            // viewBox="0 0 500 500"
            // fill="red"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Rect width="100%" height="100%" fill={`url(#${id})`} />
            <Defs>
                <Type
                    id={id}
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    gradientUnits="userSpaceOnUse"
                >
                    {colors.map(([color, offset, opacity = 1], key) => (
                        <Stop
                            key={key}
                            stopColor={color}
                            offset={offset}
                            stopOpacity={opacity}
                        />
                    ))}
                </Type>
            </Defs>
        </Svg>
    )
}

// export default function SvgComponent(props) {
//     return (
//         <Svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="100%"
//             height="100%"
//             viewBox="0 0 1 1"
//             fill="red"
//             preserveAspectRatio="none"
//             {...props}
//         >
//             <Rect
//                 width="100%"
//                 height="100%"
//                 fill="url(#a)"
//                 d="M0 0h800v200H0z"
//             />
//             <Defs>
//                 <RadialGradient
//                     id="a"
//                     x1="0"
//                     y1="0"
//                     x2="100%"
//                     y2="0"
//                     gradientUnits="userSpaceOnUse"
//                 >
//                     <Stop offset={0.15} stopColor="#2c5171" />
//                     <Stop offset={1} stopColor="#0c2036" />
//                 </RadialGradient>
//             </Defs>
//         </Svg>
//     )
// }
