import * as THREE from 'three'
// import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'
import TWEEN from '@tweenjs/tween.js'
import SpriteAnimated from './utils/SpriteAnimated'
import SpriteAnimated3D from './utils/SpriteAnimated3D'
import { loadSpriteMaterial } from './utils/Loader'
import {
    degToRad,
    radToDeg,
    supplant,
    randomInt,
    getRotationFromPoints,
} from './utils/'
import createUxSprite from './createUxSprite'
import createLife from './createLife'
import createTile from './createTile'
import createArea from './createArea'
import createIcons from './createIcons'

export default function createUnit(
    asset_config,
    { debug, scene, addUpdate, getCameraRotation, addSpriteAnimated, renderer }
) {
    const fps = 30
    const group = new THREE.Group()
    let area_range = []
    let area_element = {
        remove: () => {},
        setPosition: () => {},
    }
    let iddleTimeout

    // Icons
    const icons = createIcons({}, { addUpdate })
    group.add(icons.element)

    // Shadow
    let shadow
    if (asset_config.shadow_asset !== undefined) {
        shadow = new THREE.Sprite(
            loadSpriteMaterial(asset_config.shadow_asset.url)
        )
        shadow.scale.set(
            asset_config.shadow_asset.scale,
            asset_config.shadow_asset.scale,
            asset_config.shadow_asset.scale
        )
        shadow.position.y = -0.01
        group.add(shadow)
    }

    // Animated
    const body_material = loadSpriteMaterial(asset_config.body_asset.url, {
        delay: 1000,
        cache: false,
    })
    const body_sprite = new THREE.Sprite(body_material)
    body_material.map.minFilter = THREE.LinearFilter
    body_sprite.position.x = asset_config.body_asset.offset_x || 0
    body_sprite.position.y = asset_config.body_asset.offset_y || 0
    // renderer.initTexture(body_material.map) // https://github.com/mrdoob/three.js/pull/22846#issuecomment-1766524303

    const frames = Math.ceil(
        Math.sqrt(
            asset_config.loops
                .map((loop) => loop.frames)
                .reduce((sum, val) => sum + val, 0) * 8
        )
    )

    const spriteanimated = SpriteAnimated3D({
        onEnterFrame: () => {},
    })
    spriteanimated.animation.addFrames({
        object: body_sprite,
        framesHorizontal: frames,
        framesVertical: frames,
        frameDisplayDuration: 1000 / fps,
    })

    // Creating loops
    asset_config.loops.forEach(({ name, frames }, index) => {
        const orientations = []
        const offset = asset_config.loops
            .map(({ frames }) => frames * 8)
            .slice(0, index)
            .reduce((a, b) => a + b, 0)
        for (let i = 0; i < 8; ++i) {
            const start = i * frames + offset
            const end = start + frames - 1
            orientations.push({
                start,
                end,
                orientation: degToRad(i * 45),
            })
        }
        if (debug) {
            // console.log(name, offset, orientations)
        }
        spriteanimated.createLoop(name, orientations)
    })

    const sprites = spriteanimated.animation.objects
    // addPlaneToFaceCamera(sprites)
    sprites.scale.set(
        asset_config.body_asset.scale,
        asset_config.body_asset.scale,
        asset_config.body_asset.scale
    )
    group.add(sprites)

    // Add life
    const life = createLife(asset_config)
    scene.ux.add(life.element)

    // Hit Animation
    const hit = SpriteAnimated()
    hit.addFrames({
        object: new THREE.Sprite(loadSpriteMaterial(asset_config.hit.url)),
        framesHorizontal: 4,
        framesVertical: 1,
        frameDisplayDuration: 1000 / 6,
    })
    hit.objects.scale.set(2, 2, 2)
    hit.objects.position.y = 0.5
    hit.objects.visible = false
    hit.setKeyFrame(3, {
        onLeaveFrame: () => {
            hit.objects.visible = false
        },
    })
    group.add(hit.objects)
    addSpriteAnimated({ update: hit.update })

    // Tiles
    const tile_canwalk = createTile(
        {
            ...asset_config.tile_walkable,
            opacity: 0,
            position_y: 0.01,
            cache: false,
        },
        { scene }
    )
    const tile_canattack = createTile(
        {
            ...asset_config.tile_attackable,
            opacity: 0,
            position_y: 0.01,
            cache: false,
        },
        { scene }
    )
    // tile_canwalk.hide()
    // tile_canattack.hide()

    scene.sprites.add(group)

    // Axis
    let axis
    if (debug) {
        axis = new THREE.AxesHelper()
        axis.scale.set(5, 5, 5)
        axis.visible = true
        group.add(axis)
    }

    let highlighttile_animation

    const unit = {
        element: group,
        rotation: 0,
        spriteanimated,
        hit,
        remove: () => {
            scene.ux.remove(life.element)
            scene.sprites.remove(group)
            tile_canwalk.remove()
            tile_canattack.remove()
            area_element.remove()
        },

        setLife: life.setLife,

        setPosition: ({ col, row }) => {
            group.position.x = col
            group.position.z = row
            life.element.position.x = col
            life.element.position.z = row
            tile_canwalk.setPosition({ col, row })
            tile_canattack.setPosition({ col, row })
            area_element.setPosition({ col, row })
        },

        showActionsTiles: ({ can_walk, can_attack }) => {
            if (can_walk) {
                tile_canwalk.show()
                tile_canwalk.opacity(1)
            } else if (can_attack) {
                tile_canwalk.opacity(1)
                tile_canattack.opacity(0)
            }
        },

        hideActionsTiles: () => {
            tile_canwalk.opacity(0)
            tile_canattack.opacity(0)
        },

        setColorActionsTiles: (color) => {
            tile_canwalk.setColor(color)
            tile_canattack.setColor(color)
        },

        highlight: ({
            color = 0xffffff,
            multiply = 5,
            time = 1000,
            repeat = Infinity,
            yoyo = false,
        } = {}) => {
            const { r, g, b } = new THREE.Color(color).convertLinearToSRGB()
            return new TWEEN.Tween({
                r: r * multiply,
                g: g * multiply,
                b: b * multiply,
            })
                .to({ r: 1, g: 1, b: 1 }, time)
                .onUpdate(({ r, g, b }) => body_material.color.setRGB(r, g, b))
                .repeat(repeat)
                .yoyo(yoyo)
                .start()
        },

        highlightTile: () => {
            tile_canwalk.element.scale.set(1, 1, 1)
            tile_canattack.element.scale.set(1, 1, 1)

            highlighttile_animation = new TWEEN.Tween({
                scale: 1,
            })
                .to(
                    {
                        scale: 0.8,
                    },
                    500
                )
                .easing(TWEEN.Easing.Quadratic.Out)
                .repeat(Infinity)
                .yoyo(true)
                .onUpdate(({ scale, r, g, b }) => {
                    tile_canwalk.element.scale.set(scale, scale, scale)
                    tile_canattack.element.scale.set(scale, scale, scale)
                })
                .start()
        },

        unhighlightTile: () => {
            tile_canwalk.element.scale.set(1, 1, 1)
            tile_canattack.element.scale.set(1, 1, 1)
            if (highlighttile_animation) {
                highlighttile_animation.stop()
            }
        },

        getPosition: () => {
            return { col: group.position.x, row: group.position.z }
        },

        updateRotation: () => {
            const rotation = degToRad(360) - getCameraRotation() + unit.rotation
            spriteanimated.setRotation(rotation)
        },

        setRotation: (rotation) => {
            unit.rotation = rotation
            unit.updateRotation()
        },

        setOpacity: (opacity) => {
            body_sprite.material.opacity = opacity
        },

        lookAt: (
            to,
            from = {
                col: group.position.x,
                row: group.position.z,
            }
        ) => {
            const rotation = getRotationFromPoints(
                { x: from.col, y: from.row },
                { x: to.col, y: to.row }
            )
            unit.setRotation(rotation)
        },

        iconAdd: ({ id, url }) => {
            icons.add({
                id,
                url,
                scale: asset_config.icons.scale,
                shadow_url: asset_config.icons_shadow.url,
                shadow_scale: asset_config.icons_shadow.scale,
            })
        },

        iconRemove: ({ id }) => {
            icons.remove({ id })
        },

        play: (name) => {
            spriteanimated.play(name)
        },

        stop: () => {
            spriteanimated.stop('iddle')
        },

        animateIddle: ({ loop = true }) => {
            // console.log('animateIddle', loop, asset_config.body_asset.url)
            clearTimeout(iddleTimeout)
            spriteanimated.play('iddle', {
                repeat: loop ? Infinity : 1,
                onFinish: () => {
                    if (spriteanimated.getCurrentLoop() === 'iddle') {
                        iddleTimeout = setTimeout(
                            () => unit.animateIddle({ loop: false }),
                            randomInt(1000, 10000)
                        )
                    }
                },
            })
        },

        animateWalk: ({ path, time = 200, resolve }) => {
            // clearTimeout(iddleTimeout)
            let promise
            if (typeof resolve !== 'function') {
                promise = new Promise((res) => {
                    resolve = res
                })
            }
            const { x, z } = group.position
            const { col, row } = path[0]
            if (spriteanimated.getCurrentLoop() !== 'walk') {
                spriteanimated.play('walk')
            }
            unit.lookAt({ col, row })
            new TWEEN.Tween({ col: x, row: z })
                .to({ col, row }, time)
                .onUpdate(unit.setPosition)
                .onComplete(() => {
                    if (path.length > 1) {
                        unit.animateWalk({ path: path.slice(1), time, resolve })
                    } else {
                        unit.stop()
                        resolve()
                    }
                })
                .start()
            return promise
        },

        animateAttack: ({ to }) => {
            return new Promise((onFinish) => {
                unit.lookAt(to)
                spriteanimated.play('attack', { repeat: 1 })
                const { start, end } = spriteanimated.loops.attack[0]
                const time = ((end - start) / fps) * 1000
                setTimeout(onFinish, time)
            })
        },

        animateHit: ({ damage = 1, time = 1000 } = {}) => {
            return new Promise((resolve) => {
                const number = createUxSprite(
                    asset_config['numberhit-' + damage],
                    { scene }
                )
                number.setPosition({
                    col: group.position.x,
                    row: group.position.z,
                })
                number.fade(time).then(() => {
                    hit.objects.visible = false
                    number.remove()
                    resolve()
                })

                hit.goto(0)
                hit.objects.visible = true

                unit.highlight({ color: 0xff0000, repeat: 1, time })
            })
        },

        animateDeath: ({ time = 2000 } = {}) => {
            return new Promise((resolve) => {
                const skull = createUxSprite(
                    {
                        url: asset_config.death.url,
                        scale: 3,
                        cache: true,
                    },
                    { scene }
                )
                skull.setPosition({
                    col: group.position.x,
                    row: group.position.z,
                })
                skull.fade(time).then(() => {
                    skull.remove()
                })

                group.remove(sprites)
                group.remove(shadow)

                return new TWEEN.Tween({ rgb: 1, opacity: 1 })
                    .to({ rgb: 0, opacity: 0 }, time)
                    .easing(TWEEN.Easing.Quadratic.Out)
                    .onUpdate(({ rgb, opacity }) => {
                        // body_material.color.setRGB(rgb, rgb, rgb)
                        // body_material.opacity = opacity
                        // shadow.material.opacity = opacity
                        life.element.children.forEach(
                            (s) => (s.material.opacity = opacity)
                        )
                    })
                    .onComplete(() => {
                        resolve()
                    })
                    .start()
            })
        },

        showHideAxis: ({ value } = {}) => {
            axis.visible = value || !axis.visible
        },

        showHideRange: ({ value, range, color = 0xffffff }) => {
            if (
                value === true &&
                (range[0] !== area_range[0] || range[1] !== area_range[1])
            ) {
                area_range = range
                area_element.remove()
                const length_hole = range[1] - range[0] + 1
                const from = { col: -range[1], row: -range[1] }
                const to = { col: range[1], row: range[1] }
                const hole = {
                    from: {
                        col: from.col + length_hole,
                        row: from.row + length_hole,
                    },
                    to: {
                        col: to.col - length_hole,
                        row: to.row - length_hole,
                    },
                }
                area_element = createArea(
                    {
                        from,
                        to,
                        holes: [hole],
                        color: color,
                        opacity: 0.3,
                        lineColor: color,
                        lineOpacity: 0,
                    },
                    { scene }
                )
                area_element.setPosition({
                    col: group.position.x,
                    row: group.position.z,
                })
            }

            area_element.element.visible = value
        },
    }

    addSpriteAnimated({
        update: spriteanimated.update,
        updateRotation: unit.updateRotation,
    })

    return unit
}
