import * as THREE from 'three'
import { loadMeshMaterial } from './utils/Loader'

export default function createTerrainPattern(
    {
        url,
        repeat,
        units = repeat,
        position_x = 0,
        position_y = 0,
        position_z = 0,
        rotation_x = -Math.PI / 2,
        rotation_y = 0,
        rotation_z = 0,
        offset_x = 0,
        offset_y = 0,
        scale = 1,
    },
    { scene }
) {
    const geometry = new THREE.PlaneGeometry(units, units)
    const material = loadMeshMaterial(url, {
        // color: 0xffffff,
        // alphaTest: 0.5,
        // transparent: true,
    })

    // texture.minFilter = THREE.LinearFilter
    // texture.minFilter = THREE.LinearMipMapLinearFilter
    // texture.minFilter = THREE.NearestFilter

    material.map.offset.x = offset_x
    material.map.offset.y = offset_y

    const pattern = new THREE.Mesh(geometry, material)
    material.map.wrapS = material.map.wrapT = THREE.RepeatWrapping
    material.map.repeat.set(repeat, repeat)
    pattern.position.x = position_x
    pattern.position.y = position_y
    pattern.position.z = position_z

    pattern.rotation.x = rotation_x
    pattern.rotation.y = rotation_y
    pattern.rotation.z = rotation_z
    pattern.scale.set(scale, scale, 1)
    scene.terrain.add(pattern)

    return {}
}
