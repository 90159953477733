import SpriteAnimated from './SpriteAnimated'

export default function SpriteAnimated3D({ onEnterFrame }) {
    const animation = SpriteAnimated({ onEnterFrame: onEnterFrameWrapper })
    const loops = {}
    let rotation = 0
    let current_loop
    let onFinishLoop

    function createLoop(name, orientations) {
        if (current_loop === undefined) {
            current_loop = name
        }
        loops[name] = orientations
        orientations.forEach(({ start, end }) => {
            animation.setKeyFrame(end, { onLeaveFrame })
        })
    }

    function onEnterFrameWrapper(frame) {
        // console.log(current_loop, frame)
        if (typeof onEnterFrame === 'function') {
            const loop_item = loops[current_loop].find(
                ({ start, end }) => frame <= end && frame >= start
            )
            if (loop_item !== undefined) {
                onEnterFrame(frame - loop_item.start, frame)
            }
        }
    }

    function onLeaveFrame() {
        const { current_frame } = animation
        const loop_item = loops[current_loop].find(
            ({ end }) => end === current_frame
        )
        if (loop_item !== undefined) {
            if (onFinishLoop && current_frame === loop_item.end) {
                onFinishLoop({
                    current_loop,
                    current_frame,
                    loop_item,
                })
            }
            return loop_item.start
        }
    }

    function reduceExcessRotation(rotation) {
        const onecircle = Math.PI * 2
        const cicles = rotation / onecircle
        return cicles > 1 ? rotation - Math.floor(cicles) * onecircle : rotation
    }

    function update(delta) {
        return animation.update(delta)
    }

    function setRotation(rot) {
        rotation = reduceExcessRotation(rot)
        updateRotation()
    }

    function updateRotation() {
        const { current_frame } = animation
        const loops_name = loops[current_loop]
        const { current, selected } = getIndexLoop(
            loops_name,
            current_frame,
            rotation
        )
        if (current !== selected) {
            const relative_frame = current_frame - loops_name[current].start

            if (relative_frame <= animation.frames.length) {
                animation.goto(loops_name[selected].start + relative_frame)
            }
        }
    }

    function play(
        name = current_loop,
        { repeat = Infinity, onFinish = () => {} } = {}
    ) {
        let repeat_index = 0
        current_loop = name
        animation.goto(getStartLoop(name))
        animation.play()
        onFinishLoop = (params) => {
            repeat_index += 1
            // console.log('onFinishLoop', repeat_index, repeat, getCurrentLoop())
            if (repeat_index >= repeat) {
                animation.pause()
                onFinish(params)
            }
        }
    }

    function stop(name = current_loop) {
        current_loop = name
        animation.goto(getStartLoop(name))
        animation.pause()
    }

    function getStartLoop(name) {
        const { current_frame } = animation
        const { selected } = getIndexLoop(
            loops[current_loop],
            current_frame,
            rotation
        )
        return loops[name][selected].start
    }

    function getIndexLoop(loops, current_frame, rotation) {
        let min_orientation = Infinity
        let min_index
        let min_difference = Infinity
        let selected = 0
        let current = 0
        loops.forEach(({ start, end, orientation }, index) => {
            const difference = Math.abs(orientation - rotation)
            if (current_frame >= start && current_frame <= end) {
                current = index
            }
            if (difference < min_difference) {
                min_difference = difference
                selected = index
            }
            if (orientation < min_orientation) {
                min_orientation = orientation
                min_index = index
            }
        })

        // Initial orientation
        const onecircle = Math.PI * 2
        const orientation = onecircle + min_orientation
        const difference = Math.abs(orientation - rotation)
        if (difference < min_difference) {
            selected = min_index
        }

        return { current, selected }
    }

    function getCurrentLoop() {
        return current_loop
    }

    const animation3d = {
        loops,
        animation,
        createLoop,
        setRotation,
        getCurrentLoop,
        update,
        play,
        stop,
    }

    return animation3d
}
