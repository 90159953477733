import styled from '@emotion/native'
import { px } from '~/utils/device'
import { COLOR } from '~/const'

export const FormField = styled.View`
    width: 100%;
    height: ${(p) => px(p.height || 100)};
`

export const FormFieldSeparator = styled.View`
    height: ${px(30)};
`
