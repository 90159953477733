import styled from '@emotion/native'
import { px } from '~/utils/device'
import { COLOR } from '~/const'

export const H1 = styled.Text`
    text-align: ${(p) => p.align || 'left'};
    font-family: Poppins-Black;
    font-size: ${(p) => px(p.size || 30)};
    color: ${(p) => p.color || COLOR.BEIGE};
    text-shadow: 0 ${px(3)} 0 rgba(0, 0, 0, 0.15);
`
