import React, { useState } from 'react'
import styled from '@emotion/native'
import { UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { px, isIos } from '~/utils/device'
import { colorLuminance } from '~/utils'

export default function Button({
    label,
    onClick,
    width = 400,
    height = 100,
    fontSize = 45,
    icon,
    iconUri = false,
    iconSize = 40,
    iconWidth,
    color = COLOR.BROWNDARK,
    colorShadow,
    shadowSize = bordersize,
    colorText = 'white',
    luminance = -0.5,
    disabled = false,
    bg = 'bg-button.png',
}) {
    const [pressed, setPressed] = useState(false)

    if (disabled) {
        color = COLOR.DISABLED
        colorText = 'rgba(255,255,255,0.1)'
    }

    colorShadow = colorShadow || colorLuminance(color, luminance)
    return (
        <Shadow width={width} height={height}>
            <Container
                disabled={disabled}
                pressed={pressed}
                color={color}
                colorShadow={colorShadow}
                shadowSize={shadowSize}
                onPress={onClick}
                width={width}
                height={height}
                onPressIn={() => setPressed(true)}
                onPressOut={() => setPressed(false)}
            >
                <Container2
                    borderColor={colorShadow}
                    source={UX_ASSETS[bg]}
                    imageStyle={{ resizeMode: 'stretch' }}
                >
                    <Background>
                        {icon && (
                            <Icon
                                iconSize={iconSize}
                                iconWidth={iconWidth}
                                source={
                                    iconUri ? { uri: icon } : UX_ASSETS[icon]
                                }
                                disabled={disabled}
                            />
                        )}
                        {label && (
                            <Text
                                fontSize={fontSize}
                                colorText={colorText}
                                icon={icon}
                            >
                                {label}
                            </Text>
                        )}
                    </Background>
                </Container2>
            </Container>
        </Shadow>
    )
}

const bordersize = 8
const borderradius = 100
const paddingsides = 10
const boxshadow = isIos()
    ? ''
    : `box-shadow: 0 ${px(bordersize + 5)} ${px(3)} rgba(0, 0, 0, 0.2);`

const Shadow = styled.View`
    width: ${(p) => px(p.width)};
    height: ${(p) => px(p.height)};
    border-radius: ${px(borderradius)};
    ${boxshadow}
`

const Container = styled.Pressable`
    width: 100%;
    height: 100%;
    position: relative;
    top: ${(p) => (p.pressed ? px(bordersize) : 0)};
    box-shadow: ${(p) =>
        p.pressed ? 'none' : '0px ' + px(p.shadowSize) + ' ' + p.colorShadow};
    background: ${(p) => p.color};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    // border: ${px(6)} solid transparent;
    border-radius: ${px(borderradius)};
`

const Container2 = styled.ImageBackground`
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: ${px(borderradius)};
`

const Background = styled.View`
    width: 100%;
    height: 100%;
    flex-direction: row; // row-reverse
    align-items: center;
    justify-content: center;
`

const Text = styled.Text`
    margin: 0 ${px(paddingsides)};
    font-family: ChangaOne;
    color: ${(p) => p.colorText};
    font-size: ${(p) => px(p.fontSize)};
`

const Icon = styled.Image`
    // margin-right: ${px(paddingsides)};
    width: ${(p) => px(p.iconWidth || p.iconSize)};
    height: ${(p) => px(p.iconSize)};
    opacity: ${(p) => (p.disabled ? '.5' : '1')};
`
