import styled from '@emotion/native'
import { px } from '~/utils/device'
import { COLOR } from '~/const'

export const Error = styled.View`
    border-radius: ${px(10)};
    padding: ${px(10)} ${px(10)};
    background: ${COLOR.REDDARK};
`
export const ErrorText = styled.Text`
    margin: 0 ${px(30)};
    font-size: ${px(20)};
    color: white;
    opacity: 1;
    font-family: ${(p) => (p.bold ? 'Poppins-Bold' : 'Poppins-Medium')};
    text-align: center;
`
