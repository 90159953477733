import React, { useEffect } from 'react'
import Animated, {
    Easing,
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withTiming,
} from 'react-native-reanimated'
import { CARD_RARITY_ID, REWARD_ASSET } from 'conkis-core'
import styled from '@emotion/native'
import { px, screenWidth, isWeb } from '~/utils/device'
import { UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { translate } from '~/locale'
import RewardIcon from '~/components/stateless/RewardIcon'

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (isWeb()) {
    window._frameTimestamp = null
}

const TITLE = {
    1: 'You have earned a new Icon!',
    2: 'You have earned a ${rarity} Card!',
    3: 'You have earned these Tulips!',
    4: 'You have earned these Coins!',
}

export default function RewardsCollected({ collect, onClose }) {
    const { params, reward } = collect
    const duration = 20000
    const easing = Easing.linear
    const wh = screenWidth() * 1.1
    const sv = useSharedValue(0)

    // return null

    useEffect(() => {
        //https://docs.swmansion.com/react-native-reanimated/docs/animations/withRepeat
        sv.value = withRepeat(withTiming(1, { duration, easing }), -1)
    }, [])

    const animatedStyle = useAnimatedStyle(() => ({
        transform: [{ rotate: `${sv.value * 360}deg` }],
    }))

    const titleargs =
        reward.asset === REWARD_ASSET.CARD
            ? { rarity: translate(CARD_RARITY_ID[reward.rarity]) }
            : {}

    return (
        <Container onPress={onClose}>
            <Animated.View
                style={[
                    {
                        position: 'absolute',
                        width: wh,
                        height: wh,
                    },
                    animatedStyle,
                ]}
            >
                <Shine1 source={UX_ASSETS['bg-shine.png']} />
            </Animated.View>
            <Animated.View
                style={[
                    {
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                    animatedStyle,
                ]}
            >
                <Shine2 source={UX_ASSETS['bg-shine2.png']} />
            </Animated.View>
            <Content>
                <SubContent>
                    <Header>
                        <Title>
                            {translate(TITLE[reward.asset], titleargs)}
                        </Title>
                    </Header>
                    <Middle>
                        <RewardIcon {...params} size={400} />
                    </Middle>
                    <Bottom>
                        <Label>{params.label}</Label>
                    </Bottom>
                </SubContent>
            </Content>
        </Container>
    )
}

const Container = styled.Pressable`
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: ${COLOR.BEIGE2};
`

const Shine1 = styled.ImageBackground`
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.1;
`

const Shine2 = styled.Image`
    width: ${px(800)};
    height: ${px(800)};
    opacity: 0.2;
`

const Content = styled.View`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`
const SubContent = styled.View`
    // width: ${px(800)};
`
const Header = styled.View`
    align-items: center;
    justify-content: flex-end;
    height: ${px(200)};
`
const Middle = styled.View`
    align-items: center;
    padding: ${px(50)} 0;
`
const Bottom = styled.View`
    align-items: center;
    height: ${px(200)};
`
const Title = styled.Text`
    color: ${COLOR.BROWN};
    font-family: ChangaOne;
    font-size: ${px(40)};
`
const Label = styled.Text`
    color: ${COLOR.BROWN};
    font-family: ChangaOne;
    font-size: ${px(75)};
`
