import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js'
import { loadSpriteMaterial } from './utils/Loader'

export default function createUxSprite(
    { url, scale, cache = false },
    { scene }
) {
    // https://threejsfundamentals.org/threejs/threejs-textured-cube-adjust.html
    const material = loadSpriteMaterial(url, { cache })
    const sprite = new THREE.Sprite(material)
    sprite.scale.set(scale, scale, scale)
    material.map.minFilter = THREE.LinearFilter
    material.map.repeat.set(4, 4)
    material.map.center.set(0.5, 0.5)
    material.map.offset.y = -0.5
    scene.ux.add(sprite)

    return {
        element: sprite,

        remove: () => {
            scene.ux.remove(sprite)
        },

        setPosition: ({ col, row }) => {
            sprite.position.x = col
            sprite.position.z = row
        },

        fade: (time = 1000) => {
            return new Promise((resolve) => {
                const y = sprite.material.map.offset.y
                new TWEEN.Tween({ y, opacity: 1 })
                    .to({ y: -1, opacity: 1 }, time)
                    .easing(TWEEN.Easing.Quadratic.Out)
                    .onUpdate(({ y, opacity }) => {
                        sprite.material.map.offset.y = y
                        sprite.material.opacity = opacity
                    })
                    .onComplete(resolve)
                    .start()
            })
        },
    }
}
