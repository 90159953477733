import React, { useState, forwardRef, useEffect } from 'react'
import styled, { css } from '@emotion/native'
import { isTouchable } from '~/utils/device'

export default forwardRef(function HorizontalScroll(
    { children, ...props },
    ref
) {
    const View = isTouchable() ? Mobile : Desktop

    return (
        <View ref={ref} {...props}>
            {children}
        </View>
    )
})

const Desktop = forwardRef(({ children, ...props }, ref) => {
    const [state] = useState({ scrolling: false })

    useEffect(() => {
        let pos = { left: 0, x: 0 }
        const ele = ref.current
        // ref.current.className += ' hide-scroll'
        ele.addEventListener('mousedown', onMouseDown)
        function onMouseDown(e) {
            document.addEventListener('mousemove', onMouseMove)
            document.addEventListener('mouseup', onMouseUp)
            ele.style.cursor = 'grabbing'
            pos = {
                left: ele.scrollLeft,
                x: e.clientX,
            }
        }
        function onMouseMove(e) {
            const dx = e.clientX - pos.x
            ele.scrollLeft = pos.left - dx
            if (Math.abs(dx) > 5) {
                state.scrolling = true
            }
        }
        function onMouseUp(e) {
            ele.style.cursor = 'inherit'
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
            setTimeout(() => (state.scrolling = false), 0)
        }

        return () => {
            ele.removeEventListener('mousedown', onMouseDown)
        }
    }, [ref, state])

    return (
        <ScrollView {...props} horizontal={true} ref={ref}>
            {children}
        </ScrollView>
    )
})

const Mobile = forwardRef(({ children, ...props }, ref) => {
    return (
        <ScrollView {...props} horizontal={true} ref={ref}>
            {children}
        </ScrollView>
    )
})

const ScrollView = styled.ScrollView`
    width: 100%;
    height: 100%;
`
