import React from 'react'
import styled from '@emotion/native'
import { px, isDesktop, openUrl } from '~/utils/device'
import { COLOR } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { AUDIO } from '~/audio'
import { translate } from '~/locale'
import { CHAINS, getShorterAddress, ethers } from '~/npm/web3'
import { useGlobalState, useLocalState } from '~/store/hooks'
import { Server } from '~/server'
import Dialog from '~/components/stateless/Dialog'
import DropDown from '~/components/stateless/DropDown'
import Input from '~/components/stateless/Input'
import Button from '~/components/stateless/Button'
import FormValue from '~/components/stateless/FormValue'
import { FormField, FormFieldSeparator } from '~/components/stateless/Form'
import Loading from '~/components/stateless/Loading'
import { Error, ErrorText } from '~/components/stateless/Error'

export default function CardMint({ cards, onClose, onComplete }) {
    const [{ web3 }, setGlobalState] = useGlobalState()
    const [state, setState] = useLocalState({
        loading: false,
        completed: false,
        error: null,
        chain_open: false,
        chain_selected: null,
        address_open: false,
        address_selected: null,
        address: '',
    })
    const chains = Object.keys(CHAINS).map((chain_id) => CHAINS[chain_id])
    const chain = getChain()
    const addresses = web3.addresses
        .slice(0)
        .map((addrs) => ({ value: addrs, label: getShorterAddress(addrs) }))
        .concat([{ value: null, label: translate('Enter Address') + '...' }])

    const is_custom =
        state.address_selected !== null &&
        state.address_selected >= web3.addresses.length
    const is_invalid_address =
        is_custom &&
        state.address.length > 0 &&
        !ethers.isAddress(state.address)
    const is_valid = getChain() !== null && getAddress() !== null

    function onChangeAddress(address) {
        setState({ address, error: null })
    }

    function getChain() {
        const chain_id = chains[state.chain_selected]
        return chain_id === undefined ? null : chain_id
    }

    function getAddress() {
        const address = is_custom
            ? state.address
            : web3.addresses[state.address_selected]
        return ethers.isAddress(address) ? address : null
    }

    async function onMint() {
        AUDIO.CLICK()
        setState({ loading: true, error: null })
        const chain_id = getChain().chainId
        const address = getAddress()
        const card_ids = cards.map((c) => c._id)
        try {
            const tx = await Server.userMint({
                chain_id,
                address,
                card_ids: card_ids,
            })
            setState({ loading: false, completed: true, tx })
            onComplete()
        } catch (error) {
            setState({ loading: false, error })
        }
    }

    return (
        <Dialog
            width={750}
            height={750}
            onClose={onClose}
            onBackground={onClose}
        >
            <Container>
                <Content>
                    {state.loading && (
                        <Block>
                            <Loading size={75} />
                        </Block>
                    )}
                    {state.completed && (
                        <Block>
                            <Completed>
                                <CompletedImage
                                    source={UX_ASSETS['icon-check-green.png']}
                                />
                                <FormFieldSeparator />
                                <CompletedText>
                                    {translate('Mint completed')}!
                                </CompletedText>
                                <FormFieldSeparator />
                                <FormFieldSeparator />
                                <CompletedLink
                                    onPress={() =>
                                        openUrl(
                                            `${chain.blockExplorerUrls[0]}/tx/${state.tx.hash}`
                                        )
                                    }
                                >
                                    <CompletedLinkText>
                                        {translate(
                                            'See tx on the block-explorer'
                                        )}
                                        .
                                    </CompletedLinkText>
                                </CompletedLink>
                            </Completed>
                            <FormFieldSeparator />
                            <Button
                                label={translate('Continue')}
                                color={COLOR.BLUEDARK}
                                width={'100%'}
                                height={100}
                                fontSize={35}
                                onClick={onClose}
                            />
                        </Block>
                    )}
                    {!state.loading && !state.completed && (
                        <>
                            <Block>
                                <FormField>
                                    <FormValue
                                        icon={UX_ASSETS['icon-cards.png']}
                                        value={`  x${cards.length}`}
                                        align="flex-start"
                                    />
                                    <FormValue
                                        icon={UX_ASSETS['icon-coin.png']}
                                        value={`  ${translate('Free')}`}
                                        width="50%"
                                        align="flex-end"
                                    />
                                </FormField>
                            </Block>
                            <Block zIndex={state.chain_open ? '1' : '0'}>
                                <DropDown
                                    placeholder={translate('Select Blockchain')}
                                    open={state.chain_open}
                                    selected={state.chain_selected}
                                    options={chains.map((c) => c.chainName)}
                                    width={'100%'}
                                    fontSize={35}
                                    onOpen={(chain_open) => {
                                        AUDIO.CLICK()
                                        setState({ chain_open })
                                    }}
                                    onSelect={(chain_selected) => {
                                        setState({ chain_selected })
                                    }}
                                />
                            </Block>
                            <Block zIndex={state.address_open ? '1' : '0'}>
                                <DropDown
                                    placeholder={translate('Select Address')}
                                    open={state.address_open}
                                    selected={state.address_selected}
                                    options={addresses.map((a) => a.label)}
                                    width={'100%'}
                                    fontSize={35}
                                    onOpen={(address_open) => {
                                        AUDIO.CLICK()
                                        setState({ address_open })
                                    }}
                                    onSelect={(address_selected) => {
                                        setState({ address_selected })
                                    }}
                                />
                            </Block>
                            {is_custom && (
                                <Block>
                                    <Input
                                        placeholder={getShorterAddress(
                                            '0x0000000000000000000000000000000000000000'
                                        )}
                                        returnKeyType="done"
                                        value={state.address}
                                        color={
                                            is_invalid_address
                                                ? COLOR.RED
                                                : COLOR.BROWNDARK
                                        }
                                        borderColor={
                                            is_invalid_address
                                                ? COLOR.RED
                                                : COLOR.BROWN
                                        }
                                        editable={true}
                                        onChangeText={onChangeAddress}
                                        onFocus={() => {
                                            if (!isDesktop()) {
                                                setGlobalState({
                                                    input: {
                                                        text: state.address,
                                                        onChange:
                                                            onChangeAddress,
                                                        onCancel:
                                                            onChangeAddress,
                                                    },
                                                })
                                            }
                                        }}
                                    />
                                </Block>
                            )}
                            <Block>
                                <Button
                                    disabled={!is_valid}
                                    label={translate('Mint')}
                                    color={COLOR.GREENDARK}
                                    width={'100%'}
                                    height={100}
                                    fontSize={35}
                                    onClick={onMint}
                                />
                                <InfoAddress>
                                    {translate(
                                        'Be sure you control this address. Otherwise, you will lose your cards'
                                    )}
                                    .
                                </InfoAddress>
                            </Block>
                        </>
                    )}
                </Content>
            </Container>
            {state.error !== null && (
                <ErrorContainer>
                    <Error>
                        <ErrorText>
                            {translate(
                                'An error occurred during the minting. Try again later or contact support.'
                            )}
                        </ErrorText>
                        {/* <ErrorText bold={true}>
                                        {translate('Error: ${error}', {
                                            error: state.error,
                                        })}
                                    </ErrorText> */}
                    </Error>
                </ErrorContainer>
            )}
        </Dialog>
    )
}

const Container = styled.View`
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

const Content = styled.View`
    width: 85%;
`

const Block = styled.View`
    padding: ${(p) => px(p.padding || 15)} 0;
    justify-content: center;
    align-items: center;
    z-index: ${(p) => px(p.zIndex || '0')};
`

const InfoAddress = styled.Text`
    margin-top: ${px(20)};
    font-size: ${px(15)};
    font-family: Poppins-Medium;
    color: ${COLOR.BROWN};
    opacity: 0.8;
`

const Completed = styled.View`
    width: 100%;
    height: ${px(500)};
    align-items: center;
    justify-content: center;
`
const CompletedImage = styled.Image`
    width: ${px(150)};
    height: ${px(150)};
`
const CompletedText = styled.Text`
    font-family: Poppins-Black;
    color: ${COLOR.BROWNDARK};
    font-size: ${px(32)};
`
const CompletedLink = styled.TouchableOpacity`
    border-bottom-width: ${px(3)};
    border-bottom-color: ${COLOR.BLUEDARK};
`
const CompletedLinkText = styled.Text`
    font-family: Poppins-Medium;
    color: ${COLOR.BLUEDARK};
    font-size: ${px(20)};
`
const ErrorContainer = styled.View`
    position: relative;
    bottom: ${px(25)};
    width: 100%;
`
