import React, { useEffect } from 'react'
import styled from '@emotion/native'
import { USER_STATUS } from 'conkis-core'
import { CONNECTION, VIEW, LOCALSTORAGE } from '~/const'
import { useGlobalState } from '~/store/hooks'
import { connect } from '~/server'
import { localStorageSet } from '~/utils/device'

// Partials
import InputApp from '~/components/partials/InputApp'
// Views
import Home from '~/components/views/Home'
import Profile from '~/components/views/Profile'
import Cards from '~/components/views/Cards'
import Shop from '~/components/views/Shop'
import Play from '~/components/views/Play'
import Searching from '~/components/views/Searching'
import Game from '~/components/views/Game/Game'
// Dialogs
import Login from '~/components/dialogs/Login'
import Connectivity from '~/components/dialogs/Connectivity'
import Outdated from '~/components/dialogs/Outdated'
import Web3 from '~/components/dialogs/Web3'
import Invited from '~/components/dialogs/Invited'
import PlayError from '~/components/dialogs/PlayError'

const VIEWS = {
    [VIEW.HOME]: <Home />,
    [VIEW.PROFILE]: <Profile />,
    [VIEW.CARDS]: <Cards />,
    [VIEW.SHOP]: <Shop />,
    [VIEW.PLAY]: <Play />,
    [VIEW.SEARCHING]: <Searching />,
    [VIEW.GAME]: <Game />,
}

export default function Views() {
    const [
        { connection, view, logged, outdated, user, game_id },
        setGlobalState,
    ] = useGlobalState(
        'connection',
        'logged',
        'view',
        'outdated',
        'user.status',
        'game_id'
    )

    useEffect(() => {
        connect()
    }, [])

    useEffect(() => {
        if (user.status?.type === USER_STATUS.PLAYING) {
            const { game_id } = user.status
            localStorageSet(LOCALSTORAGE.GAME_ID, game_id)
            setGlobalState({ view: VIEW.GAME, game_id })
        }
    }, [user.status])

    if (outdated !== null) {
        return <Outdated />
    }

    if (connection !== CONNECTION.OPEN) {
        return <Connectivity />
    }

    if (!logged) {
        return (
            <>
                <Login />
                <InputApp />
            </>
        )
    }

    return (
        <>
            <Container>
                <Content>
                    {game_id === null ? VIEWS[view] : VIEWS[VIEW.GAME]}
                </Content>
            </Container>
            <Web3 />
            <InputApp />
            <Invited />
            <PlayError />
            {/* <InfoDev /> */}
        </>
    )
}

// const InfoDevContainer = styled.View`
//     display: flex;
//     pointer-events: none;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background: rgba(255, 255, 255, 0.6);
//     z-index: 99999;
// `

// function InfoDev() {
//     const [state] = useGlobalState('connection', 'connection_trys', 'logged')

//     return (
//         <InfoDevContainer>
//             <div>connection: {JSON.stringify(state.connection)}</div>
//             <div>connection_trys: {JSON.stringify(state.connection_trys)}</div>
//             <div>logged: {JSON.stringify(state.logged)}</div>
//             <div>email: {localStorage.getItem('EMAIL')}</div>
//             <div>token: {localStorage.getItem('TOKEN')}</div>
//         </InfoDevContainer>
//     )
// }

const Container = styled.View`
    flex: 1;
`

const Content = styled.View`
    width: 100%;
    height: 100%;
`
