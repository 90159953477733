import React, { Component } from 'react'
import Svg, { Circle, Rect } from 'react-native-svg'
import { pxRaw } from '~/utils/device'
import { COLOR } from '~/const'

export default function CircleProgress({
    progress = 50,
    size = 200,
    strokeWidth = 2,
    strokeColor = COLOR.ORANGE,
    backgroundColor = COLOR.BEIGE,
    children,
}) {
    size = pxRaw(size)
    strokeWidth = pxRaw(strokeWidth)

    const center = size / 2
    const radius = center - strokeWidth / 2
    const percent = progress / 100
    const unit = Math.PI * 2 * radius
    const dashArray = unit * percent + ' ' + unit * (1 - percent)
    const dashOffset = (unit * 25) / 100

    return (
        <Svg height={size + 2} width={size + 2}>
            <Circle
                cx={center}
                cy={center}
                r={radius}
                fill={backgroundColor}
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={strokeWidth}
                strokeDasharray={dashArray}
                strokeDashoffset={dashOffset}
            />
        </Svg>
    )
}
