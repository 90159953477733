import React, { useEffect, useState } from 'react'
import styled from '@emotion/native'
import { CONNECTION } from '~/const'
import { px } from '~/utils/device'
import { UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { translate } from '~/locale'
import { useGlobalState, useTimer } from '~/store/hooks'
import { connect } from '~/server'
import Button from '~/components/stateless/Button'
import { AUDIO } from '~/audio'

export default function Connectivity() {
    const [{ connection, connection_trys }] = useGlobalState('connection')

    // OPEN
    if (connection === CONNECTION.OPEN || connection_trys < 2) {
        return null
    }

    function onClick() {
        AUDIO.CLICK()
        connect()
    }

    return (
        <Background>
            <Title>
                {translate('We have lost the connection with the server.')}
            </Title>
            <Image source={UX_ASSETS['disconnected.png']} />
            <Reconnect>
                {/* {connection === CONNECTION.CLOSE && ( */}
                <ReconnectTimer
                    connection={connection}
                    connection_trys={connection_trys}
                />
                {/* )} */}
                <ReconnectButton>
                    <Button
                        label={translate(
                            connection === CONNECTION.CONNECTING
                                ? 'Reconnecting'
                                : 'Reconnect'
                        )}
                        disabled={connection === CONNECTION.CONNECTING}
                        width={300}
                        height={80}
                        fontSize={30}
                        onClick={onClick}
                    />
                </ReconnectButton>
            </Reconnect>
        </Background>
    )
}

function ReconnectTimer({ connection, connection_trys }) {
    const [ms] = useTimer({
        initial: connection_trys * 1000,
        increment: -1000,
        stop: connection !== CONNECTION.CLOSE,
    })
    const seconds = Math.round(ms / 1000)

    return (
        <ReconnectText visible={connection !== CONNECTION.CONNECTING}>
            {translate(
                // eslint-disable-next-line
                'Reconnecting in ${seconds}...',
                { seconds }
            )}
        </ReconnectText>
    )
}

const Background = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background: ${COLOR.REDDARK + 'fd'};
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    cursor: default;
    z-index: 3;
`

const Title = styled.Text`
    font-family: Poppins-Bold;
    color: white;
    font-size: ${px(25)};
`

const Image = styled.Image`
    width: ${px(270)};
    height: ${px(270)};
    margin: ${px(100)} 0;
`

const Reconnect = styled.View`
    align-items: center;
`

const ReconnectButton = styled.View`
    padding-top: ${px(40)};
`

const ReconnectText = styled.Text`
    font-family: Poppins-Medium;
    color: white;
    font-size: ${px(20)};
    opacity: ${(p) => (p.visible ? '0.8' : '0')};
`

// function ConnectivityClose({ connect, connection_trys }) {
//     const [seconds, setSeconds] = useState(connection_trys)
//     useEffect(() => {
//         const timeout = setTimeout(() => {
//             setSeconds(seconds - 1)
//         }, 1000)
//         return () => clearTimeout(timeout)
//     }, [seconds, setSeconds])
//     return (
//         <ConnectivityContainer onClick={connect}>
//             <ConnectivityContainerError>
//                 'We have lost the connection with the server. Reconnect in $
//                 {seconds}...' &nbsp;&nbsp;&nbsp;
//                 <ReconnectButton>Click to connect.</ReconnectButton>
//             </ConnectivityContainerError>
//         </ConnectivityContainer>
//     )
// }
